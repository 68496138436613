import { ProjectService } from './../contexts/ProjectContext';
import { NotificationService } from './../contexts/NotificationContext';
import { useContext } from "react";
import NotificationContext from "../contexts/NotificationContext";
import { DashboardService, StatusItem } from '../services/dashboardService';
import { NextActionBehavior } from '../shared/models/interfaces';

export function useNotificationContext() {
    return useContext(NotificationContext);
}

export function verifyIfThereIsAnyNotificationToChangeStatus(
    notificationService: NotificationService,
    projectService: ProjectService,
    projectId?: number,
    setStatus?: (statusName: string) => void,
    setStatusList?: (statusList: StatusItem[]) => void,
    setIsLoading?: (isLoading: boolean) => void): boolean | undefined {
    const service = notificationService.get();

    if(service) {
        const { changeStatusReq } = projectService;

        if(projectId && projectId !== changeStatusReq?.idProject) return ;
        
        if(setIsLoading) setIsLoading(true);
        service.subscribe(succeeded => {
            if(succeeded && changeStatusReq) {
                const nextAction: NextActionBehavior = {
                    idFlowStatus: changeStatusReq.idStatus,
                    selfAssessmentQuestionnaireId: changeStatusReq.idProject,
                    comment: changeStatusReq.comment
                }

                DashboardService.changeStatus(nextAction).subscribe((list) => {
                    if(setStatus && setStatusList && setIsLoading) {
                        setStatus(changeStatusReq.status);
                        setStatusList(list)
                        setIsLoading(false);
                    }
                    sessionStorage.removeItem('changeStatus');
                });
            }
            // if undo was clicked
            else if(setIsLoading) setIsLoading(false);
        });

        return true;
    }

    return false;
}