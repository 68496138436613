import React, { useState, useEffect } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment, { Moment } from 'moment';
import MomentUtils from "@date-io/moment";
import 'moment/locale/en-gb'
import {
    KeyboardDatePicker,
    DatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';


import UiFieldWrapper from './UiFieldWrapper';
import UiBaseField from './UiBaseField';
import { getFieldData } from './UiFieldHelpers';

type IPropsUiDateField= UiBaseField &  {
    format?: string,
    keyboard?: boolean,
    min?: string | Date,
    max?: string | Date,
    onSelect?(newValue: string|null) : void
}

export const UiDateField = (props: IPropsUiDateField) => {
    const {error, name, value} = getFieldData(props);
    const format: string = props.format || "YYYY-MM-DD"
    const defaultDate: Moment | null = value ? moment(value, format): null;
    const [dateState, setDate] = useState<Date | null | Moment>(defaultDate);
    useEffect(() => setDate(value), [value]);

    const keyboardControl = props.keyboard !== false
    const handleDateChange = (date: Moment | null) => {
        setDate(date)
        const formatedValue = date ? date.format(format) : null;
        if(props.form){
            // Ici nous n'utilisont pas la props onChange
            // Car la signature du onChange de formik demande onChange(event, value)
            // Cependant les pickers de material-ui ne retournent pas de "event"
            // Si besoin de récupérer un événement au changement de date veuillez utiliser la prop "onSelect"
            // @see https://github.com/mui-org/material-ui-pickers/issues/974
            props.form.setFieldValue(name, formatedValue)
            if(props.onSelect){
                props.onSelect(formatedValue);
            }
        }
    }
    return (
        <UiFieldWrapper name="ui-date-field" {...props}>
            <div className="ui-field__field">
                {keyboardControl && (
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                        className="ui-date-field__keyboard"
                        fullWidth
                        clearable
                        InputLabelProps={{ style: { pointerEvents: "auto" } }}
                        label={props.label}
                        inputVariant="outlined"
                        format={format}
                        value={dateState}
                        minDate={props.min}
                        maxDate={props.max}
                        onChange={handleDateChange}
                        error={error.hasError}
                        helperText={error.errorMessage}
                        keyboardIcon={<DateRangeIcon />}
                    />
                    </MuiPickersUtilsProvider>
                )}
                {!keyboardControl && (
                    <DatePicker
                        fullWidth
                        clearable
                        InputLabelProps={{ style: { pointerEvents: "auto" } }}
                        label={props.label}
                        inputVariant="outlined"
                        format={format}
                        value={dateState}
                        minDate={props.min}
                        maxDate={props.max}
                        onChange={handleDateChange}
                        error={error.hasError}
                        helperText={error.errorMessage}
                        InputProps={{
                            endAdornment: (
                                <DateRangeIcon />
                            ),
                        }}
                    />
                )}
            </div>
        </UiFieldWrapper>
    )
}
export default UiDateField