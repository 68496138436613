import { Button, CircularProgress, Grid, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Subscription } from "rxjs";
import { FileService } from "../../../services/fileService";
import { notificationMessage, urlPartialDownload, urlPartialUpload } from "../../config/GlobalAppConfig";
import CustomAxios, { ApiCallingMethods } from "../../helpers/AxiosHelper";
import { baseCreateNotification, downloadFile } from "../../helpers/HelpersFunc";
import { DocAccessType, NotificationType } from "../../models/enums";
import { CustomFile } from "../upload/interfaces";

interface IFiles {
    id: Number
}

export const Files = (props: IFiles) => {
    const [documentsSubscription, setDocumentsSubscription] = useState<Subscription>();
    const [documents, setDocuments] = useState<CustomFile[]>();
    const [isFileUploading, setIsFileUploading] = useState(false);
    const inputFile = useRef<HTMLInputElement>(null);

    useEffect(() => {
        loadFiles();

        return () => {
            documentsSubscription?.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadFiles = () => {
        if(!props.id){
            return;
        }
        const docSubscription = FileService.getDocuments(props.id?.toString()).subscribe(documentsInDb => {
            setDocuments(documentsInDb);
        });
        setDocumentsSubscription(docSubscription);
    }

    const deleteFile = (e: any, id: number) => {
        e.stopPropagation();
        FileService.delete(id).subscribe(() => {
            baseCreateNotification(NotificationType.success, notificationMessage.File_Deleted.title, notificationMessage.File_Deleted.message);
            loadFiles();
        }, () => {
            baseCreateNotification(NotificationType.error, notificationMessage.ErrorGenericMessage.title, notificationMessage.ErrorGenericMessage.message);
        });
    }

    const onChangeFile = (event: any) => {
        setIsFileUploading(true);
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];

        const formData = new FormData();
        formData.append("idQuestionnaire", props.id.toString());

        file.docAccessType = DocAccessType["Visible to all"].toString();

        const pos = Number(file.docAccessType);
        formData.append("docType", DocAccessType[pos]);
        formData.append("files", file, file.name);

        let method: ApiCallingMethods = ApiCallingMethods.post;
        let uploadUrl = urlPartialUpload;

        const options: AxiosRequestConfig = {
            method: method,
            url: uploadUrl,
            data: formData,
            responseType: 'json'
        };

        CustomAxios.request(options).subscribe((response: AxiosResponse<any>) => {
            baseCreateNotification(NotificationType.success, notificationMessage.File_Uploaded.title, notificationMessage.File_Uploaded.message);
            setIsFileUploading(false);
            loadFiles();
        }, () => {
            setIsFileUploading(false);
            baseCreateNotification(NotificationType.error, notificationMessage.ErrorGenericMessage.title, notificationMessage.ErrorGenericMessage.message);
        });
    };

    const onAddFileButtonClick = () => {
        if(!props.id) {
            baseCreateNotification(NotificationType.info, notificationMessage.File_CantUpload.title, notificationMessage.File_CantUpload.message);
            return;
        }
        if(inputFile != null && inputFile.current != null)
        {
            inputFile.current.click();
        }
    };

    //TODO use a proper type, remove any
    const onDownload = (file: any) => {
        FileService.download(urlPartialDownload, file.filenameConverted).subscribe((res) => {
            downloadFile(res, file.filenameConverted);
        })
    }

    return (
        <Grid container spacing={3} className="documents-upload">
                {documents?.length
                    ? documents?.map((doc: any) => (
                        <Grid item xs={12} md={8} className="action">
                            {/* TODO change the design, this input must be desabled, but when i desable it, onclick wont fire */}
                            <OutlinedInput
                                label="Filename"
                                type={'text'}
                                value={doc.filename}
                                className="file"
                                fullWidth
                                onClick={() => onDownload(doc)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(e) => deleteFile(e, doc.id)}
                                            edge="end"
                                            >
                                            {<Delete color="primary" />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    ))
                    : (
                        <Grid item xs={12} md={8} className="example-label">
                            <label>Document describing the project, Data flow diagrams, technical architecture of the solution, product presentations, security certifications of the vendor, contract draft, etc...</label>
                        </Grid>
                    )
                }
            <Grid item xs={12} md={4} className="action">
                <>
                    <Button
                        startIcon={isFileUploading ? <CircularProgress size={20} /> : <Add />}
                        variant="contained"
                        color="primary"
                        onClick={onAddFileButtonClick}
                        disabled={isFileUploading}
                        >
                        Add a file
                    </Button>
                    <input
                        ref={inputFile}
                        type='file'
                        id='file'
                        style={{display: 'none'}}
                        onChange={(event) => onChangeFile(event) }
                    />
                </>
            </Grid>
        </Grid>
    );
}