import { IUiTableHead } from '../../../shared/components/ui/UiTable/types';
import {
  //Actions,
  AssignTo,
  ContactName,
  dateString,
  modifedDateString,
  ProjectName,
  Status,
  EmailAssignedSecurityUser,
  ShadowIT,
} from './UiListViewCells';

export const tableHeads: IUiTableHead[] = [
  {
    name: 'status',
    label: 'Status',
    accessor: 'statusName',
    sortable: true,
    cell: Status,
    colId: 1,
  },
  {
    name: 'shadowIT',
    label: 'Shadow IT',
    accessor: 'isShadowIT',
    sortable: false,
    cell: ShadowIT,
    colId: 2,
  },
  {
    name: 'projectName',
    label: 'Project Name',
    cell: ProjectName,
    sortable: true,
    colId: 3,
  },
  {
    name: 'contactName',
    label: 'Contact Name',
    accessor: 'contactName',
    cell: ContactName,
    sortable: true,
    colId: 4,
  },
  {
    name: 'date',
    label: 'Submission Date',
    accessor: 'submissionDate',
    cell: dateString,
    sortable: true,
    colId: 5,
  },
  {
    name: 'date',
    label: 'Last Activity',
    accessor: 'modifiedDate',
    cell: modifedDateString,
    sortable: true,
    colId: 6,
  },
  {
    name: 'thirdParties',
    label: 'Third Party',
    accessor: 'thirdPartyNames',
    sortable: true,
    colId: 7,
  },
  {
    name: 'score',
    label: 'Score',
    accessor: 'thirdPartyScores',
    sortable: true,
    colId: 8,
  },
  {
    name: 'assigned',
    label: 'Assigned',
    accessor: 'assgined',
    cell: AssignTo,
    sortable: true,
    colId: 9,
  } /* ,
  {
    name: "actions",
    label: "",
    cell: Actions,
  }, */,
];

export const stakeHoldersTableHeads: IUiTableHead[] = [
  {
    name: 'status',
    label: 'Status',
    accessor: 'statusName',
    sortable: true,
    cell: Status,
    colId: 1,
  },
  {
    name: 'projectName',
    label: 'Project Name',
    cell: ProjectName,
    sortable: true,
    colId: 3,
  },
  {
    name: 'date',
    label: 'Submission Date',
    accessor: 'submissionDate',
    cell: dateString,
    sortable: true,
    colId: 4,
  },
  {
    name: 'date',
    label: 'Last Activity',
    accessor: 'modifiedDate',
    cell: modifedDateString,
    sortable: true,
    colId: 5,
  },
  {
    name: 'thirdParties',
    label: 'Third Party',
    accessor: 'thirdPartyNames',
    sortable: true,
    colId: 6,
  },
  {
    name: 'score',
    label: 'Score',
    accessor: 'thirdPartyScores',
    sortable: true,
    colId: 7,
  },
  {
    name: 'assigned',
    label: 'Assigned',
    accessor: 'assgined',
    cell: EmailAssignedSecurityUser,
    sortable: true,
    colId: 8,
  } /* ,
  {
    name: "actions",
    label: "",
    cell: Actions,
  }, */,
];
