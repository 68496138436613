import React, { ReactNode, ReactChild, ReactChildren } from "react"
import Modal from '@material-ui/core/Modal';
import UiBox from "../UiBox";
import CloseIcon from '@material-ui/icons/Cancel';

export type Action = 'backdropClick' | 'escapeKeyDown' | 'closeIconClick' | 'cancelBtnClick' | 'applyFilter' | 'clearFilter' | 'update';

export type IPropsUiModalBase = {
    open: boolean
    /**
     * Callback fired when the component requests to be closed.
     *
     * @param {object} event The event source of the callback.
     * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`, `"closeIconClick"`.
     */
    onClose?(event: {}, reason: Action): void;
    footerButtons?: ReactNode[]
}
type IPropsUiModal = IPropsUiModalBase & {
    id?: string
    title: string
    children: ReactChild | ReactChildren,
    maxWidth?: number
}
export const UiModal = (props: IPropsUiModal) => {
    const handleClickClose = (event: any) => {
        if (props.onClose) {
            props.onClose(event, 'closeIconClick')
        }
    }
    const renderFooter = () => {
        if (props.footerButtons && props.footerButtons.length) {
            return <div className="ui-modal__footer ui-modal__footer--buttons">{props.footerButtons.map((btn, index) => <div key={index}>{btn}</div>)}</div>
        }
        return "";
    }
    return (
        <div onClick={(e: any) => { e.stopPropagation(); }}>
            <Modal
                open={props.open}
                onClose={props.onClose}
            >
                <div id={props.id} className="ui-modal">
                    <div className="ui-modal__content" style={{maxWidth: props.maxWidth}}>
                        <UiBox
                            title={props.title}
                            actions={<button onClick={handleClickClose} className="ui-modal__close"><CloseIcon fontSize="default" /></button>}
                        >
                            <>
                                {props.children}
                                {renderFooter()}
                            </>
                        </UiBox>
                    </div>
                </div>
            </Modal>
        </div>
    )
}