import React, {createContext, FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import AxiosHelper from "../shared/helpers/AxiosHelper";
import {getMappingRoles} from "../shared/models/records";
import {tokenRequestParam} from "../services/authentification/authentificationConfig";
import LocalStorageTokenService from "../shared/helpers/LocalStorageTokenService";
import {CircularProgress, Grid} from "@material-ui/core";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {AuthenticationResult, InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";

export interface IAuthContext {
    role?: string;
    roleId?: number;
    setRole(role: string): void;
    isLoading: boolean;
    setIsLoading(val: boolean): void;
    username?: string;
    setUsername(val: string): void;
    isAuth: boolean;
    setIsAuth(isAuth: boolean): void;
    isError: boolean;
    setIsError(isAuth: boolean): void;
    accessToken: string;
    setAccessToken(val: string): void;
    refreshToken: string;
    setRefreshToken(val: string): void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthContextProvider: FC = React.memo(({children}) => {
    const [role, setRole] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [username, setUsername] = useState<string>();
    const [isAuth, setIsAuth] = useState(false);
    const [isError, setIsError] = useState(false);
    const [refreshToken, setRefreshToken] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();
    const defineRefreshToken = (token: string) => {
        setRefreshToken(token);
    };
    const defineAccessToken = (token: string) => {
        setAccessToken(token);
        AxiosHelper.setAccessToken(token);
    };

    const roleId = useMemo(() => {
        return role ? getMappingRoles(role) : undefined;
    }, [role]);

    const updateContextFromToken = useCallback(
        (response: AuthenticationResult) => {
            if (response === undefined || response === null) {
                setIsAuth(false);
                setIsError(true);
            } else {
                const claims = response.idTokenClaims as { [key: string]: any };
                const roles = claims.groups;
                defineAccessToken(response.idToken);
                setIsAuth(!!response);
                setRole(roles);
                setUsername(response.account?.username);
            }
            setIsLoading(false);
        },
        [setIsAuth, setIsError, setIsLoading, setRole, setUsername]
    );

    useEffect(() => {
            instance.acquireTokenSilent(tokenRequestParam).then(response => {
                LocalStorageTokenService.setToken(response);
                updateContextFromToken(response);
            }).catch(error => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(tokenRequestParam)
                }
            });
    }, []);

    useEffect(() => {
        if (inProgress === InteractionStatus.None && !isAuthenticated) {
            instance.loginRedirect();
        }
    })

    useEffect(() => {
        instance.handleRedirectPromise().then(response => {
            if (response) {
                instance.setActiveAccount(response.account);
                LocalStorageTokenService.setToken(response);
                updateContextFromToken(response);
            }
        })
    })

    const value: IAuthContext = {
        role,
        setRole,
        isLoading,
        setIsLoading,
        username,
        setUsername,
        isAuth,
        setIsAuth,
        isError,
        setIsError,
        roleId,
        setAccessToken: defineAccessToken,
        setRefreshToken: defineRefreshToken,
        refreshToken,
        accessToken,
    };

    return (
        <AuthContext.Provider value={value}>
            {isLoading ? (
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{minHeight: "100vh"}}
                >
                    <Grid item xs={3}>
                        <CircularProgress/>{" "}
                    </Grid>
                </Grid>
            ) : (
                children
            )}
        </AuthContext.Provider>
    );
});

export const useAuthContext = () => useContext(AuthContext);
