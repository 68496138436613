import React from 'react';
import './Dropzone.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { NotificationBannerTimeDisplayMs } from '../../config/GlobalAppConfig';

interface Props {
    maxFileSize: number;
    supportedExtensions: string[];
    onFilesAdded?: (array: any) => void;
    disabled?: boolean;
}

interface StateProps {
    highlight: boolean
}

const convertMegabytesToKilobytes = (value: number) => {
    return 1024 * 1024 * value;
}

class Dropzone extends React.Component<Props, StateProps> {
    fileInputRef: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            highlight: false
        };
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    onDragOver(evt: React.DragEvent<HTMLDivElement>) {
        evt.preventDefault();

        if (this.props.disabled) return;

        this.setState({ highlight: true });
    }

    onDragLeave() {
        this.setState({ highlight: false });
    }

    onDrop(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();

        if (this.props.disabled) return;

        const files: FileList = event.dataTransfer.files;

        if (this.isValid(files) && this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
        this.setState({ highlight: false });
    }

    isValid = (files: FileList): boolean => {
        let flag = true;
        Array.from(files).forEach((file: File) => {
            const fileExtension = file.name.split('.').pop();
            if (fileExtension && this.props.supportedExtensions.indexOf(fileExtension) < 0) {
                NotificationManager.error(`The extension ${fileExtension} not supported`, `File not supported`, NotificationBannerTimeDisplayMs);
                flag = false
                return;
            }
            if (file.size > convertMegabytesToKilobytes(this.props.maxFileSize)) {
                NotificationManager.error(`It should be < ${this.props.maxFileSize}MB`, `File too heavy`, NotificationBannerTimeDisplayMs);
                flag = false
                return;
            }
        });
        return flag;
    }

    openFileDialog() {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    onFilesAdded(evt: any) {
        if (this.props.disabled) return;
        const files = evt.target.files;
        if (this.isValid(files) && this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
    }

    fileListToArray(list: FileList) {
        const array = [];
        for (var i = 0; i < list.length; i++) {
            array.push(list[i]);
        }
        return array;
    }

    render() {
        return (
            <div
                className={`Dropzone ${this.state.highlight ? "Highlight" : ""}`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onClick={this.openFileDialog}
                style={{ cursor: this.props.disabled ? "default" : "pointer" }} >
                <input
                    ref={this.fileInputRef}
                    className="FileInput"
                    type="file"
                    name="uploadFileInput"
                    multiple
                    onChange={this.onFilesAdded}
                    accept={this.props.supportedExtensions.join(',.').replace(/^/, '.')}
                />
                <span>Drag files here</span><br />
                <span>OR</span><br />
                <span className="browse">Browse files...</span>
            </div>
        )
    }
}

export default Dropzone;