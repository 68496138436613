import { AxiosRequestConfig } from "axios";
import { map } from "rxjs/operators";
import { urlPartialPerformAction } from "../shared/config/GlobalAppConfig";
import { ApiCallingMethods } from "../shared/helpers/AxiosHelper";
import DataConnectionFactory from "../shared/helpers/DataConnectionFactory";
import { ApiResponse, NextActionBehavior } from "../shared/models/interfaces";

export class ProjectService {
    static performAction(nextAction: NextActionBehavior) {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialPerformAction,
            data: nextAction
        };
        
        const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.model;
        }));
        return res;
    }
}