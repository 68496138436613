import { Button } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import { Grid } from '@mui/material';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import * as yup from 'yup';
import LoadingContext from '../../../contexts/LoadingContext';
import { ThirdPartyTask } from '../../../services/formService';
import { ThirdPartyService } from '../../../services/thirdPartyService';
import { UiDateField, UiNumberField, UiSelectField, UiTextField } from '../../../shared/components/fields';
import { IPropsUiModalBase, UiModal } from '../../../shared/components/ui/UiModal/UiModal';
import { notificationMessage } from '../../../shared/config/GlobalAppConfig';
import { baseCreateNotification } from '../../../shared/helpers/HelpersFunc';
import { NotificationType } from '../../../shared/models/enums';
import { CommonProperties, Reference } from '../../../shared/models/interfaces';

export interface IThirdParty extends CommonProperties{
    companyName: string,
    companyWebsite: string,
    contactName?: string,
    emailContact?: string,
    score?: string | number,
    tasks?: Reference[] | ThirdPartyTask[],
    tasksSaved?: ThirdPartyTask[],
    tasksSelected?: ThirdPartyTask[],
    phoneNumber?: number | string,
    scoreModifiedDate?: Date | string
    tiering?: string
}

export const initThirdParty: IThirdParty = {
    id: 0,
    companyName: '',
    companyWebsite: '',
    contactName: '',
    emailContact: '',
    phoneNumber: '',
    score: 0,
    tasks: [],
    scoreModifiedDate: '',
    tiering: 'Tier 1'
}

const tieringOption = [
    {
        itemContent: "Tier 1",
        id: "Tier 1"
    },
    {
        itemContent: "Tier 2",
        id: "Tier 2"
    },
    {
        itemContent: "Tier 3",
        id: "Tier 3"
    }
]

const validationSchema = yup.object().shape({
    companyName: yup.string().required("This is a required field"),
    companyWebsite: yup.string().required("This is a required field"),
})

type IProps = IPropsUiModalBase & {
    thirdParty?: IThirdParty
    thirdPartiesList: IThirdParty[]
}

export const AddOrEditThirdPartyModal = function(props: IProps) {
    const [thirdPartySubscription, setThirdPartySubscription] = useState<Subscription | undefined>();
    const [values, setValues] = useState<IThirdParty>(initThirdParty);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [openMessageModal, setOpenMessageModal] = useState(false);
    const ctx = React.useContext(LoadingContext);

    const handleBlur = function(event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) {
        const elFound = props.thirdPartiesList.filter(tp => tp.companyName.toLowerCase() === event.target.value.toLowerCase()  && tp.id !== props.thirdParty?.id)[0];
        if(elFound) {
            setFieldValue('companyName', '');
            setOpenMessageModal(true);
        }
    }

    const submit = function(values: IThirdParty) {
        ctx.startLoading();
        if(!values.id) {
            validate(values);
            const tempSubscription = ThirdPartyService.add(values).subscribe((response) => {
                if(props.onClose) props.onClose(response, 'applyFilter');
                baseCreateNotification(NotificationType.success, notificationMessage.ThirdPartyForm_added.title, notificationMessage.ThirdPartyForm_added.message);
                ctx.stopLoading();
            }, () => ctx.stopLoading());
            setThirdPartySubscription(tempSubscription);
        }
        else {
            const tempSubscription = ThirdPartyService.update(values).subscribe((response) => {
                if(props.onClose) props.onClose(response, 'update');
                baseCreateNotification(NotificationType.success, notificationMessage.ThirdPartyForm_updated.title, notificationMessage.ThirdPartyForm_updated.message);
                ctx.stopLoading();
            }, () => ctx.stopLoading());
            setThirdPartySubscription(tempSubscription);
        }
    }

    const validate = function(tp: IThirdParty) {
        if (!tp) {
            return null;
        }
        // score
        tp.score = tp.score ? Number(tp.score) : 0;
        // scoreModifiedDate
        if (!tp.scoreModifiedDate) {
            tp.scoreModifiedDate = undefined;
        }
        //return tp;
    }

    const cancel = function() {
        setOpenMessageModal(false);
    }

    useEffect(() => {
        if(props.thirdParty) {
            //if (!props.thirdParty.id) props.thirdParty.scoreModifiedDate = null;
            if (props.thirdParty.tiering === null) props.thirdParty.tiering = ""
            setValues(props.thirdParty);
            setEditMode(props.thirdParty.id ? true : false);
        }
    }, [props.thirdParty])

    useEffect(() => {
        return () => {
            thirdPartySubscription?.unsubscribe();
        }
    }, [])

    return (
        <UiModal
            id="add-third-party-modal"
            title={editMode ? "Edit Third Party" : "Add Third Party"}
            open={props.open}
            onClose={props.onClose}
            maxWidth={1100}
        >
            <Formik
                onSubmit={submit}
                enableReinitialize={true}
                initialValues={values}
                validationSchema={validationSchema}
                validateOnChange={false}
            >
                {({ values, handleSubmit, setFieldValue }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <div className="section-title">Company Profile</div>
                            <Field
                              name={'companyName'}
                              label="Company name"
                              component={UiTextField}
                              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => handleBlur(event, setFieldValue)}
                            />
                            <Field
                              name={'companyWebsite'}
                              label="Company website (www.xxxxx.com format)"
                              component={UiTextField}
                            />
                            <Field name={'score'} label="Score" min={0} max={1000} component={UiNumberField} />
                            <Field name={'scoreModifiedDate'} label="Date of assessment" component={UiDateField} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="section-title">Company point of contact</div>
                            <Field name={'contactName'} label="Full name" component={UiTextField} />
                            <Field name={'emailContact'} label="Email" component={UiTextField} />
                            <Field
                              name={'phoneNumber'}
                              label="Phone number (with country code)"
                              component={UiTextField}
                            />
                            <Field name={'tiering'} label="Tiering" component={UiSelectField} options={tieringOption} />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            {editMode ? (
                              <Button
                                id="edit-third-party"
                                style={{ margin: 10 }}
                                type="submit"
                                color="primary"
                                variant="contained"
                                startIcon={<Edit />}
                              >
                                Save changes
                              </Button>
                            ) : (
                              <Button
                                id="add-third-party"
                                style={{ margin: 10 }}
                                type="submit"
                                color="primary"
                                variant="contained"
                                startIcon={<Add />}
                              >
                                Add Third Party
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                        <UiModal title="Information" open={openMessageModal} onClose={cancel}>
                          <div>
                            <div className="row">{values.companyName} company name already exists!</div>
                            <div className="ui-modal__footer ui-modal__footer--buttons">
                              <Button onClick={cancel} variant="outlined" color="primary" key="cancel">
                                Ok
                              </Button>
                            </div>
                          </div>
                        </UiModal>
                      </form>
                    );
                }
            }
            </Formik>
        </UiModal>
    )
}