import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React from "react";
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';

export class AppBreadcrumbs extends React.Component<any, any> {
    routesList = {
        '/intro': 'Welcome to MyCyberJourney',
        '/dashboard': 'List of Projects',
        '/dashboard/selfAssessment': '',
        '/dashboard/selfAssessment/new': 'New Questionnaire',
        '/dashboard/selfAssessment/:mode': '',
        '/dashboard/selfAssessment/:mode/:id': '',
        '/dashboard/project': '',
        '/dashboard/project/:id': '',
        '/thirdParty': 'List of Assessed Third Parties',
    }

    constructor(props: any) {
        super(props);

        this.state = {
            routesList: this.routesList,
            projectName: '',
        }
    }

    componentDidMount() { }

    render() {
        return (
            <Breadcrumbs
                mappedRoutes={this.state.routesList}
                WrapperComponent={(props: any) => {
                    return <ol className="breadcrumb">{props.children}</ol>
                }
                }
                ActiveLinkComponent={(props: any) =>
                    <li className="breadcrumb-item active" >{props.children}</li>
                }
                LinkComponent={(props: any) =>
                    <li className="breadcrumb-item">{props.children}<ArrowRightIcon />
                    </li>
                }
            />
        );
    }
}