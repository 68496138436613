import React from 'react';
import TextField from '@material-ui/core/TextField';
import UiFieldWrapper from './UiFieldWrapper';
import UiBaseField from './UiBaseField';
import { getFieldData } from './UiFieldHelpers';
type IPropsUiTextarea = {
}
export const UiTextareaField = (props: UiBaseField & IPropsUiTextarea) => {
    const {error, name, value, onChange} = getFieldData(props);

  
    return (
        <React.Fragment>
            <UiFieldWrapper name="ui-textearea-field" {...props}>
                <span className="ui-text-field__field  ui-field__field">
                    <TextField
                        fullWidth
                        label={props.label}
                        variant="outlined"
                        value={value}
                        disabled={props.disabled}
                        name={name}
                        onChange={onChange}
                        error={error.hasError}
                        helperText={error.hasError ? error.errorMessage : null}
                        multiline
                        rows={props.rows}
                        inputProps={props.inputProps}
                    />
                </span>
            </UiFieldWrapper>
        </React.Fragment>
    )
}
export default UiTextareaField