import React, { ReactNode, useEffect, useState } from "react";
import ProjectContext, { ChangeStatus } from "../contexts/ProjectContext";
import { Reference } from "../shared/models/interfaces";

export default React.memo(function ProjectController({ children }: { children: ReactNode }) {
    const [changeStatusReq, setChangeStatusReq] = useState<ChangeStatus>();
    const [thirdPartyActivities, setThirdPartyActivities] = useState<Reference[]>();

    const handlePersistentChangeStatusReq = function (changeStatus: ChangeStatus) {
        sessionStorage.setItem('changeStatus', JSON.stringify(changeStatus));
        setChangeStatusReq(changeStatusReq);
    }

    useEffect(() => {
        const changeStatus = sessionStorage.getItem('changeStatus');
        if (changeStatus) {
            setChangeStatusReq(JSON.parse(changeStatus));
        }
    }, []);

    const value = {
        changeStatusReq,
        setChangeStatusReq,
        handlePersistentChangeStatusReq,
        thirdPartyActivities,
        setThirdPartyActivities
    }

    return (
        <ProjectContext.Provider value={value}>
            {children}
        </ProjectContext.Provider>
    )
})
