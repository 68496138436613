import { IconButton, makeStyles, styled } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Theme, tooltipClasses } from '@mui/material';
import React, { ReactNode, useState } from 'react';

type IPropsUiTooltip = {
    title?: string | ReactNode;
    label?: string | ReactNode;
    isClickable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #C5C5C5"
        },
        color: theme.palette?.common.white + " !important"
    },
    tooltip: {
        backgroundColor: theme.palette?.common.white + " !important",
        border: "1px solid #C5C5C5",
        color: "#6F6F70 !important",
        borderRadius: "0px !important",
        padding: "15px !important",
        fontSize: "12px !important",
        top: 10
    }
}));

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});

export const UiTooltip: React.SFC<IPropsUiTooltip> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    if (!props.title) return null;

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    return (
        <>
            {!props.label
                ? <CustomWidthTooltip title={props.title}>
                    <HelpOutlineIcon fontSize="small" />
                </CustomWidthTooltip >
                : <>
                    {props.label}
                    <Tooltip
                        onClose={handleTooltipClose}
                        open={open}
                        disableHoverListener
                        title={props.title}
                        placement="top"
                        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                        arrow
                    >
                        <IconButton onClick={handleTooltipOpen} aria-label="tooltip" style={{ color: '#1A1919' }} component="div">
                            <HelpOutlineIcon fontSize="small" />
                        </IconButton>
                    </Tooltip >
                </>}
        </>
    )
}
export default UiTooltip;