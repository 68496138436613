import {Button, CircularProgress, TableContainer, TableSortLabel, Tooltip} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Add } from "@material-ui/icons";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Document from '@material-ui/icons/InsertDriveFile';
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Field, Formik, FormikHelpers } from "formik";
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Subscription } from "rxjs";
import LoadingContext from "../../../../contexts/LoadingContext";
import { CustomDocument } from "../../../../screens/dashboard/interface";
import { FileService } from "../../../../services/fileService";
import { ISendNote, NoteService, sendNoteValidationSchema } from "../../../../services/noteService";
import { notificationMessage, urlPartialUpload } from "../../../config/GlobalAppConfig";
import CustomAxios, { ApiCallingMethods } from "../../../helpers/AxiosHelper";
import { baseCreateNotification } from "../../../helpers/HelpersFunc";
import { DocAccessType, NotificationType } from "../../../models/enums";
import { UiTextareaField } from "../../fields";
import UiTooltipLabel from '../../fields/UiTooltipLabel';
import { NotesTable } from "../../note/NotesTable";
import { UiModal } from "../UiModal/UiModal";
import { IPropsUiTable, IStateOrder, IStateOrderBy } from './types';
import { UiTableCell } from './UiTableCell';
import { getComparator } from './UiTableHelper';
import { UiPagination } from './UiTablePagination';
import { UiTableRowSkeleton } from './UiTableRowSkeleton';
import {PAGE_NUMBER_KEY} from "../../../../screens/introPage/constants";

const sendNoteInitialValues: ISendNote =
{
    idQuestionnaire: 0,
    noteText: "",
    projectName: ""
};

interface IFilesList {
    idQuestionnaire: string
}

export const FilesList = forwardRef((props: IFilesList, ref) => {
    const [showAllFiles, setShowAllFiles] = useState(false);
    const [list, setList] = useState<CustomDocument[]>();
    const [listToDisplay, setListToDisplay] = useState<CustomDocument[]>();
    const [docSubscription, setDocSubscription] = useState<Subscription>();

    useImperativeHandle(ref, () => {
        return {
            reloadDocuments
        }
    });

    const handleSeeAllFilesClick = function () {
        setShowAllFiles(true);
        setListToDisplay(list);
    }

    const reloadDocuments = (id: string) => {
        const _docSubscription = FileService.getDocuments(id).subscribe(documents => {
            setList(documents);
            setListToDisplay(documents.slice(0, 2));
        });
        setDocSubscription(_docSubscription);
    }

    useEffect(() => {

        reloadDocuments(props.idQuestionnaire);
        
        return () => {
            docSubscription?.unsubscribe();
        }

    }, []);

    return (
        <>
            {
                listToDisplay?.map((document: CustomDocument, index: number) => (
                    <div key={`doc${document.id}-${index}`}>
                        <Document />
                        {`${document.filename}.${document.extension}`}
                    </div>
                ))
            }
            {(!showAllFiles && list != null && list.length > 2) && <Button name="allFiles" variant="text" onClick={handleSeeAllFilesClick}>See all files</Button>}
            {!list || list.length < 1 ? "No documents found" : ""}
        </>
    )
});

export const StopLoadingAfterRendering = ({ position, totalRows } : {position: number, totalRows: number} ) => {
    const ctx = React.useContext(LoadingContext);
    useEffect(() => {
        if(position === totalRows) {
            ctx.stopLoading();
        };
    },[])
    return <></>
}

export const UiTable = (props: IPropsUiTable) => {

    const [order, setOrder] = React.useState<IStateOrder>();
    const [orderBy, setOrderBy] = React.useState<IStateOrderBy>();
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(props.rowPerPages);
    const [page, setPage] = React.useState<number>(1);
    const inputFile = useRef<HTMLInputElement>(null);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const refs = useRef<unknown[]>([]);
    const [openNoteModal, setOpenNoteModal] = React.useState(false);
    const noteModalHandleOpen = (idQuestionnaire: number, projectName: string) => {
        sendNoteInitialValues.idQuestionnaire = idQuestionnaire;
        sendNoteInitialValues.projectName = projectName;
        sendNoteInitialValues.noteText = '';
        setOpenNoteModal(true)
    };
    const noteModalHandleClose = () => setOpenNoteModal(false);
    const [sendingNote, setSendingNote] = React.useState(false);
    const childRef = useRef();
    const [notesCountSubscription, setNotesCountSubscription] = useState<Subscription>();
    const { items, tableHeads, onRowSelect, showCheckboxes, onChange, usersList } = props;

    const getItems = () => {
        let orderedItems = items
        if (orderBy || orderBy === 0) {
            const head = tableHeads[orderBy]
            if (head.sortable && (head.accessor || head.customSort)) {
                orderedItems = orderedItems.sort((a: any, b: any) => {
                    if (head.customSort) {
                        return head.customSort(a, b, order);
                    }
                    return getComparator(head.accessor, a, b, order !== 'desc');
                })
            }
        }
        const currentPage = page - 1;
        return orderedItems.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
    }

    const [currentItems, setCurrentItems] = useState(props.total ? items : getItems());

    const handleChangePage = (_event: unknown, newPage: number) => {
        sessionStorage.setItem(PAGE_NUMBER_KEY, newPage.toString());
        setPage(newPage);
        if(props.onSetPage) props.onSetPage(newPage);
    };


    const toggleOrder = () => {
        setOrder(order === 'asc' ? 'desc' : 'asc')
    }

    const createSortHandler = (headIndex: number) => (_event: React.MouseEvent<unknown>) => {
        if(!props.total) {
            if (headIndex === orderBy) {
                setOrderBy(headIndex)
                toggleOrder();
            } else {
                setOrder('asc')
                setOrderBy(headIndex)
            }
        }

        if(onChange) onChange(headIndex, 'sort');
    }

    const onAddFileButtonClick = () => {
        if(inputFile != null && inputFile.current != null)
        {
            inputFile.current.click();
        }
    };

    const onChangeFile = (event: any, questionnaireId: string, itemIndex: number) => {
        setIsFileUploading(true);
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        
        const formData = new FormData();
        formData.append("idQuestionnaire", questionnaireId.toString());

        file.docAccessType = DocAccessType["Visible to all"].toString();

        const pos = Number(file.docAccessType);
        formData.append("docType", DocAccessType[pos]);
        formData.append("files", file, file.name);

        let method: ApiCallingMethods = ApiCallingMethods.post;
        let uploadUrl = urlPartialUpload;

        const options: AxiosRequestConfig = {
            method: method,
            url: uploadUrl,
            data: formData,
            responseType: 'json'
        };
        CustomAxios.request(options).subscribe((response: AxiosResponse<any>) => {        
            //Reload
            setIsFileUploading(false);
            if(refs.current[itemIndex])
            {
                (refs.current[itemIndex] as any).reloadDocuments(questionnaireId);
            }
        }, () => {
            setIsFileUploading(false);
            baseCreateNotification(NotificationType.error, notificationMessage.ErrorGenericMessage.title, notificationMessage.ErrorGenericMessage.message);
        });

    };
    
    const submitNote = function (values: ISendNote, formProps: FormikHelpers<ISendNote>) {
        setSendingNote(true);

        NoteService.Add(values).subscribe(data => {
            baseCreateNotification(data ? NotificationType.success : NotificationType.error, data ? "Note Added" : "Error while adding note");
            formProps.resetForm();
            if (childRef) {
                (childRef.current as any).reloadNotes();
            }
            setSendingNote(false);

            const notesCountSubscription = NoteService.CountByQuestionnaire(values.idQuestionnaire.toString()).subscribe(count => {
                currentItems.map(item => {
                   if (item.id.toString() === values.idQuestionnaire.toString())
                    item.notesCount = count;
                   return item;
                });
            });
            setNotesCountSubscription(notesCountSubscription);
        });
    }

    useEffect(() => {
        if(props.total) {
            setCurrentItems(items.slice());
        }
        else {
            setCurrentItems(getItems())
        };
    }, [items, page, orderBy, order]);

    useEffect(() => {
        if (props.orderBy !== undefined) {
            if (props.orderBy !== orderBy) {
                setOrderBy(props.orderBy);
            }
        }

        if (props.order !== undefined) {
            setOrder(props.order);
        }

    }, [props.orderBy, props.order]);

    useEffect(() => {
        setRowsPerPage(props.rowPerPages);
    }, [props.rowPerPages]);

    useEffect(() => {
        setRowsPerPage(props.rowPerPages);
        setPage(1)
        
        return () => {
            notesCountSubscription?.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem(PAGE_NUMBER_KEY) !== null) {
            setPage(parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!))
        } else {
            setPage(1)
        }
    }, [props.items]);
    return (
        <>
            <Table size="small" className={'ui-table ' + props.className}>
                <TableHead>
                    <TableRow>
                        {tableHeads.map((head, index) => (
                            <TableCell
                                key={'headDashboard-' + index}
                                sortDirection={orderBy === (head.colId ?? index) ? order : false}
                                className={'ui-tableheadcell ui-tableheadcell--' + head.name}
                            >
                                {head.sortable ?
                                    <TableSortLabel
                                        onClick={createSortHandler(head.colId ?? index)}
                                        active={orderBy === (head.colId ?? index)}
                                        direction={orderBy === (head.colId ?? index) ? order : 'asc'}
                                        IconComponent={ArrowDropDownIcon}
                                    >
                                        {
                                            head.showPopup ?
                                            UiTooltipLabel({
                                                label: head.label,
                                                activatePopup: true,
                                                popupTitle: head.popupTitle ?? head.label,
                                                popupContent: head.popupContent
                                            })
                                            : head.label
                                        }
                                    </TableSortLabel>
                                    :
                                    <>{head.label}</>
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!props.loading ?
                        currentItems.length ?
                            currentItems.map((item: any, i: number) =>
                                <Fragment key={'tablerow-' + i + item.id}>
                                    <TableRow onClick={(event) => onRowSelect ? onRowSelect(item, event) : null}>
                                        {tableHeads.map((head: any, j: number) =>
                                            <UiTableCell
                                                key={'tablecell' + i + '-' + j + head.name}
                                                {...item}
                                                head={head}
                                                showCheckbox={showCheckboxes}
                                                usersList={usersList}
                                                onChange={onChange}
                                            >
                                            </UiTableCell>)}
                                    </TableRow>
                                    <StopLoadingAfterRendering position={i} totalRows={currentItems.length-1} />
                                </Fragment >
                            )
                            :
                            <TableRow>
                                <TableCell colSpan={tableHeads.length} align="center">No item found</TableCell>
                            </TableRow>

                        : <UiTableRowSkeleton nbRow={rowsPerPage} nbCell={tableHeads.length} />}
                </TableBody>
            </Table>
            <div className="ui-table__footer">
                <div><div></div></div>
                <div>
                    <div className="ui-table__action">
                        {props.action}
                    </div>
                </div>
                <div>
                    <div>
                        {props.total !== undefined && Math.ceil(props.total / props.rowPerPages) > 1 && (
                            <UiPagination
                                page={page}
                                onChange={handleChangePage}
                                count={Math.ceil(props.total / props.rowPerPages)}
                            />
                        )}
                        {Math.ceil(items.length / props.rowPerPages) > 1 && (
                            <UiPagination
                                page={page}
                                onChange={handleChangePage}
                                count={!props.loading ? Math.ceil(items.length / props.rowPerPages) : 1}
                            />
                        )}
                    </div>
                </div>
            </div>
            
            <Formik
                initialValues={sendNoteInitialValues}
                validationSchema={sendNoteValidationSchema}
                onSubmit={submitNote}
            >
                {formProps => (
                    <UiModal
                        title={`Notes of ${sendNoteInitialValues.projectName}`}
                        open={openNoteModal}
                        onClose={noteModalHandleClose}
                        maxWidth={800}
                    >
                        <form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <Field name="noteText" label="Note" component={UiTextareaField} value={"noteText"} rows={3} />
                            </div>
                            <div className="ui-modal__footer ui-modal__footer--buttons">
                                <Button onClick={noteModalHandleClose} variant="outlined" color="primary" key="cancel">Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={sendingNote ? <CircularProgress size={20} /> : <Add />}
                                    disabled={sendingNote}>
                                    Add note
                                </Button>
                            </div>

                            <div className="ui-modal__footer ui-modal__footer--buttons">
                                <NotesTable ref={childRef} idQuestionnaire={sendNoteInitialValues.idQuestionnaire.toString()} />
                            </div>
                        </form>
                    </UiModal>
                )}
            </Formik>
        </>
    )
}