import { Team } from "../../shared/components/teams/Teams";

export const teamList: Team[] = [
    {
        name: 'Core Team',
        teamMembers: [
            {
                name: 'Joffrey Python',
                imageSrc: '/assets/img/security_team/joffrey-python.jpg'
            },
            {
                name: 'Hussein Ghazy',
                imageSrc: '/assets/img/security_team/hussein-ghazy.jpg'
            },
            {
                name: 'Cedric De Carvalho',
                imageSrc: '/assets/img/security_team/cedric-de-carvalho.jpg'
            },
            {
                name: 'Thierry Blanc',
                imageSrc: '/assets/img/security_team/thierry-blanc.jpg'
            },
            {
                name: 'Pierre Olodo',
                imageSrc: '/assets/img/security_team/pierre-olodo.jpg'
            },
            {
                name: 'Loubna Boughanim',
                imageSrc: '/assets/img/security_team/loubna-boughanim.jpg'
            },
        ],
    },
    {
        name: 'Americas - Points of contact',
        teamMembers: [
            {
                name: 'Matthew Ziino',
                imageSrc: '/assets/img/security_team/matthew-ziino.jpg'
            },
        ]
    },
    {
        name: 'China - Points of contact',
        teamMembers: [
            {
                name: 'Franck Wu',
                imageSrc: '/assets/img/security_team/frank-wu.jpg'
            },
            {
                name: 'Holly Zhang',
                imageSrc: '/assets/img/security_team/holly-zhang.jpg'
            },
        ]
    },
    {
        name: 'Korea - Point of contact',
        teamMembers: [
            {
                name: 'Sk Seo',
                imageSrc: '/assets/img/security_team/sk-seo.jpg'
            },
        ]
    }
]

export const PAGE_NUMBER_KEY = 'pageNumber';
