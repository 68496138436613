import React from 'react';
import {history} from "../../shared/routes/RouterHistory"
import './UiLogo.scss';

class UiLogo extends React.Component<any> {
    render() {
        return (
            <a onClick={() => history.push("/")}>
                <svg width="225px" height="14px" viewBox="0 0 225 14" version="1.1">
                    <g id="01_CYBER_JOURNEY" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="02-Dashboard-v3" transform="translate(-52.000000, -33.000000)" fill="#004165">
                            <g id="logo/blue" transform="translate(52.000000, 33.000000)">
                                <g id="logo">
                                    <path d="M7.22199153,6.4358 L8.85292373,6.4358 C12.1802542,6.4358 13.9777119,5.71289091 13.9777119,3.46525455 C13.9777119,1.77252727 12.0811017,0.686254545 9.11860169,0.686254545 C8.12135593,0.686254545 7.68788136,0.742890909 7.22199153,0.761981818 L7.22199153,6.4358 Z M2.96224576,2.01752727 C2.96224576,0.628345455 1.63067797,0.571072727 0.597838983,0.571072727 L0.000381355932,0.571072727 L0.000381355932,0.000254545455 C1.065,0.000254545455 3.12813559,0.0778909091 5.16012712,0.0778909091 C7.15716102,0.0778909091 8.75377119,0.000254545455 10.5188136,0.000254545455 C14.7099153,0.000254545455 18.4376695,0.648072727 18.4376695,3.34943636 C18.4376695,5.06507273 16.4406356,6.11125455 13.8105508,6.70307273 L19.5029237,11.5757091 C20.435339,12.3756182 21.1020763,12.6047091 22.7323729,12.7198909 L22.7323729,13.2894364 C21.6347034,13.2894364 20.5688136,13.2137091 19.4705085,13.2137091 C18.4376695,13.2137091 17.374322,13.2894364 16.3414831,13.2894364 C13.7800424,11.3688909 11.582161,9.31025455 9.41987288,7.12116364 L7.22199153,7.12116364 L7.22199153,11.1964364 C7.22199153,12.6626182 8.42199153,12.7198909 9.95186441,12.7198909 L10.5505932,12.7198909 L10.5505932,13.2894364 C8.65207627,13.2894364 6.72432203,13.2137091 4.82707627,13.2137091 C3.22983051,13.2137091 1.66563559,13.2894364 0.000381355932,13.2894364 L0.000381355932,12.7198909 L0.597838983,12.7198909 C1.83152542,12.7198909 2.96224576,12.3953455 2.96224576,11.6908909 L2.96224576,2.01752727 Z" id="Fill-1"></path>
                                    <path d="M29.6036441,12.8621818 L29.9557627,12.8621818 C30.8532203,12.8621818 31.8015254,12.7902727 31.8015254,12.0482727 L31.8015254,4.564 C31.8015254,3.82136364 30.8532203,3.752 29.9557627,3.752 L29.6036441,3.752 L29.6036441,3.32309091 C30.5767373,3.32309091 32.0761017,3.37972727 33.2989831,3.37972727 C34.5479237,3.37972727 36.0453814,3.32309091 37.2180508,3.32309091 L37.2180508,3.752 L36.867839,3.752 C35.9703814,3.752 35.0201695,3.82136364 35.0201695,4.564 L35.0201695,12.0482727 C35.0201695,12.7902727 35.9703814,12.8621818 36.867839,12.8621818 L37.2180508,12.8621818 L37.2174153,13.2891818 C36.0199576,13.2891818 34.5199576,13.2344545 33.2748305,13.2344545 C32.050678,13.2344545 30.5767373,13.2891818 29.6036441,13.2891818 L29.6036441,12.8621818 Z" id="Fill-3"></path>
                                    <path d="M62.4050212,6.2643 C61.7033263,4.87957273 60.5579873,3.7373 56.813072,3.7373 C52.7192161,3.7373 50.5721822,5.5783 50.5721822,8.07857273 C50.5721822,10.4630273 52.6931568,12.8761182 57.4111653,12.8761182 C60.0838347,12.8761182 61.8552331,11.9209364 62.5035381,10.4903909 L63.2033263,10.5502091 C62.8791737,11.3195727 62.7031144,12.0921182 62.5791737,12.8621182 C61.1300212,13.2210273 59.158411,13.5054818 56.8607415,13.5054818 C51.2452754,13.5054818 47.0776907,11.7497545 47.0776907,8.16257273 C47.0776907,4.96611818 51.5948517,3.10857273 56.738072,3.10857273 C59.6573517,3.10857273 60.6062924,3.62275455 62.9522669,3.8213 C62.92875,4.60848182 62.9783263,5.40775455 63.15375,6.20702727 L62.4050212,6.2643 Z" id="Fill-5"></path>
                                    <path d="M77.4301907,12.0475091 C77.4301907,12.8194182 78.4782839,12.8620545 79.3776483,12.8620545 L79.853072,12.8620545 L79.853072,13.2890545 C78.7528602,13.2890545 77.15625,13.2343273 75.8081568,13.2343273 C74.5350636,13.2343273 73.0363347,13.2890545 72.0371822,13.2890545 L72.0371822,12.8620545 L72.3867585,12.8620545 C73.2861229,12.8620545 74.2350636,12.7901455 74.2350636,12.0475091 L74.2350636,4.56450909 C74.2350636,3.82123636 73.2861229,3.75123636 72.3867585,3.75123636 L72.0371822,3.75123636 L72.0371822,3.32232727 C73.0363347,3.32232727 74.5350636,3.37896364 75.8081568,3.37896364 C77.15625,3.37896364 78.7528602,3.32232727 79.853072,3.32232727 L79.853072,3.75123636 L79.3776483,3.75123636 C78.4782839,3.75123636 77.4301907,3.79387273 77.4301907,4.56450909 L77.4301907,7.69096364 L86.4924788,7.69096364 L86.4924788,4.56450909 C86.4924788,3.79387273 85.44375,3.75123636 84.5443856,3.75123636 L84.0702331,3.75123636 L84.0702331,3.32232727 C85.1679025,3.32232727 86.7657839,3.37896364 88.1151483,3.37896364 C89.3876059,3.37896364 90.8850636,3.32232727 91.8816737,3.32232727 L91.8816737,3.75123636 L91.5340042,3.75123636 C90.6365466,3.75123636 89.6876059,3.82123636 89.6876059,4.56450909 L89.6876059,12.0475091 C89.6876059,12.7901455 90.6365466,12.8620545 91.5340042,12.8620545 L91.8816737,12.8620545 L91.8816737,13.2890545 C90.8850636,13.2890545 89.3876059,13.2343273 88.1151483,13.2343273 C86.7657839,13.2343273 85.1679025,13.2890545 84.0702331,13.2890545 L84.0702331,12.8620545 L84.5443856,12.8620545 C85.44375,12.8620545 86.4924788,12.8194182 86.4924788,12.0475091 L86.4924788,8.49214545 L77.4301907,8.49214545 L77.4301907,12.0475091 Z" id="Fill-7"></path>
                                    <path d="M100.819258,12.8621818 L101.168835,12.8621818 C102.068199,12.8621818 103.01714,12.7902727 103.01714,12.0482727 L103.01714,4.564 C103.01714,3.82136364 102.068199,3.752 101.168835,3.752 L100.819258,3.752 L100.819258,3.32309091 C102.34214,3.32309091 104.937267,3.37972727 107.035996,3.37972727 C109.132818,3.37972727 111.727945,3.32309091 113.424343,3.32309091 C113.374767,3.93654545 113.400191,4.87963636 113.473919,5.50772727 L112.726462,5.62227273 C112.599979,4.69254545 112.30125,3.95181818 109.680699,3.95181818 L106.209725,3.95181818 L106.209725,7.69109091 L109.182394,7.69109091 C110.679852,7.69109091 111.003369,7.20681818 111.152733,6.43554545 L111.901462,6.43554545 C111.85125,6.993 111.827097,7.55045455 111.827097,8.106 C111.827097,8.64945455 111.85125,9.19163636 111.901462,9.73381818 L111.152733,9.81972727 C111.003369,8.96509091 110.930275,8.40763636 109.205911,8.40763636 L106.209725,8.40763636 L106.209725,11.7339091 C106.209725,12.6636364 107.659513,12.6636364 109.255487,12.6636364 C112.251674,12.6636364 113.574343,12.5490909 114.323708,10.9206364 L115.024131,11.0199091 C114.696801,11.7765455 114.398708,12.5344545 114.174343,13.2891818 C112.576462,13.2891818 109.706123,13.2344545 107.459301,13.2344545 C105.211843,13.2344545 102.242987,13.2891818 100.819258,13.2891818 L100.819258,12.8621818 Z" id="Fill-9"></path>
                                    <path d="M135.290275,10.7921545 L141.207013,3.32251818 C141.955106,3.32251818 142.85447,3.37915455 143.752564,3.37915455 C144.627775,3.37915455 145.525869,3.32251818 146.399809,3.32251818 L146.399809,3.75142727 L146.173538,3.75142727 C145.426716,3.75142727 143.903835,3.7807 143.854258,4.40815455 C143.826292,4.77851818 143.927352,5.20742727 143.977564,5.52115455 L144.926504,12.0610636 C145.025021,12.7763364 145.651081,12.8329727 147.148538,12.8622455 L147.148538,13.2892455 C145.974597,13.2618818 144.751716,13.2345182 143.552352,13.2345182 C142.180106,13.2345182 140.80786,13.2618818 139.435614,13.2892455 L139.435614,12.8622455 C140.733496,12.8196091 141.582013,12.8196091 141.582013,12.1902455 C141.582013,12.0203364 141.582013,11.8637909 141.555953,11.4902455 L140.858072,5.46515455 L140.80786,5.46515455 L134.442394,13.5056091 L133.844301,13.5056091 L127.802352,5.62233636 L127.752775,5.62233636 L126.904894,11.8911545 C126.904894,12.3474273 127.105106,12.5758818 127.428623,12.7044273 C127.802352,12.8329727 128.326716,12.8469727 129.025233,12.8622455 L129.325233,12.8622455 L129.325233,13.2892455 C128.202775,13.2618818 127.053623,13.2345182 125.931165,13.2345182 C124.956801,13.2345182 123.958284,13.2618818 122.985191,13.2892455 L122.985191,12.8622455 L123.333496,12.8622455 C125.307648,12.8196091 125.381377,12.3340636 125.606377,11.3063364 L126.355742,7.33606364 C126.556589,6.32233636 126.65447,5.39451818 126.65447,4.82115455 C126.65447,4.02060909 125.580318,3.75142727 124.482013,3.75142727 L124.15786,3.75142727 L124.15786,3.32251818 C125.181165,3.32251818 126.179682,3.37915455 127.203623,3.37915455 C127.952987,3.37915455 128.701716,3.32251818 129.448538,3.32251818 L135.290275,10.7921545 Z" id="Fill-11"></path>
                                    <path d="M164.147097,12.8758636 C168.989682,12.8758636 169.838199,10.4354091 169.838199,8.34940909 C169.838199,6.26404545 167.866589,3.73831818 163.722521,3.73831818 C159.354089,3.73831818 158.056843,5.96622727 158.056843,7.87786364 C158.056843,10.4354091 160.104725,12.8758636 164.147097,12.8758636 M163.772097,3.10831818 C169.08947,3.10831818 173.332691,4.99322727 173.332691,8.03440909 C173.332691,11.3199545 169.214682,13.5052273 163.898581,13.5052273 C158.60536,13.5052273 154.562352,11.4491364 154.562352,8.37677273 C154.562352,5.40877273 158.581208,3.10831818 163.772097,3.10831818" id="Fill-13"></path>
                                    <path d="M196.896229,10.4764545 L196.945805,10.4490909 L196.945805,5.09409091 C196.945805,3.92063636 195.523983,3.752 194.775254,3.752 L194.225466,3.752 L194.225466,3.32245455 C195.400678,3.32245455 196.544746,3.37972727 197.719958,3.37972727 C198.743263,3.37972727 199.767839,3.32245455 200.789873,3.32245455 L200.789873,3.752 L200.41678,3.752 C199.367415,3.752 198.194746,3.86590909 198.194746,5.56436364 L198.194746,12.061 C198.194746,12.5618182 198.218898,13.0613636 198.345381,13.5055455 L197.396441,13.5055455 L184.542203,5.30854545 L184.542203,11.1917273 C184.542203,12.4351818 184.965508,12.8621818 186.885,12.8621818 L187.311483,12.8621818 L187.311483,13.2891818 C186.239237,13.2891818 185.163178,13.2344545 184.091568,13.2344545 C182.967203,13.2344545 181.821229,13.2891818 180.6975,13.2891818 L180.6975,12.8621818 L181.045805,12.8621818 C182.768263,12.8621818 183.293263,12.1901818 183.293263,11.0479091 L183.293263,5.03554545 C183.293263,4.23627273 182.144746,3.752 181.021017,3.752 L180.6975,3.752 L180.6975,3.32245455 C181.646441,3.32245455 182.617627,3.37972727 183.566568,3.37972727 C184.315297,3.37972727 185.039237,3.32245455 185.786695,3.32245455 L196.896229,10.4764545 Z" id="Fill-15"></path>
                                    <path d="M210.526144,4.03575455 C208.653686,4.03575455 208.579322,4.29475455 208.204958,5.33584545 L207.456229,5.33584545 C207.555381,4.9343 207.679958,4.53657273 207.754322,4.1223 C207.855381,3.72393636 207.903051,3.32302727 207.903051,2.90811818 L208.503686,2.90811818 C208.703263,3.33575455 209.328686,3.32302727 210.00178,3.32302727 L222.880169,3.32302727 C223.553898,3.32302727 224.178686,3.30839091 224.228898,2.88011818 L224.826992,2.93611818 C224.72911,3.32302727 224.629322,3.70611818 224.552415,4.09493636 C224.504746,4.47802727 224.504746,4.86620909 224.504746,5.24993636 L223.755381,5.40775455 C223.703898,4.87957273 223.581229,4.03575455 221.907712,4.03575455 L217.81322,4.03575455 L217.81322,11.6358455 C217.81322,12.7335727 218.689068,12.8621182 219.886525,12.8621182 L220.360678,12.8621182 L220.360678,13.2891182 C219.385678,13.2891182 217.637797,13.2343909 216.289703,13.2343909 C214.793517,13.2343909 213.047542,13.2891182 212.073178,13.2891182 L212.073178,12.8621182 L212.545424,12.8621182 C213.919576,12.8621182 214.619364,12.7908455 214.619364,11.6632091 L214.619364,4.03575455 L210.526144,4.03575455 Z" id="Fill-17"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </a>
        )
    }
}

export default UiLogo;