import { Box, Button, Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Field, FieldProps, FormikProps } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { FormService, ThirdPartyTask } from '../../../services/formService';
import { ReferenceService } from '../../../services/referenceService';
import { UiAutocompleteField, UiTextField } from '../../../shared/components/fields';
import { IUiMultiselectOption } from '../../../shared/components/fields/UiAutocompleteField';
import { UiBoxV2 } from '../../../shared/components/ui/UiBoxV2';
import { UiModal } from '../../../shared/components/ui/UiModal/UiModal';
import {baseCreateNotification, updateMultipleSelectedValues} from '../../../shared/helpers/HelpersFunc';
import { CommonProperties, Reference } from '../../../shared/models/interfaces';
import { IThirdParty } from '../../thirdParties/components/AddOrEditThirdPartyModal';
import {notificationMessage} from "../../../shared/config/GlobalAppConfig";
import {NotificationType} from "../../../shared/models/enums";

export interface IThirdPartyInformation extends CommonProperties {
    idQuestionnaire?: number,
    thirdPartyId: number,
    tasks?: ITask[],
    tasksSaved?: ITask[],
    tasksSelected?: ITask[],
    thirdParty: IThirdParty,
}

export interface ITask {
    idThirdPartyInformation?: number,
    idTask?: number
}

interface ThirdPartyProps extends FieldProps, FormikProps<any> {
    index: number,
    thirdPartiesList: Reference[],
    thirdPartyActivities: Reference[],
    disable?: boolean,
    item: any
}

export function ThirdParty(props: ThirdPartyProps) {
    const { field, thirdPartyActivities, index, disable, form } = props;
    const [openMessageModal, setOpenMessageModal] = useState(false);
    const [thirdPartyToBeUsed, setThirdPartyToBeUsed] = useState<IThirdParty>();
    const { setFieldValue } = form;

    const handleCustomSelect = function (values: Array<IUiMultiselectOption>, _projectId: number, _name: string) {
        updateMultipleSelectedValues(values, `${field.name}.${index}.tasks`, field, setFieldValue);
    }

    const handleBlur = function(event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) {
        const alreadyAdded = props.form.values
            ?.thirdParties
            ?.filter((tp: any) =>
                tp.thirdParty.companyName?.toLowerCase() === event.target.value.toLowerCase()
                && !tp.isDeleted);

        const elFound = props.thirdPartiesList.filter(tp => tp.itemContent?.toLowerCase() === event.target.value.toLowerCase())[0];

        if(alreadyAdded?.length > 1){
            baseCreateNotification(NotificationType.warning, notificationMessage.ThirdPartyForm_already_added.title, notificationMessage.ThirdPartyForm_already_added.message);
            setFieldValue(`${field.name}.${index}.thirdParty.companyName`, '');
            return;
        }

        if(elFound) {
            setThirdPartyToBeUsed(elFound.itemtKey);
            setFieldValue(`${field.name}.${index}.thirdParty.companyName`, '');
            setOpenMessageModal(true);
        }
    }

    const cancel = function() {
        setOpenMessageModal(false);
    }

    const confirm = function() {
        setFieldValue(`${field.name}.${index}.thirdParty`,thirdPartyToBeUsed);
        setOpenMessageModal(false);
    }

    useEffect(() => {
        if (field.value[index].tasksSaved) {
            const selectedtps = field.value.map((tpi: IThirdParty) => {
                if (tpi.tasksSaved?.length) {
                    tpi.tasks = tpi.tasksSaved.map((t: ThirdPartyTask) => {
                        return thirdPartyActivities.find((activity: Reference) => activity.id === t.idTask) as Reference
                    })
                }
                return tpi;
            })

            setFieldValue(`${field.name}`, selectedtps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value[index].tasksSaved, thirdPartyActivities])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <div className="section-title">Company Profile</div>
                <Field
                    name={`${field.name}.${index}.thirdParty.companyName`}
                    label="Company name*"
                    component={UiTextField}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => handleBlur(event, setFieldValue)}
                    disabled={disable}
                />
                <Field
                    name={`${field.name}.${index}.thirdParty.companyWebsite`}
                    label="Company website (www.xxxxx.com format)*"
                    component={UiTextField}
                    disabled={disable}
                />
                <Field
                    name={`${field.name}.${index}.tasks`}
                    label="Will the third party do any of the following?"
                    multiple={true}
                    component={UiAutocompleteField}
                    options={thirdPartyActivities}
                    disabled={disable}
                    onCustomSelect={handleCustomSelect}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <div className="section-title">Company point of contact</div>
                <Field
                    name={`${field.name}.${index}.thirdParty.contactName`}
                    label="Full name"
                    component={UiTextField}
                    disabled={disable}
                />
                <Field
                    name={`${field.name}.${index}.thirdParty.emailContact`}
                    label="Email"
                    component={UiTextField}
                    disabled={disable}
                />
                <Field
                    name={`${field.name}.${index}.thirdParty.phoneNumber`}
                    label="Phone number (with country code)"
                    component={UiTextField}
                    disabled={disable}
                />
            </Grid>
            <UiModal title="Warning" open={openMessageModal} onClose={cancel}>
                <div>
                    <div className="row">
                        <Typography>Company name already exists!</Typography>
                        <Typography>Do you want to use the existing one?</Typography>
                    </div>
                    <div className="ui-modal__footer ui-modal__footer--buttons">
                        <Button onClick={cancel} variant="outlined" color="primary" key="cancel">Cancel</Button>
                        <Button onClick={confirm} variant="contained" color="primary" key="confirm">Confirm</Button>
                    </div>
                </div>
            </UiModal>
        </Grid>
    )
}

interface ThirdPartiesProps extends FieldProps, FormikProps<any> {
    isThereThirdParty: boolean,
    thirdPartiesList: IThirdPartyInformation[],
    disable?: boolean
}

const newThirdPartyItem: IThirdParty = {
    contactName: '',
    companyName: '',
    companyWebsite: '',
    emailContact: '',
    tasks: [],
    tasksSaved: [],
    tasksSelected: [],
    phoneNumber: ''
}

export const newThirdPartyInformation: IThirdPartyInformation = {
    thirdPartyId: 0,
    thirdParty: newThirdPartyItem,
    tasks: [],
    tasksSaved: [],
    tasksSelected: []
}

export default function ThirdParties(props: ThirdPartiesProps) {
    const { field, form, thirdPartiesList, isThereThirdParty, disable } = props;
    const [thirdPartyActivities, setThirdPartyActivities] = useState<Reference[]>([]);
    const [thirdParties, setThirdParties] = useState<Reference[]>([]);

    const onAddThirdParty = () => {
        /* if(form.values.thirdPartyNameInvolved){
            let tempArr = thirdPartiesList.slice();
            const newItem = tempArr[tempArr.length-1];
            newItem.thirdParty = thirdParties.filter(i => i.id === form.values.thirdPartyNameInvolved)[0].itemtKey;
            newItem.thirdPartyId = newItem.thirdParty.id ?? 0
            tempArr.push(Object.assign({}, newThirdPartyInformation));
                      
            form.setFieldValue('thirdParties', tempArr);
            form.setFieldValue('thirdPartyNameInvolved', '');
        }
        else { */
        thirdPartiesList.push(newThirdPartyInformation);
        form.setFieldValue('thirdParties', thirdPartiesList.slice());
        /* } */
    }

    const handleThirdPartyDeletion = function (index: number) {
        if(thirdPartiesList[index].id) {
            thirdPartiesList[index].isDeleted = true;
        }
        else {
            thirdPartiesList.splice(index, 1);
        }
        form.setFieldValue('thirdParties', thirdPartiesList.slice());
    }

    useEffect(() => {
        const thirdPartyActivitiesSubscription = ReferenceService.GetListThirdPartyActivies().subscribe(activitiesList => {
            setThirdPartyActivities(activitiesList);
        })

        const thirdPartySubscription = FormService.getThirdParties().subscribe(thirdPartiesList => {
            setThirdParties(thirdPartiesList);
        })

        return () => {
            thirdPartyActivitiesSubscription.unsubscribe();
            thirdPartySubscription.unsubscribe();
        }
    }, [])

    return (
        <>
            {isThereThirdParty && (
                <div id={field.name}>
                    {
                        thirdPartiesList?.map((item, index) => (
                            <Fragment key={index}>
                                {
                                    !item.isDeleted && <UiBoxV2 title={`Third Party ${index + 1}`} showDelete={thirdPartiesList.length > 1 && !disable} onDelete={() => handleThirdPartyDeletion(index)}>
                                        <Field
                                            name="thirdParties"
                                            index={index}
                                            item={item}
                                            thirdPartiesList={thirdParties}
                                            thirdPartyActivities={thirdPartyActivities}
                                            disable={disable}
                                            component={ThirdParty} />
                                    </UiBoxV2>
                                }
                            </Fragment>
                        ))
                    }
                    {!disable && (
                        <Grid container
                            spacing={3}
                            alignItems='flex-end'
                            direction='row'
                        >
                           {/*  <Grid item xs={12} md={6}>
                                <Field
                                    name="thirdPartyNameInvolved"
                                    //label=""
                                    component={UiSelectField}
                                    options={thirdParties}
                                    enableValue_None={true}
                                />
                            </Grid> */}
                            <Grid item xs={12} md={6}>
                                <Box m={1} mt={1.2} display="flex" alignItems="right">
                                    <Button data-testid="addThirdPartyButton" variant="contained" color="primary" onClick={onAddThirdParty}><AddCircleIcon />Add Extra Third Party</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </div>
            )}
        </>
    )
}