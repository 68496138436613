import { Grid } from '@material-ui/core';
import { Field, FieldProps, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { ProgrammingLanguage } from '../../../services/formService';
import { ReferenceService } from '../../../services/referenceService';
import { UiAutocompleteField, UiSelectField, UiSwitchField, UiTextField } from '../../../shared/components/fields';
import { IUiMultiselectOption } from '../../../shared/components/fields/UiAutocompleteField';
import { updateMultipleSelectedValues } from '../../../shared/helpers/HelpersFunc';
import { AppType as AppTypeEnum, ProgrammingLanguage as ProgrammingLanguageEnum } from '../../../shared/models/enums';
import { CommonProperties, Reference } from '../../../shared/models/interfaces';
import { Option } from './../../../shared/components/SelectField';

export interface IDigitalApplication extends CommonProperties {
    appName: string,
    appTypes?: Reference[],
    appTypesSaved?: IAppType[],
    appTypesSelected?: IAppType[],
    scopes?: Option[],
    scopesSaved?: Scope[],
    scopesSelected?: Scope[],
    usersWithFunctionalPrivilegedAccess?: string,
    usersToInteractWithApp?: string,
    usersWithDataPrivilegedAccess?: string,
    appHost?: Reference | string,
    appHostedCountrySelected?: Reference | string,
    appHostedCountry?: Reference | string,
    dataHostedCountrySelected?: Reference | string,
    dataHostedCountry?: Reference | string,
    projectRegionToOperate?: Option[],
    digitalApplicationAccessibleFrom?: number | string,
    programmingLanguages?: Option[],
    programmingLanguagesSaved?: ProgrammingLanguage[],
    programmingLanguagesSelected?: ProgrammingLanguage[],
    isThereAnyInterconnections?: boolean,
    isItDesignedDeveloperForThePurposedOfTheProject?: boolean,
    idQuestion?: number | string,
    doesItCollectInformation?: boolean,
    isApplicationAllowingUserInputs?: boolean,
    isStaticApp?: boolean,
    appTypeOther?: string,
    otherProgrammingLanguage?: string
}

export interface IAppType extends CommonProperties {
    idAppType: number
    idDigitalApplication: number
}

export interface Scope extends CommonProperties {
    idDigitalApplication: number
    idScope: number
}

export interface DigitalApplicationProps extends FieldProps, FormikProps<any> {
    isThereAnyDigitalApplicationInvolved: boolean,
    countriesList: Reference[],
    isDisabled?: boolean
}

type CountryIsFor = 'data' | 'application';

export function HostedLabel({ word }: { word: string }) {
    return (
        <label>In which country/geographic area would the <u>{word}</u> be hosted?</label>
    )
}

export default function DigitalApplication(props: DigitalApplicationProps) {
    const { field, form, isThereAnyDigitalApplicationInvolved, isDisabled } = props;
    const { setFieldValue } = form;
    const [applicationTypeList, setApplicationTypeList] = useState([]);
    const [applicationScopeList, setApplicationScopeList] = useState([]);
    const [numberOfUsersList, setNumberOfUsersList] = useState([]);
    const [applicationHostEnvList, setApplicationHostEnvList] = useState([]);
    const [applicationAccessUsersList, setApplicationAccessUsersList] = useState([]);
    const [programmingLanguageList, setProgrammingLanguageList] = useState([]);
    const [questionsList, setQuestionsList] = useState([]);
    const [countriesListForApp, setCountriesListForApp] = useState<Reference[]>([]);
    const [countriesListForData, setCountriesListForData] = useState<Reference[]>([]);
    const [countriesSubscription, setCountriesSubscription] = useState<Subscription>();
    let timer: NodeJS.Timeout | null = null;

    const findCountries = function (event: React.ChangeEvent, countrySearch: string, countryIsFor: CountryIsFor) {
        if (timer) clearTimeout(timer);

        // Avoid multiple requests in a row almost instantly
        timer = setTimeout(() => {
            if (event.type === 'change') {
                const tempSubscription = ReferenceService.GetListCountries(countrySearch).subscribe(countryList => {
                    if (countryIsFor === 'data')
                        setCountriesListForData(countryList);
                    else {
                        setCountriesListForApp(countryList)
                    }
                });
                setCountriesSubscription(tempSubscription);
            }
        }, 500);
    }

    const handleCustomSelect = function (values: Array<IUiMultiselectOption>, _projectId: number, name: string) {
        updateMultipleSelectedValues(values, name, field, setFieldValue);
    }

    //Preselect values from dropdown lists
    useEffect(() => {
        if (field.value?.programmingLanguagesSelected) {
            const selectedPls = field.value.programmingLanguagesSelected.map((pl: ProgrammingLanguage) => {
                return programmingLanguageList.find((pli: Reference) => pli.id === pl.idProgrammingLanguage)
            })
            setFieldValue(`${field.name}.programmingLanguages`, selectedPls);
        }

        if (field.value?.appTypesSelected) {
            const selectedAtls = field.value.appTypesSelected.map((at: IAppType) => {
                return applicationTypeList.find((atl: Reference) => atl.id === at.idAppType)
            })
            setFieldValue(`${field.name}.appTypes`, selectedAtls);
        }

        if (field.value?.scopesSelected) {
            const selectedScopes = field.value.scopesSelected.map((s: Scope) => {
                return applicationScopeList.find((scope: Reference) => scope.id === s.idScope)
            })
            setFieldValue(`${field.name}.scopes`, selectedScopes);
        }

        if (field.value?.appHostedCountrySelected && typeof field.value?.appHostedCountrySelected === 'number' && countriesListForApp.length) {
            const selectedAppHostedCountry = countriesListForApp.find((clapp: Reference) => clapp.id === field.value?.appHostedCountrySelected) as Reference | undefined
            setFieldValue(`${field.name}.appHostedCountrySelected`, selectedAppHostedCountry);
        }

        if (field.value?.dataHostedCountrySelected && typeof field.value?.dataHostedCountrySelected === 'number' && countriesListForApp.length) {
            const selectedDataHostedCountry = countriesListForData.find((clData: Reference) => clData.id === field.value?.dataHostedCountrySelected) as Reference | undefined
            setFieldValue(`${field.name}.dataHostedCountrySelected`, selectedDataHostedCountry ?? '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        field.value?.programmingLanguagesSaved,
        field.value?.dataHostedCountry,
        field.value?.appHostedCountry,
        programmingLanguageList,
        countriesListForData,
        countriesListForApp
    ])

    useEffect(() => {
        setCountriesListForApp(props.countriesList.slice());
        setCountriesListForData(props.countriesList.slice());
    }, [props.countriesList])

    //Load all lists needed to show Digital Application form
    useEffect(() => {
        const applicationTypeSubscription = ReferenceService.GetListApplicationType().subscribe(appTypeList => {
            setApplicationTypeList(appTypeList);
        })

        const applicationScopeSubscription = ReferenceService.GetListApplicationScope().subscribe(appScopeList => {
            setApplicationScopeList(appScopeList);
        })

        const numberOfUsersSubscription = ReferenceService.GetListNbStandardUsers().subscribe(numberOfUsersList => {
            setNumberOfUsersList(numberOfUsersList);
        })

        const applicationHostEnvSubscription = ReferenceService.GetListApplicationHostEnv().subscribe(hostEnvList => {
            setApplicationHostEnvList(hostEnvList);
        })

        const applicationAccessUsersSubscription = ReferenceService.GetListApplicationAccessUsers().subscribe(accessUsersList => {
            setApplicationAccessUsersList(accessUsersList);
        })

        const programmingLanguageSubscription = ReferenceService.GetListProgrammingLanguage().subscribe(programmingLanguageList => {
            setProgrammingLanguageList(programmingLanguageList);
        })

        const questionsSubscription = ReferenceService.GetListQuestions().subscribe(questionsList => {
            setQuestionsList(questionsList);
        })

        return () => {
            applicationTypeSubscription.unsubscribe();
            applicationScopeSubscription.unsubscribe();
            numberOfUsersSubscription.unsubscribe();
            applicationHostEnvSubscription.unsubscribe();
            applicationAccessUsersSubscription.unsubscribe();
            programmingLanguageSubscription.unsubscribe();
            countriesSubscription?.unsubscribe();
            questionsSubscription?.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isThereAnyDigitalApplicationInvolved && (
                <div id={field.name}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name={`${field.name}.appName`}
                                label="What is the name of the digital application?*"
                                component={UiTextField}
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name={`${field.name}.appTypes`}
                                label="What kind of digital application it is?"
                                component={UiAutocompleteField}
                                showSecondLabel={true}
                                options={applicationTypeList}
                                multiple={true}
                                onCustomSelect={handleCustomSelect}
                                disabled={isDisabled}
                            />
                            {
                                !!field.value.appTypes?.filter((t: Reference) => t.id === AppTypeEnum.other).length && (
                                    <Field
                                        name={`${field.name}.appTypeOther`}
                                        label="Specify the other type here"
                                        component={UiTextField}
                                    />
                                )
                            }
                        </Grid>
                    </Grid>
                    <div className="section-title">Access to the application</div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name={`${field.name}.scopes`}
                                label="Who would be accessing the application?"
                                component={UiAutocompleteField}
                                multiple={true}
                                options={applicationScopeList}
                                disabled={isDisabled}
                                onCustomSelect={handleCustomSelect}
                            />
                            <Field
                                name={`${field.name}.usersWithFunctionalPrivilegedAccess`}
                                label="How many users will have functional administrator role?"
                                component={UiTextField}
                                tooltip="People being able to manage the access rights and permissions for the solution"
                                // tooltipLabel="Tooltip"
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name={`${field.name}.usersToInteractWithApp`}
                                label="How many users will access the application?"
                                component={UiSelectField}
                                options={numberOfUsersList}
                                disabled={isDisabled}
                            />
                            <Field
                                name={`${field.name}.usersWithDataPrivilegedAccess`}
                                label="How many users will have technical administrator role?"
                                component={UiTextField}
                                tooltip="People having full access to the database"
                                // tooltipLabel="Tooltip"
                                disabled={isDisabled}
                            />
                        </Grid>
                    </Grid>
                 
                   
                    <div className="section-title">Development</div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name={`${field.name}.isItDesignedDeveloperForThePurposedOfTheProject`}
                                label="Is there going to be any code development?"
                                component={UiSwitchField}
                                disabled={isDisabled}
                            />
                            <Field
                                name={`${field.name}.isApplicationAllowingUserInputs`}
                                tooltip="text box to fill, document to upload etc"
                                label="Is this an application allowing for user input?"
                                component={UiSwitchField}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                field.value.isItDesignedDeveloperForThePurposedOfTheProject && (
                                    <Field
                                        name={`${field.name}.programmingLanguages`}
                                        label="What is the programming language in which the application is developed?"
                                        component={UiAutocompleteField}
                                        options={programmingLanguageList}
                                        multiple={true}
                                        disabled={isDisabled}
                                        onCustomSelect={handleCustomSelect}
                                    />
                                )
                            }
                            {
                                field.value.isItDesignedDeveloperForThePurposedOfTheProject && 
                                !!field.value.programmingLanguages?.filter((t: Reference) => t.id === ProgrammingLanguageEnum.other).length && (
                                    <Field
                                        name={`${field.name}.otherProgrammingLanguage`}
                                        label="Specify the other language here"
                                        component={UiTextField}
                                    />
                                )
                            }
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    )
}
