import { AxiosRequestConfig } from "axios";
import { map } from "rxjs/operators";
import {
    urlPartialGetListFlowStatus,
    urlPartialGetNextActions,
    urlPartialGetThirdPartyList
} from "../shared/config/GlobalAppConfig";
import { ApiCallingMethods } from "../shared/helpers/AxiosHelper";
import DataConnectionFactory from "../shared/helpers/DataConnectionFactory";
import { ApiResponse, CommonProperties, Reference } from "../shared/models/interfaces";

interface IThirdParty extends CommonProperties {
    idQuestionnaire: number,
    name: string,
    phoneNumber: string,
    thirdPartyTasks: any,
    url: string,
}

interface IThirdPartyServer extends CommonProperties {
    id: number,
    companyName: string,
}

interface NextActions extends CommonProperties{
    idFlowStatus: number
    idFlowActions: number
    flowAction: string
    idFlowOwner: number
    idNextTask: number
}

export interface ProjectFlow extends CommonProperties{
    idFlowOwner?: number;
	idFlowStatus: number;
	idFlowAction: number;
	actionLabel: string;
	idNextTask?: number;
	displayOrder: number;
    isVisible: boolean;
}

export class FlowRefService {
    static GetAllStatus(thirdPartyNameSearch?: string) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetListFlowStatus,
            data: null,
            params: {
                thirdPartyKey: thirdPartyNameSearch
            }
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetAllThirdParties() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetThirdPartyList,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map((response:ApiResponse<IThirdPartyServer[]>): Reference[] => {
            return response.data.model.map(tp => {
                return {
                    id: tp.id ?? 0,
                    itemContent: tp.companyName
                }
            })
        }));
    }

    static GetNextActions(idStatus: number, questionnaireId: string) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetNextActions}/${idStatus}/${questionnaireId}`
        }

        return DataConnectionFactory.request(options).pipe(map((response:ApiResponse<NextActions[]>) => {
            return response.data.model
        }));
    }
}