import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import UiTooltip from './UITooltip';
import UiBaseField from './UiBaseField';
import { getFieldData } from './UiFieldHelpers';

type IPropsUiSelectField = UiBaseField & {
    options: Array<any>,
    enableValue_None: boolean,
    visible?: boolean
    onCustomSelect?: (value: IPropsUiSelectField) => {}
}

export const UiSelectField = (props: IPropsUiSelectField) => {
    let { error, name, value, onChange } = getFieldData(props);
    const childClasses = (props.column) ? 'ui-field--' + props.column : 'ui-field__field  padr-6';
    const tooltipClass = (props.tooltipLabel) ? 'ui-field__tooltip--bottom' : 'ui-field__tooltip';
    const classes = (props.tooltip) ? 'ui-field ui-field__before-tooltip' : 'ui-field';
    const [selected, setSelected] = useState(value ? value : "");
    useEffect(() => setSelected(value), [value]);

    const renderOption = (option: any) => {
        if (typeof option === 'string') {
            return option
        } else {
            return option.itemContent
        }
    }
    const handleChange = (event: any, selectComponent: any) => {
        setSelected(event.target.value as string)
        if (props.form) {
            props.form.setFieldValue(name, value)
        }
        onChange(event);

        if (props.onCustomSelect) {
            props.onCustomSelect(event.target.value);
        }
    }
    return (
        <React.Fragment>
            <FormControl fullWidth variant="outlined" className={classes} >
                <div className={childClasses}>
                    <InputLabel error={error.hasError} id={name + "-label"}>{props.label}</InputLabel>
                    <Select
                        disabled={props.disabled ? props.disabled : false}
                        fullWidth
                        labelId={name + "-label"}
                        id={name}
                        label={props.label}
                        value={selected || ''}
                        name={name}
                        onChange={handleChange}
                        error={error.hasError}
                    >
                        {props.enableValue_None &&
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                        }
                        {props.options.map((option: any, index: number) => {
                            return <MenuItem key={index} value={option.id}>{renderOption(option)}</MenuItem>
                        })}
                    </Select>
                    {error.hasError && (
                        <FormHelperText error={error.hasError}>{error.errorMessage}</FormHelperText>
                    )}
                </div>
                {props.tooltip && <span className={tooltipClass}> <UiTooltip title={props.tooltip} label={props.tooltipLabel} isClickable={props.isTooltipClickable} /></span>}
            </FormControl>
        </React.Fragment>
    )
}
export default UiSelectField