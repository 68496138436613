import { Button, Container } from '@material-ui/core';
import { Add, Filter3, Assignment } from '@material-ui/icons';
import React, { useContext, useEffect } from "react";
import LoadingContext from '../../contexts/LoadingContext';
import { Teams } from '../../shared/components/teams/Teams';
import { teamList } from './constants';
import {useHistory} from "react-router";
import { dropRight } from 'lodash';

export const IntroPage = () => {
    const ctx = useContext(LoadingContext);
    const video = process.env.REACT_APP_INTRO_VIDEO;
    const videoType = process.env.REACT_APP_INTRO_VIDEO_TYPE;

    const history = useHistory();

    useEffect(() => {
        ctx.stopLoading();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const redirectTo = (path: string) => {
        history.push(path);
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl" >
                <div className="intro-page">
                    <section className="first">
                        <label className="page-title">MYCYBERJOURNEY</label>
                        <p className="moto">Know your Cyber Risks, Make better Business Decisions</p>
                        {/* <div className="action">
                            <Button className="action-item" color="primary" variant="contained" onClick={() => redirectTo("/dashboard/selfAssessment/new")} startIcon={<Add />}>
                                Start an assessement
                            </Button>
                        </div> */}
                        <div className="action">
                            <Button className="action-item" color="primary" variant="contained" onClick={() => redirectTo("/dashboard")} startIcon={<Assignment />}>
                                See previous requests
                            </Button>
                        </div>
                        <div className="action">
                            <Button className="action-item" color="primary" variant="contained" onClick={() => redirectTo("/thirdParty")} startIcon={<Filter3 />}>
                                See assessed third parties
                            </Button>
                        </div>

                        <div className="stepper">
                            <img src="/assets/img/intro/intro.png" />
                        </div>

                    </section>

                    <section className="second">
                        <div className='intro-page--details'>
                            <div className="video">
                                <video controls>
                                    <source src={video} type={videoType} />
                                </video>
                            </div>
                            <div className="team-intro">
                                <h2>Meet The Team</h2>
                                <p className="team-lead">“Individuals can and do make a difference.”</p>
                            </div>
                            <Teams data={teamList}/>
                        </div>

                    </section>  

                </div>
            </Container>
        </React.Fragment>
    )
}

export default IntroPage