import React, { ReactChild, ReactChildren, ReactNode, useState } from 'react';
import UiTooltip from './UITooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { UiModal } from '../ui/UiModal/UiModal';
type IPropsUiTooltipLabel = {
    label: string | ReactNode;
    tooltip?: any;
    cssClass?: string;
    activatePopup?: boolean;
    popupTitle?: string;
    popupContent?: ReactChild | ReactChildren;
}

const UiTooltipLabel = (props: IPropsUiTooltipLabel) => {
    const [showPopup, setShowPopup] = useState(false);

    if (!props.label) return null;

    const handleOpen = function(e: any) {
        setShowPopup(true);
        e.stopPropagation();
    }

    const handleClose = function(e: any) {
        setShowPopup(false);
        e.stopPropagation();
    }
    return (
        <>
            <span className={`ui-tooltip-label ${props.cssClass ?? ''}`}>
                <span className="ui-tooltip-label__label">{props.label} </span>
                { props.activatePopup && props.popupContent && props.popupTitle
                    ? (
                        <>
                            <span className="ui-tooltip-label__icon" onClick={handleOpen}><HelpOutlineIcon /></span>
                            <UiModal title={props.popupTitle} children={props.popupContent} open={showPopup} onClose={handleClose}></UiModal>
                        </>
                    )
                    : <span className="ui-tooltip-label__icon"><UiTooltip title={props.tooltip} /></span>
                }
            </span>
            
        </>
    )
}
export default UiTooltipLabel;