import { DataSubjectInvolved, SensitiveCompanyInfo, TypeBU } from "../../screens/selfAssessmentQuestionnaire/components/DataInformation";
import { IAppType, IDigitalApplication } from "../../screens/selfAssessmentQuestionnaire/components/DigitalApplication";
import { IHosting } from "../../screens/selfAssessmentQuestionnaire/components/Hosting";
import { ITask, IThirdPartyInformation } from "../../screens/selfAssessmentQuestionnaire/components/ThirdParty";
import { ISelfAssessmentQuestionnaire } from "../../screens/selfAssessmentQuestionnaire/selfAssessmentQuestionnaire";
import { IThirdParty } from "../../screens/thirdParties/components/AddOrEditThirdPartyModal";
import { AppType, DataSubjectsType, DataTypeBU, PersonalDataRetained, ProgrammingLanguage, SensitiveDataIds } from "../../shared/models/enums";
import { CommonProperties, NextActionBehavior, Reference } from "../../shared/models/interfaces";
import { Formulaire } from "./formService";

interface IThirdPartyInformationItemServer {
    idQuestionnaire?: number,
    thirdPartyId: number,
    tasks?: any,
    tasksSaved?: any,
    tasksSelected?: any,
    thirdParty: IThirdParty,
}

interface IDigitalApplicationServer extends CommonProperties {
    appName: string,
    usersWithFunctionalPrivilegedAccess?: string,
    idUsersToInteractWithApp?: number | null,
    usersWithDataPrivilegedAccess?: string,
    idAppHost?: number | null,
    idAccessibleFrom?: number | null,
    isThereAnyInterconnections?: boolean,
    isItDesignedForTheProject?: boolean,
    idQuestion?: number | null,
    doesItCollectInformation?: boolean,
    isApplicationAllowingUserInputs?: boolean
    idDataHostedCountry?: number | null,
    idAppHostedCountry?: number | null,
    appTypes?: any,
    scopes?: any,
    programmingLanguages?: any,
    isStaticApp?: boolean
}

interface IDataInformationServer extends CommonProperties {
    sensitiveCompanyInfo?: any,
    isThisProductDataNotReleased?: boolean,
    IsThereCreditCardSwitch?: boolean,
    isTherePersonalDataInvolved: boolean,
    dataUsedFor?: any,
    idPersonalDataRetainedBy?: number | null,
    idDataSubjectsCollected?: number | null,
    idProductInvolved?: number | null,
    isPersonalDataNecessary?: boolean,
    dataSubjectInvolved?: any,
    dataProcessing?: any,
    haveClearProcess?: boolean,
    isTherePersonalDataUsed?: boolean,
    isThereInternationalTransfer?: boolean,
    doesInvolvePiiTransfer?: boolean,
    otherPersonalData?: string,
    personalDataType?: any,
}

export interface ISelfAssessmentQuestionnaireServer extends CommonProperties {
    projectName: string,
    projectDescription: string,
    country: any,
    maison: any,
    region: any,
    maisonProjectOwner: string,
    technicalPointOfContact?: string,
    department: string,
    thirdParties: IThirdPartyInformationItemServer[] | null,
    digitalApplication: IDigitalApplicationServer | null,
    hosting : IHosting | null;
    dataInformation: IDataInformationServer
}

interface SubmitObject {
    nextActionBehavior: NextActionBehavior,
    selfAssessmentQuestionnaire: ISelfAssessmentQuestionnaireServer
}

export const mapFormFromServer = function(formFromserver: Formulaire<ISelfAssessmentQuestionnaireServer>): ISelfAssessmentQuestionnaire {
    const saq = formFromserver.selfAssessmentQuestionnaire ?? formFromserver;

    const thirdParties = saq.thirdParties
        ? saq.thirdParties?.map(tp => {
            const newTp: IThirdPartyInformationItemServer  = Object.assign({}, tp);
            newTp.thirdParty = tp.thirdParty
            newTp.tasksSaved = tp.tasks ?? '';
            newTp.tasksSelected = tp.tasks ?? '';
            return newTp;
        })
        : [];
    const form : ISelfAssessmentQuestionnaire = {
        ...saq,
        isThereAnyDigitalApplicationInvolved: saq.digitalApplication?.appName ? true : false,
        isThereAnyThirdPartyInvolved: saq.thirdParties?.length ? true : false,
        generalInformation: {
            projectName: saq.projectName,
            projectDescription: saq.projectDescription,
            department: saq.department,
            maisonProjectOwner: formFromserver.maisonProjectOwner ? {
                id: formFromserver.maisonProjectOwner.email,
                itemContent: formFromserver.maisonProjectOwner.fullName
            } as Reference : '',
            technicalPointOfContact: formFromserver.technicalPointOfContact ? {
                id: formFromserver.technicalPointOfContact.email,
                itemContent: formFromserver.technicalPointOfContact.fullName
            } as Reference : '',
            country: saq.country,
            countrySaved: saq.country,
            countrySelected: saq.country,
            region: saq.region ?? [],
            regionSaved: saq.region ?? [],
            regionSelected: saq.region ?? [],
            maison: saq.maison ?? [],
            maisonSaved: saq.maison ?? [],
            maisonSelected: saq.maison ?? [],
        },
        thirdPartyNameInvolved: '',
        thirdParties: thirdParties,
        thirdPartiesSaved: thirdParties.slice(),
        digitalApplication: saq.digitalApplication ? {
            ...saq.digitalApplication,
            usersWithFunctionalPrivilegedAccess: saq.digitalApplication.usersWithFunctionalPrivilegedAccess,
            usersToInteractWithApp: saq.digitalApplication.idUsersToInteractWithApp,
            usersWithDataPrivilegedAccess: saq.digitalApplication.usersWithDataPrivilegedAccess,
            //appHost: saq.digitalApplication.idAppHost ?? '',
            dataHostedCountry: saq.digitalApplication.idDataHostedCountry ?? '',
            //dataHostedCountrySelected: saq.digitalApplication.idDataHostedCountry ?? '',
            appHostedCountry: saq.digitalApplication.idAppHostedCountry ?? '',
            //appHostedCountrySelected: saq.digitalApplication.idAppHostedCountry ?? '',
            //digitalApplicationAccessibleFrom: saq.digitalApplication.idAccessibleFrom ?? '',
            //isThereAnyInterconnections: saq.digitalApplication.isThereAnyInterconnections,
            isItDesignedDeveloperForThePurposedOfTheProject: saq.digitalApplication.isItDesignedForTheProject,
            isStaticApp: saq.digitalApplication.isStaticApp,
            doesItCollectInformation: saq.digitalApplication.doesItCollectInformation,
            isApplicationAllowingUserInputs: saq.digitalApplication.isApplicationAllowingUserInputs,
            appTypesSaved: saq.digitalApplication.appTypes ?? '',
            appTypesSelected: saq.digitalApplication.appTypes ?? '',
            scopesSaved: saq.digitalApplication.scopes ?? '',
            scopesSelected: saq.digitalApplication.scopes ?? '',
            programmingLanguagesSaved: saq.digitalApplication.programmingLanguages ?? '',
            programmingLanguagesSelected: saq.digitalApplication.programmingLanguages ?? '',
            idQuestion: saq.digitalApplication.idQuestion ?? '',
            otherProgrammingLanguage: saq.digitalApplication.programmingLanguages.filter((t: any) => t.idProgrammingLanguage === ProgrammingLanguage.other)[0]?.other,
            appTypeOther: saq.digitalApplication.appTypes.filter((t: IAppType) => t.idAppType === AppType.other)[0]?.otherType,
        } as IDigitalApplication : null,
        hosting : saq.digitalApplication ? { 
            appHost: saq.digitalApplication.idAppHost ?? '',
            appHostedCountrySelected: saq.digitalApplication.idAppHostedCountry ?? '',
            digitalApplicationAccessibleFrom: saq.digitalApplication.idAccessibleFrom ?? '',
            dataHostedCountrySelected: saq.digitalApplication.idDataHostedCountry ?? '',
            isThereAnyInterconnections: saq.digitalApplication.isThereAnyInterconnections,}  as IHosting : null,
        dataInformation: {
            ...saq.dataInformation,
            isTherePersonalDataInvolved: saq.dataInformation.isTherePersonalDataInvolved,
            sensitiveCompanyInfoSaved: saq.dataInformation.sensitiveCompanyInfo ?? '',
            sensitiveCompanyInfoSelected: saq.dataInformation.sensitiveCompanyInfo ?? '',
            dataSubjectInvolvedSaved: saq.dataInformation.dataSubjectInvolved ?? '',
            dataSubjectInvolvedSelected: saq.dataInformation.dataSubjectInvolved ?? '',
            dataProcessingSaved: saq.dataInformation.dataProcessing ?? '',
            dataProcessingSelected: saq.dataInformation.dataProcessing ?? '',
            dataUsedForSaved: saq.dataInformation.dataUsedFor ?? '',
            dataUsedForSelected: saq.dataInformation.dataUsedFor ?? '',
            idProductInvolved: saq.dataInformation.idProductInvolved ? saq.dataInformation.idProductInvolved : '',
            idPersonalDataRetainedBy: saq.dataInformation.idPersonalDataRetainedBy ? saq.dataInformation.idPersonalDataRetainedBy : '',
            idDataSubjectsCollected:  saq.dataInformation.idDataSubjectsCollected ? saq.dataInformation.idDataSubjectsCollected : '',
            otherSubjectInvolved: saq.dataInformation.dataSubjectInvolved.filter((t: DataSubjectInvolved) => t.idDataSubjectInvolved === DataSubjectsType.Other)[0]?.other,
            otherDataUsedFor: saq.dataInformation.dataUsedFor.filter((t: TypeBU) => t.idDataUsedFor === DataTypeBU.Other)[0]?.other,
            otherSensitiveInformation: saq.dataInformation.sensitiveCompanyInfo.filter((t: SensitiveCompanyInfo) => t.idSensitiveCompanyInfo === SensitiveDataIds.Other)[0]?.other,
            personalDataType: saq.dataInformation.personalDataType,
            personalDataTypeSaved: saq.dataInformation.personalDataType ?? '',
            personalDataTypeSelected: saq.dataInformation.personalDataType ?? '',
        }
    }
    return form;
}

export const mapFormToServer = function(saq: ISelfAssessmentQuestionnaire, nextAction: NextActionBehavior, id: number = 0): SubmitObject {    
    let thirdPartiesList = saq.thirdParties?.map(tp => {
        const newTp: IThirdPartyInformationItemServer  = Object.assign({}, tp);
        newTp.tasks = tp.tasksSelected
        return newTp;
    }) as IThirdPartyInformationItemServer[];
    if(saq.thirdPartiesSaved?.length && saq.thirdPartiesSaved[0].isDeleted) {
        thirdPartiesList = thirdPartiesList ? thirdPartiesList.concat(saq.thirdPartiesSaved) : saq.thirdPartiesSaved;
    }

    let appTypesList = saq.digitalApplication?.appTypesSelected?.slice() ?? [];
    if(saq.digitalApplication && saq.digitalApplication.isDeleted && saq.digitalApplication.appTypesSaved?.length && saq.digitalApplication.appTypesSaved[0].isDeleted) {
        appTypesList = appTypesList.concat(saq.digitalApplication?.appTypesSaved ?? []);
    }

    let programmingLanguagesList = saq.digitalApplication?.programmingLanguagesSelected?.slice() ?? [];
    if(saq.digitalApplication && saq.digitalApplication.isDeleted && saq.digitalApplication.programmingLanguagesSaved?.length && saq.digitalApplication.programmingLanguagesSaved[0].isDeleted) {
        programmingLanguagesList = programmingLanguagesList.concat(saq.digitalApplication?.programmingLanguagesSaved ?? []);
    }

    let scopesList = saq.digitalApplication?.scopesSelected?.slice() ?? [];
    if(saq.digitalApplication && saq.digitalApplication.isDeleted && saq.digitalApplication.scopesSaved?.length && saq.digitalApplication.scopesSaved[0].isDeleted) {
        scopesList = scopesList.concat(saq.digitalApplication?.scopesSaved ?? []);
    }

    const saqs : ISelfAssessmentQuestionnaireServer = {
        id: saq.id,
        createdBy: saq.createdBy,
        createdDate: saq.createdDate,
        projectName: saq.generalInformation.projectName,
        department: saq.generalInformation.department,
        maisonProjectOwner: (saq.generalInformation.maisonProjectOwner as Reference)?.id?.toString(),
        projectDescription: saq.generalInformation.projectDescription,
        country: saq.generalInformation.countrySelected,
        technicalPointOfContact: (saq.generalInformation.technicalPointOfContact as Reference)?.id?.toString(),
        maison: saq.generalInformation.maisonSelected,
        region: saq.generalInformation.regionSelected,
        thirdParties: saq.thirdParties?.map(tp => {
            const newTp: IThirdPartyInformationItemServer = Object.assign({}, tp);
            newTp.tasks = tp.tasksSelected
            return newTp;
        }) as IThirdPartyInformationItemServer[],
        digitalApplication: saq.digitalApplication ? {
            id: saq.digitalApplication.id,
            isDeleted: saq.digitalApplication.isDeleted,
            createdBy: saq.digitalApplication.createdBy,
            createdDate: saq.digitalApplication.createdDate,
            appName: saq.digitalApplication.appName,
            usersWithFunctionalPrivilegedAccess: saq.digitalApplication.usersWithFunctionalPrivilegedAccess,
            idUsersToInteractWithApp: typeof saq.digitalApplication.usersToInteractWithApp === 'number' ? saq.digitalApplication.usersToInteractWithApp : null,
            usersWithDataPrivilegedAccess: saq.digitalApplication.usersWithDataPrivilegedAccess,
            idAppHost: saq.digitalApplication.appHost ? typeof saq.digitalApplication.appHost === 'object' ? Number(saq.digitalApplication.appHost.id) :  Number(saq.digitalApplication.appHost) : null,
            idDataHostedCountry: saq.digitalApplication.dataHostedCountrySelected && typeof saq.digitalApplication.dataHostedCountrySelected !== 'string' && typeof saq.digitalApplication.dataHostedCountrySelected.id === 'number' ? saq.digitalApplication.dataHostedCountrySelected.id : null,
            idAppHostedCountry: saq.digitalApplication.appHostedCountrySelected && typeof saq.digitalApplication.appHostedCountrySelected !== 'string' && typeof saq.digitalApplication.appHostedCountrySelected.id === 'number' ? saq.digitalApplication.appHostedCountrySelected.id : null,
            idAccessibleFrom: typeof saq.digitalApplication.digitalApplicationAccessibleFrom === 'number' ? saq.digitalApplication.digitalApplicationAccessibleFrom : null,
            isThereAnyInterconnections: saq.digitalApplication.isThereAnyInterconnections,
            isItDesignedForTheProject: saq.digitalApplication.isItDesignedDeveloperForThePurposedOfTheProject,
            idQuestion: typeof saq.digitalApplication.idQuestion === 'number' ? saq.digitalApplication.idQuestion : null,
            doesItCollectInformation: saq.digitalApplication.doesItCollectInformation,
            isApplicationAllowingUserInputs: saq.digitalApplication.isApplicationAllowingUserInputs,
            appTypes: saq.digitalApplication.appTypesSelected?.map(
                op => Object.assign({}, {
                    ...op,
                    otherType: op.idAppType === AppType.other ? saq.digitalApplication?.appTypeOther : null
                })
            ) || [],
            scopes: scopesList,
            programmingLanguages: saq.digitalApplication.programmingLanguagesSelected?.map(
                op => Object.assign({}, {
                    ...op,
                    other: op.idProgrammingLanguage === ProgrammingLanguage.other ? saq.digitalApplication?.otherProgrammingLanguage : null
                })
            )|| []
        } : null,
        hosting : saq.digitalApplication ? { 
            appHost:  '',
            appHostedCountrySelected:  '',
            digitalApplicationAccessibleFrom:  '',
            dataHostedCountrySelected:  ''}  as IHosting : null,
        dataInformation: {
            id: saq.dataInformation.id,
            createdBy: saq.dataInformation.createdBy,
            createdDate: saq.dataInformation.createdDate,
            isTherePersonalDataInvolved: saq.dataInformation.isTherePersonalDataInvolved,
            sensitiveCompanyInfo: saq.dataInformation.sensitiveCompanyInfoSelected?.map(
                op => Object.assign({}, {
                    ...op,
                    other: op.idSensitiveCompanyInfo === SensitiveDataIds.Other ? saq.dataInformation?.otherSensitiveInformation : null
                })
            ),
            isThisProductDataNotReleased: saq.dataInformation.isThisProductDataNotReleased,
            IsThereCreditCardSwitch: saq.dataInformation.isThereCreditCardSwitch,
            idDataSubjectsCollected: typeof saq.dataInformation.idDataSubjectsCollected === 'number' ? saq.dataInformation.idDataSubjectsCollected : null,
            idPersonalDataRetainedBy: typeof saq.dataInformation.idPersonalDataRetainedBy === 'number' ? saq.dataInformation.idPersonalDataRetainedBy : null,
            idProductInvolved: typeof saq.dataInformation.idProductInvolved === 'number' ? saq.dataInformation.idProductInvolved : null,
            dataProcessing: saq.dataInformation.dataProcessingSelected ?? saq.dataInformation.dataProcessingSaved,
            isPersonalDataNecessary: saq.dataInformation.isPersonalDataNecessary,
            isTherePersonalDataUsed: saq.dataInformation.isTherePersonalDataUsed,
            isThereInternationalTransfer: saq.dataInformation.isThereInternationalTransfer,
            doesInvolvePiiTransfer: saq.dataInformation.doesInvolvePiiTransfer,
            haveClearProcess: saq.dataInformation.haveClearProcess,
            otherPersonalData: saq.dataInformation.idPersonalDataRetainedBy === PersonalDataRetained.Other ? saq.dataInformation.otherPersonalData : undefined,
            dataSubjectInvolved: saq.dataInformation.dataSubjectInvolvedSelected?.map(
                op => Object.assign({}, {
                    ...op,
                    other: op.idDataSubjectInvolved === DataSubjectsType.Other ? saq.dataInformation?.otherSubjectInvolved : null
                })
            ),
            dataUsedFor: saq.dataInformation.dataUsedForSelected?.map(
                op => Object.assign({}, {
                    ...op,
                    other: op.idDataUsedFor === DataTypeBU.Other ? saq.dataInformation?.otherDataUsedFor : null
                })
            ),
            personalDataType: saq.dataInformation.personalDataTypeSelected
        }
    }
    const submitObj: SubmitObject = {
        nextActionBehavior: nextAction,
        selfAssessmentQuestionnaire: Object.assign({}, saqs)
    }
    return submitObj;
}