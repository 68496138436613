import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import { formatBytes } from '../../../helpers/HelpersFunc';
import { FileTypesAccepted } from '../../../models/interfaces';
import { CustomFile } from '../interfaces';
import './DocumentsListing.scss';

interface Props {
    files: CustomFile[];
    fileTypesAccepted: FileTypesAccepted;
    download: (file: CustomFile) => void;
    updateAccessType: (event: any, file: CustomFile) => void;
    deleteFile: (file: CustomFile) => void;
    renderProgress: (file: CustomFile) => void;
    disabled? : boolean;
}

export const DocumentsListing = (props: Props) => {

    return (
        <React.Fragment>
            { props.files?.map(file => {
                return (
                    <div key={file.name + file.id} className="row">
                        {
                            Object.values(props.fileTypesAccepted.document).indexOf(file.name.split('.').pop()!) > -1 ?
                            <i className="fa icon fa-file-text-o fa-2x" aria-hidden="true" onClick={() => props.download(file)}></i> :
                            Object.values(props.fileTypesAccepted.image).indexOf(file.name.split('.').pop()!) > -1 ?
                            <i className="fa icon fa-file-image-o fa-2x" aria-hidden="true" onClick={() => props.download(file)}></i> :
                            <i className="fa icon fa-file-video-o fa-2x" aria-hidden="true" onClick={() => props.download(file)}></i>
                        }
                        {
                            file.encryptionDone && (
                                <i className="fa fa-lock icon Encrypting Lock" aria-hidden="true"></i>
                            )
                        }

                        <div className="upload__infos">
                            <span className="Filename">{file.name.substring(0, file.name.lastIndexOf('.'))}</span>
                            <span className="UpperCase">{file.name.split('.').pop()}</span>
                            <span className="Filesize">{formatBytes(file.size)}</span>
                        </div>

                        <div className="upload__select">
                            {
                                !file.encrypting && !file.uploading ? (
                                    <>
                                        {
                                            (file.canBeDeleted === undefined || file.canBeDeleted) &&
                                                <button className="btn" type="button" onClick={() => props.deleteFile(file)}><ClearIcon /></button>
                                        }  
                                    </>
                                ) : file.uploading ? (
                                    <>
                                        {props.renderProgress(file)}
                                    </>
                                ) : (
                                    <div className="Encrypting">
                                        <i className="fa fa-circle" aria-hidden="true"></i>
                                        <label>Encrypting file </label>
                                        <img style={{height: "15px"}} src="/assets/oxygen-loader.gif" alt='encryting'/>
                                    </div>
                                )
                            }
                        </div>

                    </div>
                );
            })}
        </React.Fragment>
    )
}