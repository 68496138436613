import { AxiosRequestConfig } from "axios";
import "react-notifications/lib/notifications.css";
import { map } from "rxjs/operators";
import { CustomFile, DocumentRights } from "../shared/components/upload/interfaces";
import { urlPartialDelete, urlPartialGetDocuments, urlPartialGetUploadedFilesList, urlPartialUrlGetDocumentRightsByRole } from "../shared/config/GlobalAppConfig";
import { ApiCallingMethods } from "../shared/helpers/AxiosHelper";
import DataConnectionFactory from "../shared/helpers/DataConnectionFactory";
import { DocAccessType } from "../shared/models/enums";
import { ApiResponse, CommonProperties } from "../shared/models/interfaces";
import CustomAxios from "../shared/helpers/AxiosHelper";
import { Observable } from "rxjs";

interface QuestionnaireDocuments extends CommonProperties {
  idQuestionnaire: number;
  fullpath: string ;
  filesize: number ;
  filename: string ;
  filenameConverted: string;
  extension: string ;
  filetype: string ;
  docType: string ;
}

interface QuestionnaireDocumentsReceived extends QuestionnaireDocuments {
  canBeDeleted: boolean;
}

export class FileService {
  static getDocuments(questionnaireId: string | undefined) {
    const options: AxiosRequestConfig = {
      method: ApiCallingMethods.get,
      url: `${urlPartialGetDocuments}/${questionnaireId}`,
      data: null,
    };

    const res = DataConnectionFactory.request(options).pipe(
      map((response: ApiResponse<any>) => {
        return response.data.model;
      })
    );
    return res;
  }

  static update(url: string, file: CustomFile) {
    const pos = Number(file.docAccessType);
    const options : AxiosRequestConfig = {
        method: ApiCallingMethods.put,
        url: url + "/" + file.id + "?docType=" + DocAccessType[pos],
        data: null
    };
    return CustomAxios.request(options);
  }

  static getAllDocuments(idGroupUsers : number, idQuestionnaire: number): Observable<CustomFile[]> {
    let url = `${urlPartialGetUploadedFilesList}?idGroupUsers=${idGroupUsers}&idQuestionnaire=${idQuestionnaire}`;

    const options : AxiosRequestConfig = {
        method: ApiCallingMethods.get,
        url: url,
        data: null
    };
    return CustomAxios.request(options).pipe(map(resp => {
        const fileListFromServer: QuestionnaireDocumentsReceived[] = resp.data;
        const filelist: CustomFile[] = fileListFromServer.map((fileFromServer: QuestionnaireDocumentsReceived) => {
            const myfile: CustomFile = {
              docAccessType: DocAccessType[fileFromServer.docType],
              encrypting: false,
              encryptionDone: true,
              uploading: false,
              name: `${fileFromServer.filename}.${fileFromServer.extension}`,
              size: fileFromServer.filesize,
              id: fileFromServer.id,
              filenameInBe: fileFromServer.filenameConverted,
              createdBy: fileFromServer.createdBy,
              modifiedBy: fileFromServer.modifiedBy,
              createdDate: fileFromServer.createdDate,
              modifiedDate: fileFromServer.modifiedDate,
              idQuestionnaire: fileFromServer.idQuestionnaire, 
              canBeDeleted: fileFromServer.canBeDeleted,
              // Init manadory values
              arrayBuffer: () => Promise.resolve(new ArrayBuffer(8)),
              text: () => Promise.resolve(''),
              stream: () => new ReadableStream({}),
              slice: () => new Blob(),
              type: '',
              lastModified: 0
            }
            return myfile;
        })
        return filelist;
    }));
  }

  static getDocumentRightsByRole(idGroupUser : number): Observable<DocumentRights[]>{
    const options : AxiosRequestConfig = {
        method: ApiCallingMethods.get,
        url: urlPartialUrlGetDocumentRightsByRole + "/" + idGroupUser,
        data: null
    };
    return CustomAxios.request(options).pipe(map(res => res.data.model));
  }

  static delete(id: number) {
    const options : AxiosRequestConfig = {
        method: ApiCallingMethods.delete,
        url: urlPartialDelete + "/" + id,
        data: null
    };
    return CustomAxios.request(options);
  }

  static download(url: string, filename: string) {
    const options : AxiosRequestConfig = {
        method: ApiCallingMethods.post,
        url: url,
        data: {filename: filename},
        responseType: 'blob'
    };
    return CustomAxios.request(options);
}

}
