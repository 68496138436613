import { Button } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { UiAutocompleteField, UiSwitchField } from '../../../shared/components/fields';
import { IPropsUiModalBase, UiModal } from '../../../shared/components/ui/UiModal/UiModal';
import { Reference } from '../../../shared/models/interfaces';

export interface IModalFilter {
    onlyNewStatus: boolean,
    thirdPartyList: Reference[]
    usernamesList: Reference[]
    statusList: Reference[]
}

const filterInitialValue: IModalFilter = {
    onlyNewStatus: false,
    thirdPartyList: [],
    usernamesList: [],
    statusList: [],
}

type IPropsUiModalBrocker = IPropsUiModalBase & {
    onApply(filters: IModalFilter | undefined): void
    modalFilter: IModalFilter | undefined
    countriesRef: Reference[]
    projectStatusListRef: Reference[]
    thirdPartyList: Reference[]
    usersInvolvedList: Reference[]
    isListViewFilter: boolean
}

export class ModalFilters extends React.Component<IPropsUiModalBrocker, any> {

    handleCancel = () => {
        if (this.props.onClose) this.props.onClose({}, 'cancelBtnClick')
    }

    handleClearFilters = (values: any) => {
        this.props.onApply(undefined);
        if (this.props.onClose) this.props.onClose({}, 'clearFilter')
    }

    Submit = (values: any, _actions: any) => {
        this.props.onApply(values);
        if (this.props.onClose) this.props.onClose({}, 'applyFilter');
    }

    render() {
        return (<UiModal
            id="filters-modal"
            title={this.props.isListViewFilter ? "Filters" : "Export Filters"}
            open={this.props.open}
            onClose={this.props.onClose}
        >
            <div className="row">
                <Formik
                    initialValues={filterInitialValue}
                    onSubmit={this.Submit}
                    onReset={this.handleClearFilters}
                >
                    {({
                        handleSubmit,
                        handleReset,
                        initialValues,
                        setFieldValue,
                        values
                    }: FormikProps<any>) => {
                        useEffect(() => {
                            if (this.props.modalFilter) {
                                Object.keys(initialValues).forEach(key => {
                                    setFieldValue(key, (this.props.modalFilter as IModalFilter)[key], false)
                                })
                            }

                        }, [initialValues, setFieldValue])
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="column--flex">
                                    <Field component={UiSwitchField} name="onlyNewStatus" label="Only New Status" />
                                    {!values.onlyNewStatus && <Field component={UiAutocompleteField} options={this.props.projectStatusListRef ? this.props.projectStatusListRef : []} name="statusList" label="Status" multiple={true} />}
                                </div>
                                <div className="row">
                                    <Field component={UiAutocompleteField} options={this.props.thirdPartyList ? this.props.thirdPartyList : []} name="thirdPartyList" label="Third Parties" multiple={true} />
                                    <Field component={UiAutocompleteField} options={this.props.usersInvolvedList ? this.props.usersInvolvedList : []} name="usernamesList" label="Usernames" multiple={true} />
                                </div>
                                <div className="ui-modal__footer ui-modal__footer--buttons">
                                    {this.props.isListViewFilter && <Button onClick={handleReset} variant="outlined" color="primary" key="cancel">Clear Filter</Button>}
                                    <Button onClick={() => { handleSubmit() }} variant="contained" color="primary" key="apply">{this.props.isListViewFilter ? "Apply Filter" : "Export"}</Button>
                                </div>
                            </form>
                        )
                    }}
                </Formik>
            </div>
        </UiModal>
        )
    }
}