import LocalStorageTokenService from '../../shared/helpers/LocalStorageTokenService';
import {msalConfig, AuthAppResponse} from './authentificationConfig'
import {
   Configuration,
   PublicClientApplication
} from "@azure/msal-browser";

class AuthenticationCustom {

   private MSAL: PublicClientApplication;
   private signInResponse : AuthAppResponse;

   constructor(configuration: Configuration) {
      this.MSAL = new PublicClientApplication(configuration);
      this.signInResponse = { isError : false, errorMsg : null, response : null, token: null };
   }

   logOut() {
      this.MSAL.logoutRedirect();
      LocalStorageTokenService.clearToken();
   }

}

const AuthenticationServiceRedirect = new AuthenticationCustom(msalConfig)

export default AuthenticationServiceRedirect;