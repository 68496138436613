import React from 'react';
import { TableCell } from "@material-ui/core";

export const UiTableCell = (props:any) => {
    const cell = props.head && props.head.cell ? props.head.cell : false
    const value = props.head && props.head.accessor ? props[props.head.accessor] : false
    return (
    <TableCell className={'ui-tablecell--'+props.head?.name}>
        {cell && cell(props)}
        {!cell && value }
    </TableCell>
    )
}
export default UiTableCell;