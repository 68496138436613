import { Chip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box } from '@mui/system';
import React from 'react';
import { UiBaseField } from './UiBaseField';
import { getFieldData } from './UiFieldHelpers';
import UiFieldWrapper from './UiFieldWrapper';
export type IUiMultiselectOption = {
    value: any,
    label: string,
    id?: any
}

export type IPropsUiAutocompleteField = UiBaseField & {
    options: Array<IUiMultiselectOption>,
    multiple?: any,
    placeholder?: any,
    inputValue?: string,
    showSecondLabel?: boolean,
    getOptionLabel?: (option: any) => string,
    getOptionSelected? : ((option: any, value: any) => boolean) | undefined,
    onCustomSelect?: (value: Array<IUiMultiselectOption> | IUiMultiselectOption, projectId: number, name?: string) => {},
    onInputChange?: (value: string, event?: any) => void
}
export const UiAutocompleteField = (props: IPropsUiAutocompleteField) => {
    const { error, name, value } = getFieldData(props);
    const handleChange = (event: any, option: Array<IUiMultiselectOption> | IUiMultiselectOption) => {
        if (props.form) {
            props.form.setFieldValue(name, option)
        }
        if (props.onChange) {
            props.onChange(event, option)
        }
        if (props.onCustomSelect && props.form) {
            props.onCustomSelect(option, props.form.values.id, name);
        }
    }

    const getValue = (valuesList: any) => {
        if (props.multiple) {
            return props.options.filter(option => {
                return valuesList?.filter((valueSelected: any) => (valueSelected?.id && valueSelected.id === option.id) || (valueSelected?.value && valueSelected.value === option.value))[0];
            })
        }
        return valuesList;
    }

    const getOptionLabel = (props.getOptionLabel) ? props.getOptionLabel : (option: any): string => option.itemContent ?? ''

    const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        if (event && props.onInputChange) {
            props.onInputChange(value, event);
        }
    }

    return (
        <UiFieldWrapper name="ui-autocomplete" {...props}>
            <SearchIcon className="ui-autocomplete__icon" />
            <Autocomplete
                fullWidth
                data-testid={props.id}
                disabled={props.disabled}
                multiple={props.multiple}
                className="ui-search-multiselect"
                value={getValue(value)}
                options={props.options}
                getOptionLabel={(option) => getOptionLabel(option)}
                getOptionSelected={props.getOptionSelected ? props.getOptionSelected : undefined}
                onInputChange={handleInputChange}
                inputValue={props.inputValue}
                onChange={handleChange}
                renderOption={refProps => (
                    <Box component="div" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                        {props.showSecondLabel
                            ? <>
                                <label style={{ fontWeight: 700, display: "block" }}>{refProps.itemContent}</label>
                                {refProps.itemContent2}
                            </>
                            : refProps.itemContent}
                    </Box>
                )}
                renderTags={(value: string[], getTagProps) =>
                    value.map((option: any, index: number) => (
                        <Chip label={getOptionLabel(option)} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        error={error.hasError}
                        // if errorMessage is an object Error will be raised.
                        // this can occur when yup has object.shape in object.shape with several field.
                        // In this case errorMessage is an object with several error messages inside.
                        helperText={error.hasError ? typeof (error.errorMessage) === 'string' ? error.errorMessage : null : null}
                        variant="outlined"
                        placeholder={props.placeholder}

                    />
                )}
            />
        </UiFieldWrapper>);
}
export default UiAutocompleteField