import { IconButton } from "@material-ui/core";
import { Delete, Edit } from '@material-ui/icons';
import moment from "moment";
import React, { useEffect } from "react";
import { Subscription } from "rxjs";
import { ThirdPartyService } from "../../../services/thirdPartyService";
import { ActionType } from "../../../shared/components/ui/UiTable/types";
import { notificationMessage, UserGroup } from "../../../shared/config/GlobalAppConfig";
import { baseCreateNotification } from "../../../shared/helpers/HelpersFunc";
import LocalStorageTokenService from "../../../shared/helpers/LocalStorageTokenService";
import { NotificationType } from "../../../shared/models/enums";
import { IThirdPartyDashboard } from "../ThirdPartiesDashboard";

interface IDashboardItemExtended extends IThirdPartyDashboard {
    onChange(event: any, actionType?: ActionType, id?: number): void,
    onClickCustom?: (event: any) => void,
}

export const dateString = (props: IThirdPartyDashboard) => {
    return props.createdDate ? moment(props.createdDate).format('yyyy-MM-DD') : "-";
}

export const scoreDateString = (props: IThirdPartyDashboard) => {
    return props.scoreModifiedDate ? moment(props.scoreModifiedDate).format('yyyy-MM-DD') : "-";
}

export const standardFormat = (props: IThirdPartyDashboard, propName: string, notFoundValue: string) => {
    return props[propName] ? props[propName] : notFoundValue;
}

export const Actions = (props: IDashboardItemExtended) => {
    let delSubscription: Subscription | null = null;
    const localStorageTokenService = LocalStorageTokenService;

    const handleEdition = function() {
        if(props.id) {
            if(props.onChange) props.onChange(null, 'edit', props.id ?? -1);
        }
    }

    const handleDelete = function() {
        if(props.id) {
            delSubscription = ThirdPartyService.delete(props.id).subscribe(() => {
                if(props.onChange) props.onChange(null, 'delete', props.id ?? -1);
                baseCreateNotification(NotificationType.success, notificationMessage.ThirdPartyForm_removed.title, notificationMessage.ThirdPartyForm_removed.message);
            })
        }
    }

    useEffect(() => {
        delSubscription?.unsubscribe();
    },[]);

    return (
        <>
            {
                localStorageTokenService.getUserRoleToken() === UserGroup.SecurityTeam &&
                <>
                <IconButton color="primary" onClick={handleEdition}><Edit /></IconButton>
                <IconButton color="primary" onClick={handleDelete}><Delete /></IconButton>
                </>
            }
      </>
    );
}