import React from 'react';
import Container from '@material-ui/core/Container';
import LoadingContext from '../contexts/LoadingContext';

function ErrorPage() {
  const ctx = React.useContext(LoadingContext);

  React.useEffect(() => {
    ctx.stopLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth="xl" className="content home">
      <h2>Error</h2>
      <br />
      <p>Generic site error</p>
    </Container>
  )
}

export default ErrorPage;