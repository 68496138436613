import { Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Mail from '@material-ui/icons/Mail';
import CircularProgress from '@mui/material/CircularProgress';
import { Field, Formik, FormikHelpers } from 'formik';
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Subscription } from "rxjs";
import * as yup from "yup";
import LoadingContext from "../../../contexts/LoadingContext";
import { useNotificationContext, verifyIfThereIsAnyNotificationToChangeStatus } from "../../../hooks/notificationHook";
import { useProjectContext } from "../../../hooks/projectHook";
import { DashboardService, StatusItem } from "../../../services/dashboardService";
import { EmailDataFromDashboardDto, EmailService } from "../../../services/emailService";
import { ProjectService } from "../../../services/projectService";
import { UiTextareaField, UiTextField } from '../../../shared/components/fields';
import { ListMenu } from "../../../shared/components/ListMenu";
import { UiModal } from "../../../shared/components/ui/UiModal/UiModal";
import UiStatus from "../../../shared/components/ui/UiStatus";
import { ActionType } from "../../../shared/components/ui/UiTable/types";
import { notificationMessage, UserGroup } from "../../../shared/config/GlobalAppConfig";
import { baseCreateNotification } from "../../../shared/helpers/HelpersFunc";
import LocalStorageTokenService from '../../../shared/helpers/LocalStorageTokenService';
import { Action, NotificationType, StatusType } from "../../../shared/models/enums";
import { NextActionBehavior, Reference, User } from "../../../shared/models/interfaces";
import { IDashboardItem } from "../Dashboard";
import { ReferenceService } from "../../../services/referenceService";
import { Status as EnumStatus }  from "../../../shared/models/enums";

interface IDashboardItemExtended extends IDashboardItem {
    showCheckbox?: boolean,
    onChange?: (value: any, action?: ActionType, id?: number) => void
    onClickCustom?: (event: any) => void,
    usersList?: User[],
    questionnaireId: string,
    chosenSecurityAuditList?: string[]
}

interface StateProps {
    secAuditList: string[]
}


interface IStatus {
    idStatus: number,
    name: string
}

interface ISendEmail {
    subject: string,
    message: string
}

const sendEmailInitialValues: ISendEmail =
{
    subject: "",
    message: ""
};

const sendEmailValidationSchema = yup.object().shape({
    message: yup.string().required("This is a required field"),
    subject: yup.string().required("This is a required field"),
});

export const ProjectName = (props: IDashboardItemExtended) => {
     if (props.showCheckbox) {
         return <><Checkbox name={`checkbox-uitable-${props.id}`} onChange={ev => props.onChange ? props.onChange(ev, 'checkbox') : null} /> {props.projectName}</>
     }
    return props.projectName;
}

export const ShadowIT = (props: IDashboardItemExtended) => {
    return <><Checkbox disabled={true} checked={props.isShadowIT}/></>
}

export const Status = (props: IDashboardItemExtended) => {
    const [status, setStatus] = useState<string>();
    const [comment, setComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showStatusList, setShowStatusList] = useState(false);
    const [statusList, setStatusList] = useState<StatusItem[]>([]);
    const [statusSubscription, setStatusSubscription] = useState<Subscription>();
    const [openMessageModal, setOpenMessageModal] = useState<boolean>(false);
    const [newStatus, setNewStatus] = useState<IStatus>();
    const [changeStatusEvent, setChangeStatusEvent] = useState<React.MouseEvent<any>>();
    const notificationService = useNotificationContext();
    const projectService = useProjectContext();
    const [securityValues, setSecurityValues] = useState<string[]>([]);
    const [nextAction, setNextAction] = useState<NextActionBehavior>({ selfAssessmentQuestionnaireId: parseInt(props.questionnaireId) });
    const location = useLocation<StateProps>();
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [securityAuditList, setSecurityAuditList] = useState<Reference[]>();


    const handleCheckboxGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = securityValues.indexOf(event.target.value);
        if (event.target.checked && index < 0) {
            securityValues.push(event.target.value)
        }
        if (!event.target.checked && index > -1) {
            securityValues.splice(index, 1);
        }
        setSecurityValues(securityValues);
    }

    useEffect(() => {
        setStatus(props.statusName);
        if (props.id && props.statusName !== 'Draft' && LocalStorageTokenService.getUserRoleToken() === UserGroup.SecurityTeam) {
            const hasStartedVerification = verifyIfThereIsAnyNotificationToChangeStatus(notificationService, projectService, props.id, setStatus, setStatusList, setIsLoading);
            let changeStat: Subscription | null = null;

            if (!hasStartedVerification) {
                changeStat = DashboardService.getStatusList(props.id).subscribe(list => {
                    setStatusList(list);
                    setIsLoading(false);
                });
            }

            return () => {
                statusSubscription?.unsubscribe();
                changeStat?.unsubscribe();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.statusName]);

    const handleOnClick = function (event: React.MouseEvent<any>, status: IStatus) {
        setOpenMessageModal(true);
        setNewStatus(status);
        setChangeStatusEvent(event);
        if (!securityAuditList){
            ReferenceService.GetSecurityAuditList().subscribe(
            (securityAuditRef) => {
                setSecurityAuditList(securityAuditRef);
            }
            , () => {
            });
        }
            
    }

    const cancel = function () {
        setOpenMessageModal(false);
    }

    const history = useHistory();

    const isSecurityAuditFormValid = (): boolean => {
        return securityValues.length > 0;
    }

    const send = function () {

        let canSend = isInvalid;
        if (newStatus?.idStatus === EnumStatus.ongoing_security_audit && !isSecurityAuditFormValid()) {
            setIsInvalid(true);
            return;
        } else {
            canSend = false;
            setIsInvalid(false);
        }
        if (!canSend)
        {
            setIsLoading(true);
            setShowStatusList(!showStatusList);
            setOpenMessageModal(false);

            if (props.onClickCustom) props.onClickCustom(changeStatusEvent);
            if (props.id && newStatus) {
                projectService.handlePersistentChangeStatusReq({
                    comment,
                    idProject: props.id,
                    idStatus: newStatus.idStatus,
                    status: newStatus.name
                })
            
                const notificationSubject = notificationService.init(newStatus.name);
                notificationSubject.subscribe(succeeded => {
                    if (succeeded && props.id) {
                        const nextAction: NextActionBehavior = {
                            idFlowStatus: newStatus.idStatus,
                            selfAssessmentQuestionnaireId: props.id,
                            comment,
                            securityAudit: securityValues.toString()
                        }
                        const tempStatusSubscription = DashboardService.changeStatus(nextAction).subscribe((list) => {
                            setStatus(newStatus.name);
                            setStatusList(list);
                            setIsLoading(false);
                            props.fetch()
                        });
                        setStatusSubscription(tempStatusSubscription);
                    }
                    else {
                        setIsLoading(false);
                    }
                })
            }
        }
    }
    return <>
        {
            !status
                ? <></>
                : <UiStatus label={status} name={status.toLowerCase().replace(/ /g, '').replace('.', '') as StatusType} />
        }
        {
            LocalStorageTokenService.getUserRoleToken() === UserGroup.SecurityTeam &&
            <>
                {
                    (!isLoading)
                        ? statusList?.length > 0 && <ListMenu list={statusList} onClick={handleOnClick} prop={'name'} />
                        : <CircularProgress color="primary" size={20} style={{ marginLeft: 15, position: "absolute" }} />
                }
            </>
        }
        <UiModal title="Message" open={openMessageModal} onClose={cancel}>
            <div>
                <div className="row">
                    { newStatus?.idStatus === EnumStatus.ongoing_security_audit &&
                            <Fragment>
                                <FormGroup className="ui-field" style={{ alignItems: 'start' }} onChange={handleCheckboxGroupChange}>
                                    {securityAuditList?.map(sa => (
                                        <FormControlLabel
                                            key={`modal-securityaudit-${sa.id}`}
                                            control={<Checkbox name={sa.itemContent.replace(/\s+/g, '')} />}
                                            label={sa.itemContent}
                                            value={sa.itemContent}
                                        />
                                    ))}
                                </FormGroup>
                                {isInvalid && newStatus?.idStatus === EnumStatus.ongoing_security_audit && <FormHelperText className="modal-error" error={isInvalid}>Please choose at least one</FormHelperText>}
                            </Fragment>
                        }                    
                    <UiTextareaField
                        value={comment}
                        label="Comment"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setComment(event.target.value)}
                    />
                </div>
                <div className="ui-modal__footer ui-modal__footer--buttons">
                    <Button onClick={cancel} variant="outlined" color="primary" key="cancel">Cancel</Button>
                    <Button onClick={send} variant="contained" color="primary" key="apply">Confirm</Button>
                </div>
            </div>
        </UiModal>
    </>
}

export const AssignTo = (props: IDashboardItemExtended) => {
    const [assignedUser, setAssignedUser] = useState('Not assigned');
    const [isLoading, setIsLoading] = useState(false);
    const [showAssignList, setShowAssignList] = useState(false);
    const [assignSubscription, setAssignSubscription] = useState<Subscription>();
    const [nextAction, setNextAction] = useState<NextActionBehavior>({
        selfAssessmentQuestionnaireId: parseInt(props.id!.toString()),
        idFlowActions: Action.Assign_to,
        //TODO create an enum for this status
        idFlowStatus: 6,
        idNextTask: 8
    });
    const [openMessageModal, setOpenMessageModal] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const history = useHistory();
    const ctx = useContext(LoadingContext);

    useEffect(() => {
        const fullname = props.usersList?.filter(user => user.email === props.assignedTo)[0]?.fullName;
        if (fullname && props.assignedTo != null) setAssignedUser(fullname)
        return () => {
            assignSubscription?.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fullname = props.usersList?.filter(user => user.email === props.assignedTo)[0]?.fullName;

        if (fullname  && props.assignedTo != null) setAssignedUser(fullname);
        else setAssignedUser('Not assigned')
    }, [props.assignedTo, props.usersList])

    const cancel = () => {
        setOpenMessageModal(false);
        setIsInvalid(false);
    }

    const handleOnClick = function (event: React.MouseEvent<any>, user: User) {
        setIsLoading(true);
        setShowAssignList(!showAssignList);
        if (props.onClickCustom) props.onClickCustom(event);
        if (props.id) {
            setAssignedUser(user.fullName);
            setNextAction({ ...nextAction, email: user.email });
            setOpenMessageModal(true);
        }
    }

    const send = () => {
        setIsLoading(true);
        setOpenMessageModal(false);
        ProjectService.performAction(nextAction).subscribe((data) => {
            if(props.onChange) props.onChange(nextAction, 'assign', nextAction.selfAssessmentQuestionnaireId);
            setIsLoading(false);
            baseCreateNotification(data ? NotificationType.success : NotificationType.error,
                data ? notificationMessage.Assign_to.title : notificationMessage.ErrorGenericMessage.title,
                data ? notificationMessage.Assign_to.message : notificationMessage.ErrorGenericMessage.message);
        }, () => {
            setAssignedUser('Not assigned');
            setIsLoading(false);
        });
    }

    return(
        <>
            <span style={{ fontStyle: assignedUser === "Not assigned"   ? "italic" : "",  fontWeight: assignedUser === "Not assigned"   ? 200 : 400 }}>
            {assignedUser}
            </span>
            {
                LocalStorageTokenService.getUserRoleToken() === UserGroup.SecurityTeam &&
                <>
                    {
                        (!isLoading)
                            ? props.usersList && <ListMenu list={props.usersList} onClick={handleOnClick} prop={'fullName'} />
                            : <CircularProgress color="primary" size={20} style={{ marginLeft: 15, position: "absolute" }} />
                    }
                </>
            }
            <UiModal title="Please add a comment" open={openMessageModal} onClose={cancel}>
                <div>
                    <div className="row">
                        <UiTextareaField
                            name="nextAction.comment"
                            label="Comment"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNextAction({ ...nextAction, comment: e.target.value })}
                            value={nextAction.comment}
                            rows={4}
                        />
                    </div>
                    <div className="ui-modal__footer ui-modal__footer--buttons">
                        <Button onClick={cancel} variant="outlined" color="primary" key="cancel">Cancel</Button>
                        <Button onClick={send} variant="contained" color="primary" key="apply">Confirm</Button>
                    </div>
                </div>
            </UiModal>
        </>
    );
}

export const dateString = (props: IDashboardItem) => {
    return props.submissionDate ? moment(props.submissionDate).format('yyyy-MM-DD') : "-";
}

export const modifedDateString = (props: IDashboardItem) => {
    return props.modifiedDate ? moment(props.modifiedDate).format('yyyy-MM-DD') : "-";
}

export const standardFormat = (props: IDashboardItem, propName: string, notFoundValue: string) => {
    return props[propName] ? props[propName] : notFoundValue;
}

export const conditionFormat = (prop: any, yes: string, no: string) => {
    return prop ? yes : no;
}

/* export const Actions = (props: IDashboardItemExtended) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const modalHandleClose = () => setModalOpen(false);
    const modalHandleOpen = () => setModalOpen(true);
    const [adUsersInput, setAdUsersInput] = useState<string>('');
    const [adUsersList, setAdUsersList] = useState([])
    const [adUsersSubscription, setAdUsersSubscription] = useState<Subscription>()
    const [sendingInvitation, setSendingInvitation] = React.useState(false);
    const shareProjectInitialValues: IShareProject =
    {
        userEmail: "",
        idQuestionnaire: 0
    };

    const submit = (values: any, formProps: FormikHelpers<IShareProject>) => {
        setSendingInvitation(true);
        if(!values.userEmail.id) {
            baseCreateNotification(NotificationType.error, notificationMessage.User_not_found.title, notificationMessage.User_not_found.message);
            setSendingInvitation(false);
            return;
        }
        values.userEmail = values.userEmail.id;
        values.idQuestionnaire = Number(props.id);
        FormService.inviteUser(values).subscribe(data => {
            setSendingInvitation(false);
            formProps.resetForm();
            modalHandleClose();
        });
    }

    const findUsers = function (userSearch: string) {
        setAdUsersInput(userSearch);
        if (userSearch.length > 2) {
            const tempSubscription = ReferenceService.GetListAdUsers(userSearch).subscribe(adUsersFilteredList => {
                setAdUsersList(adUsersFilteredList);
            });
            setAdUsersSubscription(tempSubscription);
        }
    }

    useEffect(() => {
        adUsersSubscription?.unsubscribe();
    }, []);

    return (
        <>
            {
                //Only project owner can share the project
                LocalStorageTokenService.getUserNameToken() == props.createdBy &&
                    <IconButton color="primary" onClick={modalHandleOpen} ><Share className="share-icon" /></IconButton>
            }

            <UiModal title={`Invite someone`} open={modalOpen} onClose={modalHandleClose}>
                <Formik
                    initialValues={shareProjectInitialValues}
                    validationSchema={shareProjectValidationSchema}
                    onSubmit={submit}
                >
                    {formProps => (
                            <form onSubmit={formProps.handleSubmit}>
                                <div className="row">
                                    <Field
                                        name="userEmail"
                                        component={UiAutocompleteField}
                                        onInputChange={findUsers}
                                        inputValue={adUsersInput}
                                        options={adUsersList}
                                    />
                                </div>
                                <div className="ui-modal__footer ui-modal__footer--buttons">
                                    <Button onClick={modalHandleClose} variant="outlined" color="primary" key="cancel">Cancel</Button>
                                    <Button type="submit" variant="contained" color="primary" startIcon={sendingInvitation ? <CircularProgress /> : <Mail />} disabled={sendingInvitation}>
                                        Send
                                    </Button>
                                </div>
                            </form>
                    )}
                </Formik>
            </UiModal>
        </>
    );
} */

/* export const actions = () => {
    return <IconButton href="/selfAssessment" color="primary"><CalendarTodayIcon /></IconButton>;
} */

export const ExpandableArrow = (props: IDashboardItem) => {
    return <IconButton aria-label="expand row" size="small">
        {props.expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
}

export const ContactName = (props: IDashboardItem) => {

    const [open, setOpen] = React.useState(false);
    const [sendingMail, setSendingMail] = React.useState(false);
    const modalHandleOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(true);
    }
    const modalHandleClose = () => setOpen(false);

    const resetFormAndCloseModal = (form: FormikHelpers<ISendEmail>) => {
        form.resetForm();
        modalHandleClose();
        setSendingMail(false);
    }

    const submit = function (values: ISendEmail, formProps: FormikHelpers<ISendEmail>) {
        setSendingMail(true);

        const emailDto: EmailDataFromDashboardDto = {
            email: props?.contactEmail,
            emailContent: values.message,
            subject: values.subject,
            questionnaireId: props.id
        };

        EmailService.SendEmailFromDashboard(emailDto).subscribe((data) => {
            baseCreateNotification(!data ? NotificationType.success : NotificationType.error, !data ? "Email Sent" : "Error while sending the email");
            resetFormAndCloseModal(formProps);
        }, (_error) => {
            baseCreateNotification(NotificationType.error, "Error while sending the email");
            resetFormAndCloseModal(formProps);
        });
    }

    return props.contactName ?
        <>
            {props.contactName} <IconButton onClick={modalHandleOpen}> <Mail color="primary" /> </IconButton>
            <Formik
                initialValues={sendEmailInitialValues}
                validationSchema={sendEmailValidationSchema}
                onSubmit={submit}
            >
                {formProps => (
                    <UiModal title={`Send an email to ${props?.contactName}`} open={open} onClose={modalHandleClose}>
                        <form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <Field name="subject" label="Subject" component={UiTextField} value={formProps.values.subject} />
                            </div>
                            <div className="row">
                                <Field name="message" label="Message" component={UiTextareaField} value={formProps.values.message} rows={7} />
                            </div>
                            <div className="ui-modal__footer ui-modal__footer--buttons">
                                <Button onClick={modalHandleClose} variant="outlined" color="primary" key="cancel">Cancel</Button>
                                <Button type="submit" variant="contained" color="primary" startIcon={sendingMail ? <CircularProgress size={20} /> : <Mail />} disabled={sendingMail}>
                                    Send
                                </Button>
                            </div>
                        </form>
                    </UiModal>
                )}
            </Formik>
        </> : "-"
}

export const EmailAssignedSecurityUser = (props: IDashboardItemExtended) => {

    const [open, setOpen] = React.useState(false);
    const [sendingMail, setSendingMail] = React.useState(false);
    const modalHandleOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(true);
    }
    const modalHandleClose = () => setOpen(false);

    const assignedToEmail: string = props?.assignedTo ? props.assignedTo : '';
    const assignedToName = props.usersList?.filter(user => user.email === assignedToEmail)[0]?.fullName;

    const resetFormAndCloseModal = (form: FormikHelpers<ISendEmail>) => {
        form.resetForm();
        modalHandleClose();
        setSendingMail(false);
    }

    const submit = function (values: ISendEmail, formProps: FormikHelpers<ISendEmail>) {
        setSendingMail(true);

        const emailDto: EmailDataFromDashboardDto = {
            email: assignedToEmail,
            emailContent: values.message,
            subject: values.subject,
            questionnaireId: props.id
        };

        EmailService.SendEmailFromDashboard(emailDto).subscribe((data) => {
            baseCreateNotification(!data ? NotificationType.success : NotificationType.error, !data ? "Email Sent" : "Error while sending the email");
            resetFormAndCloseModal(formProps);
        }, (_error) => {
            baseCreateNotification(NotificationType.error, "Error while sending the email");
            resetFormAndCloseModal(formProps);
        });
    }

    return props.assignedTo ?
        <>
            {assignedToName} <IconButton onClick={modalHandleOpen}> <Mail color="primary" /> </IconButton>
            <Formik
                initialValues={sendEmailInitialValues}
                validationSchema={sendEmailValidationSchema}
                onSubmit={submit}
            >
                {formProps => (
                    <UiModal title={`Send an email to ${assignedToName}`} open={open} onClose={modalHandleClose}>
                        <form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <Field name="subject" label="Subject" component={UiTextField} value={formProps.values.subject} />
                            </div>
                            <div className="row">
                                <Field name="message" label="Message" component={UiTextareaField} value={formProps.values.message} rows={7} />
                            </div>
                            <div className="ui-modal__footer ui-modal__footer--buttons">
                                <Button onClick={modalHandleClose} variant="outlined" color="primary" key="cancel">Cancel</Button>
                                <Button type="submit" variant="contained" color="primary" startIcon={sendingMail ? <CircularProgress size={20} /> : <Mail />} disabled={sendingMail}>
                                    Send
                                </Button>
                            </div>
                        </form>
                    </UiModal>
                )}
            </Formik>
        </> : "-"
}