import React, { ReactNode } from "react";
import { createMuiTheme, fade } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider} from '@material-ui/pickers';
// Theme customization
// @see https://material-ui.com/customization/theming/

function remCalc(size:number) : string{
  return (size / 13 ) + 'rem';
}
const colors = {
  primary:{
    main: '#004165'
  },
  secondary: {
    main: '#31A6C5',
  },
  action: {
    main: '#A17700',
  },
}
export const uiTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: colors.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: colors.secondary.main,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
        padding: "10px 20px"
      },
      outlined:{
        padding: "10px 20px"
      }
    },
    MuiExpansionPanelSummary:{
      root:{
        background:"#F9F9FB",
        padding: "0px 32px"
      }
    },
    MuiFormControl: {
      root: {
        width:'100%',
      },
    },
    MuiFormLabel : {
      root:{
        fontSize: remCalc(14),
        lineHeight: 1.33
      }
    },
    MuiOutlinedInput: {
      notchedOutline:{
        fontSize: remCalc(14)
      },
      input: {
        padding: '14.5px 16px',
      },
      inputAdornedStart:{
        paddingLeft: '5px',
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 4px) scale(1)',
        paddingRight: 50,
        display: 'flex',
        alignItems: 'center',
        height: 35,

        '&$shrink': {
          transform: 'translate(14px, -13px) scale(0.75)',
        },
      }
    },
    MuiChip:{
      root:{
        backgroundColor: fade(colors.primary.main, 0.1),
        color: colors.primary.main,
      },
      deleteIcon:{
        color:colors.primary.main
      }
    }
  },
});



type IPropsThemeWrap = {
  children: ReactNode
}

export const ThemeWrap  = (props: IPropsThemeWrap) => {
  return (
    <ThemeProvider theme={uiTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
          {props.children}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
    )
}
