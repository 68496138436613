import { AxiosRequestConfig } from "axios";
import { urlPartialSendEmailFromDashboard } from "../shared/config/GlobalAppConfig";
import { ApiCallingMethods } from "../shared/helpers/AxiosHelper";
import DataConnectionFactory from "../shared/helpers/DataConnectionFactory";
import { map } from 'rxjs/operators';
import { ApiResponse } from "../shared/models/interfaces";

export interface EmailDataFromDashboardDto {
    subject: string,
    email: string
    questionnaireId?: number,
    emailContent: string,
}

export class EmailService {
    static SendEmailFromDashboard(emailDto: EmailDataFromDashboardDto)
    {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialSendEmailFromDashboard,
            data: emailDto
        };

        const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.didError;
        }));
        return res;
    }
}