import { reactAppAdPrefix, UserGroup } from "../config/GlobalAppConfig";
import { EnumUtils } from "../helpers/HelpersFunc";
import {UserRoles} from "./enums";

const createMappingRoles = (): Map<string, UserRoles> => {
    let mapRoles = new Map<string, number>();

    mapRoles.set(UserGroup.ProjectUser!, UserRoles.ProjectUser);
    mapRoles.set(UserGroup.Admin!, UserRoles.Admin);
    mapRoles.set(UserGroup.SecurityTeam!, UserRoles.SecurityTeam);

    return mapRoles;
}

export const getMappingRoles = (propRoles: string | null): number => {
    if(propRoles === null || propRoles === undefined){
        return 0;
    }

    let res = createMappingRoles().get(propRoles);
    return res ? res : 0;
}

export const getMappingRolesFromValue = (propRoles: any): string => {
    let res = EnumUtils.getMapKeyByEnumValue(createMappingRoles(), propRoles);
    return res ? res : '';
}