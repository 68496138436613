import React, { ReactNode, ReactChild, ReactChildren } from "react"
import DeleteIcon from '@material-ui/icons/Delete';
import { Box } from "@material-ui/core"

type IPropsUiBox = {
    name? : string,
    title : string | ReactNode,
    actions? : ReactNode,
    children: ReactChild | ReactChildren,
    altStyle?: boolean,
    collapsed?: boolean,
    showDelete?: boolean,
    onDelete?: () => void
}
export const UiBoxV2 = (props: IPropsUiBox) => {
    const cssClass = ['boxv2']
    if(props.name){
        cssClass.push(props.name)
    }
    if(props.altStyle){
        cssClass.push('boxv2--alt')
    }
    if(props.collapsed){
        cssClass.push('collapsed')
    }

    const handleDelete = function() {
        if(props.onDelete) props.onDelete();
    }
    return(
        <Box className={cssClass.join(' ')}>
            <div className="boxv2-header">
                <h2 className="boxv2-header__title">{props.title}</h2>
                {props.actions && (
                    <div className="boxv2-header__actions">
                        {props.actions}
                    </div>
                )}
                {props.showDelete && <div onClick={handleDelete}><DeleteIcon /></div>}
            </div>
            <div className="boxv2-content">
                {props.children}
            </div>
        </Box>
    )

}

export default UiBoxV2;