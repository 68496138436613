import { Container } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import LoadingContext from '../../contexts/LoadingContext';
import { ThirdPartyService } from '../../services/thirdPartyService';
import UiBox from '../../shared/components/ui/UiBox';
import { ActionType } from '../../shared/components/ui/UiTable/types';
import { UiTable } from '../../shared/components/ui/UiTable/UiTable';
import { CommonProperties } from '../../shared/models/interfaces';
import { IThirdParty } from './components/AddOrEditThirdPartyModal';
import { ThirdPartyDashboardActions } from './components/ThirdPartyDashboardActions';
import { IUiCustomTableHead } from "../../shared/components/ui/UiTable/types";
import {LoadingDelay, thirdPartyDashboardRowPerPage} from '../../shared/config/GlobalAppConfig';
import {
  Actions,
  dateString,
  scoreDateString,
  standardFormat
} from "./components/UiThirdPartyListViewCells";

const scorePopupContent = (
    <div>
        300-399: Insufficient<br/>
        400-549: Basic<br/>
        550-699: Intermediate<br/>
        700-849: Developed<br/>
        850-1000: Mature<br/>
    </div>
)

const thirdPartyTableHeads: IUiCustomTableHead[] = [
    {
        name: "name",
        label: "Name",
        accessor: "companyName",
        sortable: true,
    },
    {
        name: "date",
        label: "First creation",
        accessor: "createdDate",
        cell: dateString,
        sortable: true,
    },
    {
        name: "companyWebsite",
        label: "Third Party url",
        accessor: "companyWebsite",
        sortable: true,
    },
    {
        name: "score",
        label: "Third Party score",
        accessor: "score",
        sortable: true,
        cell: props => standardFormat(props, 'score', '-'),
        showPopup: true,
        popupContent: scorePopupContent,
    },
    {
        name: "date",
        label: "Date of Assessment",
        accessor: "scoreModifiedDate",
        cell: scoreDateString,
        sortable: true,
    },
    {
        name: "numberOfCases",
        label: "Number of cases associated",
        accessor: "numberOfCases",
        sortable: true,
        cell: props => standardFormat(props, 'numberOfCases', '-')
    },
    {
        name: "tiering",
        label: "Tiering",
        accessor: "tiering",
        sortable: true,
        cell: props => standardFormat(props, 'tiering', '-')
    },
    {
        name: "actions",
        label: "",
        cell: Actions,
    }
];

export interface IThirdPartyDashboard extends CommonProperties {
    companyName: string,
    companyWebsite: string,
    contactName?: string,
    emailContact?: string,
    phoneNumber?: string | number,
    score?: string | number,
    scoreModifiedDate?: Date | string,
    numberOfCases?: number,
    tiering?: string
}

export const ThirdPartiesDashboard = function() {
    const [thirdPartiesList, setThirdPartiesList] = useState<IThirdPartyDashboard[]>([]);
    const thirdPartyActionCompRef = useRef<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleAddThirdParty = function(thirdParty: IThirdParty) {
        const newItem: IThirdPartyDashboard = {
            companyName: thirdParty.companyName,
            companyWebsite: thirdParty.companyWebsite,
            contactName: thirdParty.contactName,
            emailContact: thirdParty.emailContact,
            phoneNumber: thirdParty.phoneNumber,
            score: thirdParty.score ?? 0,
            scoreModifiedDate: thirdParty.scoreModifiedDate,
            numberOfCases: 0
        }
        thirdPartiesList.push(newItem);
        setThirdPartiesList(thirdPartiesList.slice());
    }

    const handleUpdateThirdParty = function(thirdParty: IThirdParty) {
        debugger;
        const modifiedList = thirdPartiesList.map(t => {
            if(t.id === thirdParty.id) {
                t.companyName = thirdParty.companyName;
                t.companyWebsite = thirdParty.companyWebsite;
                t.contactName = thirdParty.contactName;
                t.emailContact = thirdParty.emailContact;
                t.phoneNumber = thirdParty.phoneNumber;
                t.score = thirdParty.score;
                t.scoreModifiedDate = thirdParty.scoreModifiedDate;
                t.tiering = thirdParty.tiering;
            }
            return t;
        })
        setThirdPartiesList(modifiedList);
    }

    const handleChange = function(_event:any, action: ActionType, id?: number) {
        if(action === 'delete') {
            const tempList = thirdPartiesList.filter(tp => tp.id !== id);
            setThirdPartiesList(tempList);
        }

        if(action === 'edit') {
            const thirdPartyToEdit = thirdPartiesList.filter(tp => tp.id === id)[0];
            if(thirdPartyToEdit) {
                thirdPartyActionCompRef.current?.handleEdit(thirdPartyToEdit);
            }
        }
    }

    useEffect(() => {
        const toLoading = setTimeout(() => setLoading(true), LoadingDelay);

        const subscription = ThirdPartyService.getDashboard().subscribe((list) => {
            setThirdPartiesList(list);
        }, (error: { status: string | number; statusText: string; }) => {
            console.error(error);
        }, () => {
            clearTimeout(toLoading);
            setLoading(false);
        });

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    return (
        <Container maxWidth="lg">
            <UiBox
                title="Third Parties"
                name="dashboard-thirdParties"
                actions={
                    <ThirdPartyDashboardActions
                        ref={thirdPartyActionCompRef}
                        onAddThirdParty={handleAddThirdParty}
                        onUpdateThirdParty={handleUpdateThirdParty}
                        thirdPartiesList={thirdPartiesList}
                    />
                }
            >
                <UiTable
                    className="dashboard-thirdParties-table"
                    items={thirdPartiesList}
                    loading={loading}
                    rowPerPages={thirdPartyDashboardRowPerPage}
                    tableHeads={thirdPartyTableHeads}
                    onChange={handleChange}
                />
            </UiBox>
        </Container>
    )
}