import { AxiosRequestConfig } from "axios";
import { KindTestExecution } from "../models/enums";
import { isTestsRunning, kindTestExecutionEnv } from "../config/GlobalAppConfig";
import { EnumUtils } from "./HelpersFunc";
import CustomAxios from './AxiosHelper';
import FileHelper from "./FileHelper";

class DataConnectionFactory {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() { }

    request = (options: AxiosRequestConfig, callBack: (() => void) | null = null ) => {
        const isRangeMode: KindTestExecution = EnumUtils.convertStringToEnum(KindTestExecution, kindTestExecutionEnv);
        if(isTestsRunning !== undefined && (isTestsRunning as unknown as number) === 1 && isRangeMode === KindTestExecution.INTEGRATION_LOCAL_TST){
            return FileHelper.request(options, callBack);
        }
        else{
            return CustomAxios.request(options, callBack);
        }
    };


}

export default new DataConnectionFactory();