import { AxiosRequestConfig } from 'axios';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IShareProject } from '../../screens/projectPage/ProjectPage';
import { ISelfAssessmentQuestionnaire } from '../../screens/selfAssessmentQuestionnaire/selfAssessmentQuestionnaire';
import { IThirdParty } from '../../screens/thirdParties/components/AddOrEditThirdPartyModal';
import { urlPartialGetAssignedSecurityUser, urlPartialGetCreatedBy, urlPartialGetDoneAndRemainingStatus, urlPartialGetForm, urlPartialGetHistory, urlPartialGetLastStatus, urlPartialGetProjectOwners, urlPartialGetThirdPartiesList, urlPartialInviteUser, urlPartialSaveForm, urlPartialSubmitForm } from '../../shared/config/GlobalAppConfig';
import { ApiCallingMethods } from '../../shared/helpers/AxiosHelper';
import DataConnectionFactory from '../../shared/helpers/DataConnectionFactory';
import { ApiResponse, NextActionBehavior, Reference, User } from '../../shared/models/interfaces';
import { CommonProperties } from './../../shared/models/interfaces';
import { ISelfAssessmentQuestionnaireServer, mapFormFromServer, mapFormToServer } from './formMappings';

export interface ProgrammingLanguage extends CommonProperties {
    idDigitalApplication: number
    idProgrammingLanguage: number
}

export interface ThirdPartyTask extends CommonProperties {
    idThirdParty: number
    idTask: number
}

export interface Formulaire<T> extends CommonProperties {
    maisonProjectOwner: User,
    technicalPointOfContact: User,
    countries: Reference[],
    selfAssessmentQuestionnaire: T
}

export interface EventHistory {
    comment: string;
    createdBy: string;
    createdDate: Date;
    flowStatus: string;
    id: number;
    idQuestionnaire: number;
    idFlow: number;
    idStatus: number;
    idFlowStatus: number;
    idFlowOwner: number;
    isDeleted: boolean;
    modifiedBy: string;
    modifiedDate: Date;
    flowOwner: string;
    securityAudit: string;
}

export interface DoneAndRemainingStatus {
    draft: boolean;
    new: boolean;
    under_Review: boolean;
    ongoing_Security_Audit: boolean;
    review_Completed: boolean;
    accepted: boolean;
    closed: boolean;
} 

export class FormService {
    static submitForm(form: ISelfAssessmentQuestionnaire, nextAction: NextActionBehavior) {
        const formToSend = mapFormToServer(form, nextAction);

        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialSubmitForm,
            data: formToSend
        };
        
        return DataConnectionFactory.request(options);
    }

    static update(form: ISelfAssessmentQuestionnaire, nextAction: NextActionBehavior, id: number) {
        const formToSend = mapFormToServer(form, nextAction, id);
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: urlPartialSaveForm,
            data: formToSend
        };
        
        const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return mapFormFromServer(response.data.model);
        }));
        return res;
    }

    static getForm(id: string) {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetForm}/${id}`
        };
        
        const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<Formulaire<ISelfAssessmentQuestionnaireServer>>) => {
            const resT: Formulaire<ISelfAssessmentQuestionnaire> = {
                technicalPointOfContact: response.data.model.technicalPointOfContact,
                maisonProjectOwner: response.data.model.maisonProjectOwner,
                countries: response.data.model.countries,
                selfAssessmentQuestionnaire: mapFormFromServer(response.data.model)
            }
            return resT;
        }));
        return res;
    }

    static getHistory(id: string): Observable<EventHistory[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetHistory}/${id}`
        };
        
        const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.model;
        }));
        return res;
    }

    static getLastStatus(id: string): Observable<EventHistory> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetLastStatus}/${id}`
        };
        
        const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.model;
        }));
        return res;
    }

    static GetDoneAndRemainingStatus(id: number): Observable<DoneAndRemainingStatus> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetDoneAndRemainingStatus}/${id}`
        };
        
        const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.model;
        }));
        return res;
    }

    static inviteUser(form: IShareProject) {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialInviteUser,
            data: form
        };
        return DataConnectionFactory.request(options);
    }

    static GetProjectOwners(id: number): Observable<User[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetProjectOwners}/${id}`
        };
        const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.model;
        }));
        return res;
    }

    static GetCreatedBy(id: number): Observable<string> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetCreatedBy}/${id}`
        };
        
        const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.model;
        }));
        return res;
    }

    static GetAssignedSecurityUser(id: number): Observable<User> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetAssignedSecurityUser}/${id}`
        };
        
        const res = DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.model;
        }));
        return res;
    }
    
    static getThirdParties(): Observable<Reference[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetThirdPartiesList
        };
        
        return  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<IThirdParty[]>) => {
            return response.data.model?.map(tp => {
                return {
                    id: tp.id ?? 0,
                    itemtKey: tp,
                    itemContent: tp.companyName
                } as Reference;
            });
        }));
    }
}