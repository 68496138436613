import React, {  } from "react";
import { MAX_NOTE_CHARS } from "../note/NotesTable";

interface ICountCharacters {
    noteText : string
}
export const CountCharacters = (props : ICountCharacters) =>  {


  return (
    <div className="CountCharacters">
      { props.noteText.length > 0 ? <p className="counter">{props.noteText.length} / {MAX_NOTE_CHARS}</p> : null }
    </div>
  )
}