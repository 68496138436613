import cLocal from "date-fns/locale/fr";

export const isTestsRunning = process.env.JEST_WORKER_ID;
export const kindTestExecutionEnv = process.env.REACT_APP_KIND_TEST;
export const mockTestPath = "public/mockDB";
export const mockTestFileExtension = ".json";

export const localUrlBase = process.env.REACT_APP_REDIRECT_URI;
export const serverUrlBase = process.env.REACT_APP_PUBLIC_URL;
export const dashboardRowPerPage: number = Number(process.env.REACT_APP_ROW_PER_PAGE);
export const thirdPartyDashboardRowPerPage: number = Number(process.env.REACT_APP_TP_ROW_PER_PAGE);
export const reactAppAdPrefix = process.env.REACT_APP_AD_PREFIX;

export const UserGroup = {
    ProjectUser: process.env.REACT_APP_GRP_STAKEHOLDER,
    SecurityTeam: process.env.REACT_APP_GRP_SECURITY_TEAM,
    Admin: process.env.REACT_APP_GRP_ADMIN
}

export const grpSecurityTeam = process.env.REACT_APP_GRP_SECURITY_TEAM;
export const grpAdmin = process.env.REACT_APP_GRP_ADMIN;

export const appCurrentLocation = cLocal;
export const urlPartialLogs: string = serverUrlBase + '/api/Logs/SaveLog';
export const urlPartialGetDashboard: string = serverUrlBase + '/api/Dashboard/GetDashboard';
export const urlPartialExportQuestionnaires: string = serverUrlBase + '/api/Dashboard/ExportQuestionnaires';
export const urlPartialGetAllUsersInvolvedInQuestionnaires: string = serverUrlBase + '/api/Dashboard/GetAllUsersInvolvedInQuestionnaires';
export const urlPartialGetRefDataThirdParties: string = serverUrlBase + '/api/AppRefData/GetListThirdParties';
export const urlPartialGetRefDataPersonalDataType: string = serverUrlBase + '/api/AppRefData/GetListPersonalDataType';
export const urlPartialGetRefDataThirdPartyActivities: string = serverUrlBase + '/api/AppRefData/GetListThirdPartyActivity';
export const urlPartialGetRefDataApplicationType: string = serverUrlBase + '/api/AppRefData/GetListApplicationType';
export const urlPartialGetRefDataApplicationScope: string = serverUrlBase + '/api/AppRefData/GetListApplicationScope';
export const urlPartialGetRefDataNbStandardUsers: string = serverUrlBase + '/api/AppRefData/GetListNbStandardUsers';
export const urlPartialGetRefDataApplicationHostEnv: string = serverUrlBase + '/api/AppRefData/GetListApplicationHostEnv';
export const urlPartialGetRefDataApplicationAccessUsers: string = serverUrlBase + '/api/AppRefData/GetListApplicationAccessUsers';
export const urlPartialGetRefDataProgrammingLanguage: string = serverUrlBase + '/api/AppRefData/GetListProgrammingLanguage';
export const urlPartialGetRefDataProjectRegion: string = serverUrlBase + '/api/AppRefData/GetListProjectRegion';
export const urlPartialGetRefDataTypeBU: string = serverUrlBase + '/api/AppRefData/GetListDataTypeBU';
export const urlPartialGetRefSensitiveInformationType: string = serverUrlBase + '/api/AppRefData/GetListSensitiveInformationType';
export const urlPartialGetRefNbProductsInvolved: string = serverUrlBase + '/api/AppRefData/GetListNbProductsInvolved';
export const urlPartialGetRefDataProcessingType: string = serverUrlBase + '/api/AppRefData/GetListDataProcessingType';
export const urlPartialGetRefNbDataSubjects: string = serverUrlBase + '/api/AppRefData/GetListNbDataSubjects';
export const urlPartialGetRefPersonalDataRetained: string = serverUrlBase + '/api/AppRefData/GetListPersonalDataRetained';
export const urlPartialGetRefDataSubjectsType: string = serverUrlBase + '/api/AppRefData/GetListDataSubjectsType';
export const urlPartialGetRefMaisonBrands: string = serverUrlBase + '/api/AppRefData/GetListMaisonBrands';
export const urlPartialGetRefCountries: string = serverUrlBase + '/api/AppRefData/GetListCountries';
export const urlPartialGetRefQuestions: string = serverUrlBase + '/api/AppRefData/GetListQuestions';
export const urlPartialSubmitForm: string = serverUrlBase + '/api/Form/AddSelfAssessmentQuestionnaire';
export const urlPartialGetForm: string = serverUrlBase + '/api/Form/GetSelfAssessmentQuestionnaire';
export const urlPartialGetDocuments: string = serverUrlBase + '/api/File/GetDocuments';
export const urlPartialGetUploadedFilesList : string  = serverUrlBase + '/api/File/UploadedFiles';
export const urlPartialUrlGetDocumentRightsByRole = serverUrlBase + '/api/File/GetDocumentRightsByRole'
export const urlPartialUpload: string = serverUrlBase + '/api/File/Upload';
export const urlPartialProgress: string = serverUrlBase + '/api/File/Progress';
export const urlPartialDownload: string = serverUrlBase + '/api/File/Download';
export const urlPartialDelete: string = serverUrlBase + '/api/File/Delete';
export const urlPartialGetSecurityTeamUsers: string = serverUrlBase + '/api/AppRefData/GetSecurityTeamUsers';
export const urlPartialAssignTo: string = serverUrlBase + '/api/Dashboard/AssignToUser';
export const urlPartialSendEmailFromDashboard: string = serverUrlBase + '/api/Email/SendEmailFromDashboard';
export const urlPartialGetStatusList: string = serverUrlBase + '/api/Dashboard/GetStatus';
export const urlPartialChangeStatus: string = serverUrlBase + '/api/Dashboard/ChangeStatus';
export const urlPartialGetHistory: string = serverUrlBase + '/api/Form/GetFormHistoryStatus';
export const urlPartialGetLastStatus: string = serverUrlBase + '/api/Form/GetLastStatus';
export const urlPartialReopenProject: string = serverUrlBase + '/api/Form/ReopenProject';
export const urlPartialDuplicateProject: string = serverUrlBase + '/api/Form/DuplicateProject';
export const urlPartialGetStatusFlowCasesForProject: string = serverUrlBase + '/api/Form/GetStatusFlowCasesForProject';
export const urlPartialGetListFlowStatus: string = serverUrlBase + '/api/AppRefFlowData/GetListFlowStatus';
export const urlPartialGetThirdPartyList: string = serverUrlBase + '/api/Dashboard/GetThirdPartyList';
export const urlPartialGetAllUsersInvolved: string = serverUrlBase + '/api/Dashboard/GetAllUsersInvolved';
export const urlPartialGetKPIs: string = serverUrlBase + '/api/Dashboard/GetKPIs';
export const urlPartialGetNextActions: string = serverUrlBase + '/api/AppRefFlowData/GetNextActions';
export const urlPartialPerformAction: string = serverUrlBase + '/api/Form/PerformAction';
export const urlPartialGetDoneAndRemainingStatus: string = serverUrlBase + '/api/Form/GetDoneAndRemainingStatus';
export const urlPartialAddNote: string = serverUrlBase + '/api/Note';
export const urlPartialUpdateNote: string = serverUrlBase + '/api/Note';
export const urlPartialDeleteNote: string = serverUrlBase + '/api/Note';
export const urlPartialGetNotesByQuestionnaire: string = serverUrlBase + '/api/Note/GetByQuestionnaire';
export const urlPartialCountNotesByQuestionnaire: string = serverUrlBase + '/api/Note/CountByQuestionnaire';
export const urlPartialGetSecurityAuditList: string = serverUrlBase + '/api/AppRefData/GetSecurityAuditList';
export const urlPartialGetListUsers: string = serverUrlBase + '/api/AppRefData/GetListUsers';
export const urlPartialSaveForm: string = serverUrlBase + '/api/Form/SaveSelfAssessmentQuestionnaire';
export const urlPartialInviteUser: string = serverUrlBase + '/api/Form/InviteUser';
export const urlPartialGetCreatedBy: string = serverUrlBase + '/api/Form/GetCreatedBy';
export const urlPartialGetAssignedSecurityUser: string = serverUrlBase + '/api/Form/GetAssignedSecurityUser';
export const urlPartialGetThirdPartyDashboard: string = serverUrlBase + '/api/ThirdParty/GetDashboard';
export const urlPartialGetProjectOwners: string = serverUrlBase + '/api/Form/GetProjectOwners';

export const urlPartialAddThirdParty: string = serverUrlBase + '/api/ThirdParty/AddThirdParty';
export const urlPartialUpdateThirdParty: string = serverUrlBase + '/api/ThirdParty/EditThirdParty';
export const urlPartialDeleteThirdParty: string = serverUrlBase + '/api/ThirdParty/DeleteThirdParty';
export const urlPartialGetThirdPartiesList: string = serverUrlBase + '/api/Form/GetThirdPartiesList';

export const urlPartialImport: string = serverUrlBase + '/api/Dashboard/ImportQuestionnaires';


export const truncateMaxCharNb = 36;
export const truncateText = "...";

//Notification display time in ms
export const notificationDisplayTime = {
    info: 5000,
    succes: 5000,
    warning: 5000,
    error: 5000,
    cancel: 3000
}
// Notification message
export const notificationMessage = {
    Submit_project: { title: 'Submitted', message: 'Questionnaires has been successfully submitted' },
    Complete_review: { title: 'Review completed', message: 'Project has been successfully reviewed' },
    Send_for_security_audit: { title: 'Info', message: 'Project has been successfully sent for security audit' },
    Cancel_project: { title: 'Info', message: 'Project successfully cancelled' },
    Assign_to: { title: 'Message sent', message: 'Project successfully assigned to security' },
    Duplicate_the_project: { title: 'Info', message: 'Project was successfully duplicated' },
    Invite_someone: { title: 'Message sent', message: 'Invitation was successfully sent' },
    ShadowIt_On: { title: 'Shadow IT Updated', message: 'Shadow IT was sucessfully updated' },
    Send_to_close: { title: 'Info', message: 'Project successfully closed' },
    ErrorGenericMessage: { title: 'Error', message: 'An error occurred, please contact your admin' },
    Form_save: { title: 'Saved', message: 'Questionnaire was successfully saved' },
    Form_not_valid: { title: 'Action allowed', message: 'Questionnaire has missing mandatory fields' },
    Form_submitted: { title: 'Submitted', message: 'Questionnaire was successfully submitted' },
    File_Uploaded : { title: 'Uploaded', message: 'File uploaded successfully' },
    File_Deleted : { title: 'Deleted', message: 'File deleted successfully' },
    File_CantUpload: { title: 'Info', message: 'Please save the project before uploading the file' },
    Note_updated: { title: 'Note updated', message: 'The note has been updated' },
    Note_deleted: { title: 'Note deleted', message: 'The note has been deleted' },
    Note_delete_error: { title: 'Error', message: 'Something went wrong' },
    Note_update_error: { title: 'Error', message: 'Something went wrong during the update' },
    User_not_found: { title: 'Error', message: 'User not found' },
    ThirdPartyForm_added: { title: 'Added', message: 'Third Party was successfully added' },
    ThirdPartyForm_updated: { title: 'Updated', message: 'Third Party was successfully updated' },
    ThirdPartyForm_removed: { title: 'Deleted', message: 'Third Party was successfully deleted' },
    Accept_the_assessment: { title: 'Accepted', message: 'The project has been accepted.' },
    ThirdPartyForm_already_added: {title: 'Warning', message: 'Unable to add a third party company multiple times.'},
    Import_Error: {title: 'Import error', message: 'Something went wrong during the import'},
    Sent_to_review: { title: 'Sent to review', message: 'Project has been successfully sent to review' },
    Reopen_project: { title: 'Reopened', message: 'Project has been successfully reopen' }
}

export const searchTimeout = 800;

export const NotificationBannerTimeDisplayMs = 3000;
export const LoadingDelay = 300;
export const REFRESH_TOKEN_DELAY = 1800000;