import React from 'react';
import SiteRoutes from './shared/routes/Routes';
import './App.css';

const App = () => {
  return (
      <div>
        <SiteRoutes />
      </div>
  );
}

export default App;