import React, { ReactNode } from "react"
import Chip from '@material-ui/core/Chip';

type IPropsUiStatus = {
    name? : any
    label: ReactNode
}
export const UiStatus = (props: IPropsUiStatus) => {
    return(
        <div style={{display: 'inline'}}>
            <Chip {...props} className={"status ui-status--"+props.name }/>
        </div>
    )

}

export default UiStatus;