import { AxiosRequestConfig } from "axios";
import { map } from "rxjs/operators";
import {
    urlPartialGetRefDataApplicationAccessUsers,
    urlPartialGetRefDataApplicationHostEnv,
    urlPartialGetRefDataApplicationType,
    urlPartialGetRefDataNbStandardUsers,
    urlPartialGetRefDataProcessingType,
    urlPartialGetRefDataProgrammingLanguage,
    urlPartialGetRefDataProjectRegion,
    urlPartialGetRefDataThirdParties,
    urlPartialGetRefDataThirdPartyActivities,
    urlPartialGetRefDataTypeBU,
    urlPartialGetRefDataSubjectsType,
    urlPartialGetRefNbDataSubjects,
    urlPartialGetRefNbProductsInvolved,
    urlPartialGetRefPersonalDataRetained,
    urlPartialGetRefSensitiveInformationType,
    urlPartialGetRefMaisonBrands,
    urlPartialGetRefCountries,
    urlPartialGetRefDataApplicationScope,
    urlPartialGetRefQuestions,
    urlPartialGetSecurityTeamUsers,
    urlPartialGetSecurityAuditList,
    urlPartialGetRefDataPersonalDataType,
    urlPartialGetListUsers
} from "../shared/config/GlobalAppConfig";
import { ApiCallingMethods } from "../shared/helpers/AxiosHelper";
import DataConnectionFactory from "../shared/helpers/DataConnectionFactory";
import { Reference, User } from "../shared/models/interfaces";

export class ReferenceService {
    static GetListThirdParty(thirdPartyNameSearch?: string) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataThirdParties,
            data: null,
            params: {
                thirdPartyKey: thirdPartyNameSearch
            }
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListThirdPartyActivies() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataThirdPartyActivities,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListApplicationType() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataApplicationType,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListApplicationScope() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataApplicationScope,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListNbStandardUsers() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataNbStandardUsers,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListApplicationHostEnv() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataApplicationHostEnv,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListApplicationAccessUsers() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataApplicationAccessUsers,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListProgrammingLanguage() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataProgrammingLanguage,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListProjectRegion() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataProjectRegion,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListDataTypeBU() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataTypeBU,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListSensitiveInformationType() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefSensitiveInformationType,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListNbProductsInvolved() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefNbProductsInvolved,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListDataProcessingType() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataProcessingType,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListNbDataSubjects() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefNbDataSubjects,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListPersonalDataRetained() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefPersonalDataRetained,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListDataSubjectsType() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataSubjectsType,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListMaisonBrands() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefMaisonBrands,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListCountries(countrySearch?: string) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefCountries,
            data: null,
            params: {
                countryKey: countrySearch
            }
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListQuestions() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefQuestions,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetSecurityTeamUsers() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetSecurityTeamUsers,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetSecurityAuditList() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetSecurityAuditList,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListPersonalDataType() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRefDataPersonalDataType,
            data: null
        }

        return DataConnectionFactory.request(options).pipe(map(response => response.data.model));
    }

    static GetListAdUsers(userSearch?: string) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetListUsers,
            data: null,
            params: {
                searchValue: userSearch
            }
        }

        return DataConnectionFactory.request(options).pipe(map(response => {
            if (response.data) {
                return response.data.model.map((user: User): Reference =>
                ({
                    itemContent: user.fullName,
                    id: user.email
                }));
            }
            return [];
        }));
    }
}