import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Action } from '../../../shared/components/ui/UiModal/UiModal';
import { AddOrEditThirdPartyModal, initThirdParty, IThirdParty } from './AddOrEditThirdPartyModal';

interface Props {
    thirdPartiesList: IThirdParty[],
    onAddThirdParty: (thirdParty: IThirdParty) => void,
    onUpdateThirdParty: (thirdParty: IThirdParty) => void
}

export const ThirdPartyDashboardActions = forwardRef((props: Props, ref) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [thirdParty, setThirdParty] = useState<IThirdParty>();

    useImperativeHandle(ref, () => ({ handleEdit }))

    const handleEdit = function(thirdParty: IThirdParty) {
        setThirdParty(thirdParty);
        setOpenModal(true);
    }

    const handleAdd = function() {
        setThirdParty(initThirdParty);
        setOpenModal(true);
    }

    const handleCloseModal = function(thirdParty:IThirdParty, reason: Action) {
        if(reason === 'applyFilter') {
            props.onAddThirdParty(thirdParty);
        }
        if(reason === 'update') {
            props.onUpdateThirdParty(thirdParty);
        }
        setOpenModal(false);
    }

    return (
        <div className="dashboard-actions">
            <div className="dashboard-actions__item">
                {/* <Button id="add-third-party" onClick={handleAdd} color="primary" variant="outlined" startIcon={<AddIcon />}> Add Third Party</Button> */}
                <AddOrEditThirdPartyModal
                    open={openModal}
                    onClose={handleCloseModal}
                    thirdParty={thirdParty}
                    thirdPartiesList={props.thirdPartiesList}
                />
            </div>
        </div>
    )
})