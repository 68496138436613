import { Grid } from "@material-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { ReferenceService } from "../../../services/referenceService";
import { UiAutocompleteField, UiSelectField, UiSwitchField, UiTextField } from "../../../shared/components/fields";
import { IUiMultiselectOption } from "../../../shared/components/fields/UiAutocompleteField";
import { Option } from "../../../shared/components/SelectField";
import { updateMultipleSelectedValues } from "../../../shared/helpers/HelpersFunc";
import { DataSubjectsType, DataTypeBU, PersonalDataRetained, SensitiveData, SensitiveDataIds } from "../../../shared/models/enums";
import { CommonProperties, Reference } from "../../../shared/models/interfaces";

export interface IDataInformation extends CommonProperties {
    sensitiveCompanyInfo?: Option[],
    sensitiveCompanyInfoSaved?: SensitiveCompanyInfo[],
    sensitiveCompanyInfoSelected?: SensitiveCompanyInfo[],
    idProductInvolved?: number | string,
    isThisProductDataNotReleased?: boolean,
    isThereCreditCardSwitch?: boolean,
    isTherePersonalDataInvolved: boolean,
    dataUsedFor?: Option[],
    dataUsedForSaved?: TypeBU[],
    dataUsedForSelected?: TypeBU[],
    idPersonalDataRetainedBy?: number | string,
    idDataSubjectsCollected?: number | string,
    isPersonalDataNecessary?: boolean,
    dataSubjectInvolved?: Option[],
    dataSubjectInvolvedSaved?: DataSubjectInvolved[],
    dataSubjectInvolvedSelected?: DataSubjectInvolved[],
    dataProcessing?: Option[],
    dataProcessingSaved?: DataProcessing[],
    dataProcessingSelected?: DataProcessing[],
    haveClearProcess?: boolean,
    isTherePersonalDataUsed?: boolean,
    doesInvolvePiiTransfer?: boolean,
    personalDataType?: Option[],
    personalDataTypeSaved?: Option[],
    personalDataTypeSelected?: any,
    otherSubjectInvolved?: string,
    otherPersonalData?: string,
    otherDataUsedFor?: string,
    isThereInternationalTransfer?: boolean
    otherSensitiveInformation?: string
}

export interface SensitiveCompanyInfo extends CommonProperties {
    idDataInformation: number
    idSensitiveCompanyInfo: number,
    other?: string
}

export interface TypeBU extends CommonProperties {
    idDataInformation: number
    idDataUsedFor: number,
    other?: string
}

interface DataProcessing extends CommonProperties {
    idDataInformation: number
    idDataProcessing: number
}

interface PersonalDataType extends CommonProperties {
    idPersonalDataType: number
    idDataProcessing: number
}

export interface DataSubjectInvolved extends CommonProperties {
    idDataInformation: number
    idDataSubjectInvolved: number,
    other?: string
}

interface Props extends FieldProps, FormikProps<any> {
    disabled: boolean
}

export default function DataInformation(props: Props) {
    const { field, form, disabled } = props;
    const { setFieldValue } = form;
    const [isIntelectualPropSelected, setIsIntelectualPropSelected] = useState(false);
    const [isFinancialDataSelected, setIsFinancialDataSelected] = useState(false);
    const [typeBUList, setTypeBUList] = useState([]);
    const [sensitiveInformationList, setSensitiveInformationList] = useState([]);
    const [productsInvolvedList, setProductsInvolvedList] = useState([]);
    const [personalDataRetainedList, setPersonalDataRetainedList] = useState([]);
    const [dataProcessingTypeList, setDataProcessingTypeList] = useState([]);
    const [dataSubjectsList, setDataSubjectsList] = useState([]);
    const [subjectTypesList, setSubjectTypesList] = useState([]);
    const [personalDataTypesList, setPersonalDataTypesList] = useState([]);

    const handleCustomSelect = function (values: Array<IUiMultiselectOption>, _projectId: number, name: string) {
        updateMultipleSelectedValues(values, name, field, setFieldValue);
    }

    useEffect(() => {
        if (form.values.dataInformation.sensitiveCompanyInfo) {
            if (form.values.dataInformation.sensitiveCompanyInfo.find((sci: Reference) => sci.itemContent === SensitiveData.IntellectualProperty)) {
                setIsIntelectualPropSelected(true);
            }
            else {
                setIsIntelectualPropSelected(false);
            }
            if (form.values.dataInformation.sensitiveCompanyInfo.find((sci: Reference) => sci.itemContent === SensitiveData.FinancialData)) {
                setIsFinancialDataSelected(true);
            }
            else {
                setIsFinancialDataSelected(false);
            }
        }
    }, [form.values.dataInformation.sensitiveCompanyInfo])

    useEffect(() => {
        if (field.value?.personalDataTypeSelected) {
            const selectedPdts = field.value.personalDataTypeSelected.map((pdt: PersonalDataType) => {
                return personalDataTypesList.find((sil: Reference) => sil.id === pdt.idPersonalDataType)
            })
            setFieldValue(`${field.name}.personalDataType`, selectedPdts);
        }

        if (field.value?.sensitiveCompanyInfoSaved) {
            const selectedScis = field.value.sensitiveCompanyInfoSaved.map((sci: SensitiveCompanyInfo) => {
                return sensitiveInformationList.find((sil: Reference) => sil.id === sci.idSensitiveCompanyInfo)
            })
            setFieldValue(`${field.name}.sensitiveCompanyInfo`, selectedScis);
        }

        if (field.value?.dataSubjectInvolvedSaved) {
            const selectedDsis = field.value.dataSubjectInvolvedSaved.map((dsi: DataSubjectInvolved) => {
                return subjectTypesList.find((stl: Reference) => stl.id === dsi.idDataSubjectInvolved)
            })
            setFieldValue(`${field.name}.dataSubjectInvolved`, selectedDsis);
        }

        if (field.value?.dataUsedForSaved) {
            const selectedDufs = field.value.dataUsedForSaved.map((duf: TypeBU) => {
                return typeBUList.find((typeBu: Reference) => typeBu.id === duf.idDataUsedFor)
            })
            setFieldValue(`${field.name}.dataUsedFor`, selectedDufs);
        }

        if (field.value?.dataProcessingSaved) {
            const selectedDsis = field.value.dataProcessingSaved.map((dp: DataProcessing) => {
                return dataProcessingTypeList.find((dpt: Reference) => dpt.id === dp.idDataProcessing)
            })
            setFieldValue(`${field.name}.dataProcessing`, selectedDsis);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        field.value?.sensitiveCompanyInfoSaved,
        sensitiveInformationList
    ])

    useEffect(() => {
        const typeBuSubscription = ReferenceService.GetListDataTypeBU().subscribe(typeBUList => {
            setTypeBUList(typeBUList)
        });
        const sensitiveInfoSubscription = ReferenceService.GetListSensitiveInformationType().subscribe(
            sensitiveInformationList => setSensitiveInformationList(sensitiveInformationList)
        );
        const productsInvolvedSubscription = ReferenceService.GetListNbProductsInvolved().subscribe(
            productsInvolvedList => setProductsInvolvedList(productsInvolvedList)
        );
        const personalDataRetainedSubscription = ReferenceService.GetListPersonalDataRetained().subscribe(
            personalDataRetainedList => setPersonalDataRetainedList(personalDataRetainedList)
        );
        const DataProcessingTypeSubscription = ReferenceService.GetListDataProcessingType().subscribe(
            dataProcessingTypeList => setDataProcessingTypeList(dataProcessingTypeList)
        );
        const standardUsersSubscription = ReferenceService.GetListNbDataSubjects().subscribe(
            dataSubjectsList => setDataSubjectsList(dataSubjectsList)
        );
        const subjectTypesSubscription = ReferenceService.GetListDataSubjectsType().subscribe(
            datasubjectTypesList => setSubjectTypesList(datasubjectTypesList)
        );
        const personalDataTypesSubscription = ReferenceService.GetListPersonalDataType().subscribe(
            personalDataTypesList => setPersonalDataTypesList(personalDataTypesList)
        );

        return () => {
            typeBuSubscription.unsubscribe();
            sensitiveInfoSubscription.unsubscribe();
            productsInvolvedSubscription.unsubscribe();
            personalDataRetainedSubscription.unsubscribe();
            DataProcessingTypeSubscription.unsubscribe();
            standardUsersSubscription.unsubscribe();
            subjectTypesSubscription.unsubscribe();
            personalDataTypesSubscription.unsubscribe();
        }
    }, [])

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Field
                        id="sensitiveCompanyInfoMultipleSelect"
                        name={`${field?.name}.sensitiveCompanyInfo`}
                        label="Is there any of the following company information involved in the project?*"
                        component={UiAutocompleteField}
                        multiple={true}
                        showSecondLabel={true}
                        options={sensitiveInformationList}
                        disabled={disabled}
                        onCustomSelect={handleCustomSelect}
                    />
                    {isIntelectualPropSelected && (
                        <Field
                            id="ipDataSingleSelect"
                            name={`${field?.name}.idProductInvolved`}
                            label="IP data about how many products is involved in this project?"
                            component={UiSelectField}
                            options={productsInvolvedList}
                            disabled={disabled}
                        />
                    )}
                    <Field
                        id="isTherePersonalDataInvolvedSwitch"
                        name={`${field?.name}.isTherePersonalDataInvolved`}
                        label="Is there any personal data involved in this project?"
                        tooltip="names, contact details, audio-visual materials, logs or cookies, medical status, transactional information, recruitment information, habits or personality information,…"
                        component={UiSwitchField}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {!!field.value.sensitiveCompanyInfo?.filter((t: Reference) => t.id === SensitiveDataIds.Other).length && (
                        <Field
                            name={`${field?.name}.otherSensitiveInformation`}
                            label="Specify the other information here*"
                            component={UiTextField}
                        />
                    )}
                    {isIntelectualPropSelected && (
                        <Field
                            id="isThisProductDataNotReleasedSwitch"
                            name={`${field?.name}.isThisProductDataNotReleased`}
                            label="Is this data about a product(s) that is/are not yet released publicly?"
                            tooltip="Product: Any tangible item (watch, jewelery, etc) produced by the group"
                            component={UiSwitchField}
                            disabled={disabled}
                        />
                    )}
                    {isFinancialDataSelected && (
                        <Field
                            id="isThereCreditCardSwitch"
                            name={`${field?.name}.isThereCreditCardSwitch`}
                            label="Is there any credit card/bank information involved?"
                            component={UiSwitchField}
                            disabled={disabled}
                        />
                    )}
                </Grid>
            </Grid>
            {form.values.dataInformation.isTherePersonalDataInvolved && (
                <Grid container spacing={3} id="PersonalData">
                    <Grid item xs={12} md={6}>
                        <Field
                            name="dataInformation.personalDataType"
                            label="Which personal data elements is involved?"
                            component={UiAutocompleteField}
                            multiple={true}
                            options={personalDataTypesList}
                            onCustomSelect={handleCustomSelect}
                        />
                        <Field
                            name="dataInformation.dataUsedFor"
                            label="What do you use this data for?*"
                            component={UiAutocompleteField}
                            multiple={true}
                            options={typeBUList}
                            disabled={disabled}
                            onCustomSelect={handleCustomSelect}
                        />
                        {!!field.value.dataUsedFor.filter((t: Reference) => t.id === DataTypeBU.Other).length && (
                            <Field
                                name={`${field?.name}.otherDataUsedFor`}
                                label="Specify the other here"
                                component={UiTextField}
                            />
                        )}
                        <Field
                            name="dataInformation.idPersonalDataRetainedBy"
                            label="How long will the personal data be retained either by the third party vendor (if any) or by the Maison?*"
                            component={UiSelectField}
                            options={personalDataRetainedList}
                            disabled={disabled}
                        />
                        {field.value.idPersonalDataRetainedBy === PersonalDataRetained.Other && (
                            <Field
                                name={`${field?.name}.otherPersonalData`}
                                label="Specify the other here"
                                component={UiTextField}
                            />
                        )}
                        <Field
                            name="dataInformation.dataSubjectInvolved"
                            label="Personal information about what type of data subjects/individuals is involved?*"
                            component={UiAutocompleteField}
                            multiple={true}
                            options={subjectTypesList}
                            disabled={disabled}
                            onCustomSelect={handleCustomSelect}
                        />
                        {!!field.value.dataSubjectInvolved?.filter((t: Reference) => t.id === DataSubjectsType.Other).length && (
                            <Field
                                name={`${field?.name}.otherSubjectInvolved`}
                                label="Specify the other here"
                                component={UiTextField}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="dataInformation.dataProcessing"
                            label="Does the data processing involve one of the above?*"
                            component={UiAutocompleteField}
                            multiple={true}
                            options={dataProcessingTypeList}
                            disabled={disabled}
                            onCustomSelect={handleCustomSelect}
                        />
                        <Field
                            name="dataInformation.idDataSubjectsCollected"
                            label="Personal information about how many data subjects is involved?*"
                            component={UiSelectField}
                            options={dataSubjectsList}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="dataInformation.isPersonalDataNecessary"
                            label="Is the personal data you collect limited to what is strictly necessary for the purpose of the project?"
                            component={UiSwitchField}
                            tooltip="You do not collect more data than what is necessary to achieve the purpose of the project"
                            disabled={disabled}
                        />
                        <Field
                            name="dataInformation.haveClearProcess"
                            label="Do you have a clear process allowing the data subjects to request that their data is deleted, or exercise other rights they have over their data?"
                            component={UiSwitchField}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            name="dataInformation.isTherePersonalDataUsed"
                            label="Is the personal data you collect used only for the purpose of this project, and no more than is necessary?"
                            component={UiSwitchField}
                            tooltip="You do not use the collected data for another purpose of which data subjects have been informed"
                            disabled={disabled}
                        />
                        <Field
                            name="dataInformation.isThereInternationalTransfer"
                            label="Will there be any international transfer of personal information?"
                            component={UiSwitchField}
                            tooltip="Personal information transferred from one country to another"
                        />
                        {
                            field.value.isThereInternationalTransfer && (
                                <Field
                                    name="dataInformation.doesInvolvePiiTransfer"
                                    label="Will there be any personal information transferred from China to another country, or access to Chinese personal information from outside of China?"
                                    component={UiSwitchField}
                                    disabled={disabled}
                                />
                            )
                        }
                    </Grid>
                </Grid>
            )}
        </>
    )
}