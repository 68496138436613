import { LocalStorageToken } from "../components/upload/interfaces";
import { StorageTermEnum } from "../models/enums";
import {AuthenticationResult} from "@azure/msal-browser";

const LocalStorageTokenService = (function () {
    var _service: any; 
    
    function _getService(this: { getService: () => any; setToken: (tokenObj: AuthenticationResult) => void; getAccessToken: () => string | null; getRefreshToken: () => string | null; clearToken: () => void; }) {
        if (!_service) {
            _service = this;
            return _service
        } return _service
    }

    function _setToken(tokenObj: AuthenticationResult) {
        if(tokenObj === undefined || tokenObj === null){
            return;
        }
        sessionStorage.setItem(StorageTermEnum.accessToken, (tokenObj.accessToken as string));
        sessionStorage.setItem(StorageTermEnum.userFullName, (tokenObj.account?.name as string));
        sessionStorage.setItem(StorageTermEnum.userName, (tokenObj.account?.username as string));
        const claims = tokenObj.idTokenClaims as { [key: string]: any };
        const roles = claims.groups;
        sessionStorage.setItem(StorageTermEnum.userRoles, (roles as string));
    }

    function _getAccessToken() {
        return sessionStorage.getItem(StorageTermEnum.accessToken);
    }
    function _getRefreshToken() {
        return sessionStorage.getItem(StorageTermEnum.refreshToken);
    }
    function _getUserNameToken() {
        return sessionStorage.getItem(StorageTermEnum.userName);
    }
    function _getUserRoleToken() {
        return sessionStorage.getItem(StorageTermEnum.userRoles);
    }
    function _getUserFullName() {
        return sessionStorage.getItem(StorageTermEnum.userFullName);
    }

    function _clearToken() {
        sessionStorage.removeItem(StorageTermEnum.accessToken);
        sessionStorage.removeItem(StorageTermEnum.refreshToken);
        sessionStorage.removeItem(StorageTermEnum.userFullName);
        sessionStorage.removeItem(StorageTermEnum.userName);
        sessionStorage.removeItem(StorageTermEnum.userRoles);
    } 
    
    return {
        getService: _getService,
        setToken: _setToken,
        getAccessToken: _getAccessToken,
        getRefreshToken: _getRefreshToken,
        getUserNameToken: _getUserNameToken,
        getUserRoleToken: _getUserRoleToken,
        getUserFullName: _getUserFullName,
        clearToken: _clearToken
    }
})();

export default LocalStorageTokenService;