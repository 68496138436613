export enum KindTestExecution {
    NONE = "NONE",
    INTEGRATION_LOCAL_TST = "INTEGRATION_LOCAL_TST",
    INTEGRATION_SERVER_TST = "INTEGRATION_SERVER_TST",
    UNIT_TST = "UNIT_TST"
}

export enum UserRoles {
    None = 0,
    ProjectUser = 1,
    SecurityTeam = 2,
    SecurityUser = 3,
    All = 4,
    Admin = 5
}

// export enum UserGroup {
//     admin = grpAdmin,
//     ProjectUser = "00000000-0000-0000-0000-000000000000",
//     SecurityTeam = "ee47c892-54c6-41dd-bca0-743b5d0d820b"
// }

export enum DocAccessType {
    "Richemont only" = 1,
    "Visible to all" = 3,
    "No Type" = 6,
}


export enum PageAccessAction {
    View_Action = 1,
    Save_Action = 2,
    Edit_Action = 4
}

export enum NotificationType {
    info = 1,
    success = 2,
    warning = 3,
    error = 4
}

export enum AppType {
    other = 7
}

export enum ProgrammingLanguage {
    other = 7,
}

export enum DocumentActions {
    Upload = "upload",
    Download = "download",
    Delete = "delete"
}

export enum SensitiveData {
    IntellectualProperty = "Intellectual Property data",
    FinancialData = "Financial data about individuals",
}

export type StatusType = 'new' | 'draft' | 'underreview' | 'ongoingsecurityaudit' | 'reviewcompleted' | 'accepted' | 'closed';

export enum Status {
    draft = 1,
    new = 2,
    under_review = 3,
    ongoing_security_audit = 4,
    review_completed = 5,
    accepted = 6,
    closed = 7,
    canceled = 8
}

export enum FlowStatus {
    draft = 1,
    new = 5
}

export enum TargetEmailRole {
    Global = "global"
}

export enum Action {
    Submit_project = 1,
    Cancel_project = 2,
    Invite_someone = 3,
    Assign_to = 4,
    Send_for_security_audit = 5,
    Send_to_review = 6,
    Accept_the_assessment = 7,
    Duplicate_the_project = 8,
    Reopen_the_project = 9,
    Send_to_close = 10,
    Change_Status = 11,
    Edit_Questionnaire = 12,
    Complete_Review = 13,
    Toggle_ShadowIt = 15

}

export enum TaskMappingToAction {
    "Submit_project" = Action.Submit_project,
    "Cancel_project" = Action.Cancel_project,
    "Invite_someone" = Action.Invite_someone,
    "Assign_to" = Action.Assign_to,
    "Send_for_security_audit" = Action.Send_for_security_audit,
    "Send_to_review" = Action.Send_to_review,
    "Accept_the_assessment" = Action.Accept_the_assessment,
    "Duplicate_the_project" = Action.Duplicate_the_project,
    "Reopen_the_project" = Action.Reopen_the_project,
    "Send_to_close" = Action.Send_to_close,
    "Change_Status" = Action.Change_Status,
    "Edit_Questionnaire" = Action.Edit_Questionnaire,
    "edit" = Action.Edit_Questionnaire,
    "reopen" = Action.Reopen_the_project,
    "Toggle_ShadowIt" = Action.Toggle_ShadowIt
}

export enum SensitiveDataIds {
    Other = 9,
}

export enum DataTypeBU {
    Other = 14,
}

export enum DataSubjectsType {
    Other = 9,
}

export enum PersonalDataRetained {
    Other = 5,
}

export enum StorageTermEnum {
    accessToken = "access_token",
    refreshToken = "refresh_token",
    userFullName = "user_full_name",
    userRoles = "user_roles",
    userName = "user_name"
}