import { toNumber } from "lodash";
import { FileTypesAccepted } from "../models/interfaces";

export const fileTypesAccepted: FileTypesAccepted = {
    document: {
        pdf: 'pdf',
        word_97: 'doc',
        word: 'docx',
        excel_97: 'xls',
        excel: 'xlsx',
    },
    image: {
        image_1: 'png',
        image_2: 'jpg',
        image_3: 'tif',
    },
    video: {
        video_1: 'mov',
        video_2: 'avi'
    }
};

export const MAX_FILE_SIZE = () => {

    let val: number = toNumber(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE);
    if(toNumber(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE) === undefined
        || isNaN(val)
        || toNumber(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE) === null) {
            // this is the default value for tests
            return 2;
    }
    else{
        return toNumber(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE);
    }
};