import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { Add, Mail, OpenInBrowser, Share, Undo } from '@material-ui/icons';
import { Collapse, Container, Grid } from "@mui/material";
import { Field, Formik, FormikHelpers } from "formik";
import moment from "moment";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Subscription } from "rxjs";
import * as yup from "yup";
import LoadingContext from "../../contexts/LoadingContext";
import { useNotificationContext, verifyIfThereIsAnyNotificationToChangeStatus } from "../../hooks/notificationHook";
import { useProjectContext } from "../../hooks/projectHook";
import { EmailDataFromDashboardDto, EmailService } from "../../services/emailService";
import { FileService } from "../../services/fileService";
import { EventHistory, FormService } from "../../services/formService";
import { ISendNote, NoteService, sendNoteValidationSchema } from "../../services/noteService";
import { ReferenceService } from "../../services/referenceService";
import { UiTextareaField, UiTextField } from "../../shared/components/fields";
import NextActions from "../../shared/components/nextActions/NextActions";
import {MAX_NOTE_CHARS, NotesTable} from "../../shared/components/note/NotesTable";
import UiBox from "../../shared/components/ui/UiBox";
import { UiModal } from "../../shared/components/ui/UiModal/UiModal";
import UiStatus from "../../shared/components/ui/UiStatus";
import { CustomFile } from "../../shared/components/upload/interfaces";
import Upload from "../../shared/components/upload/Upload";
import { notificationMessage, urlPartialDownload, urlPartialProgress, urlPartialUpload, UserGroup } from "../../shared/config/GlobalAppConfig";
import { fileTypesAccepted, MAX_FILE_SIZE } from "../../shared/config/uploadConfig";
import { baseCreateNotification } from "../../shared/helpers/HelpersFunc";
import LocalStorageTokenService from "../../shared/helpers/LocalStorageTokenService";
import { NotificationType, Status, StatusType } from "../../shared/models/enums";
import { Reference, User } from "../../shared/models/interfaces";
import './ProjectPage.scss';
import {getMappingRoles} from "../../shared/models/records";
import { CountCharacters } from "../../shared/components/countCharacters/CountCharacters";

interface StateProps {
    idStatus: number,
    usersList: User[],
    projectOwner: string,
}

interface ProjectOwnersProps {
    id: number
}

interface AssignedSecurityUserProps {
    id: number
}

export interface IShareProject {
    userEmail: string,
    idQuestionnaire: number
}

const shareProjectInitialValues: IShareProject =
{
    userEmail: "",
    idQuestionnaire: 0
};

export const shareProjectValidationSchema = yup.object().shape({
    userEmail: yup.string().required("This is a required field").nullable(),
    idQuestionnaire: yup.string().required("This is a required field"),
});

const sendNoteInitialValues: ISendNote =
{
    idQuestionnaire: 0,
    noteText: "",
    projectName: ""
};

interface ISendEmail {
    email: string,
    name: string,
    subject: string,
    message: string,
}

const sendEmailInitialValues: ISendEmail =
{
    email: "",
    name: "",
    subject: "",
    message: ""
};

const sendEmailValidationSchema = yup.object().shape({
    message: yup.string().required("This is a required field"),
    subject: yup.string().required("This is a required field"),
});

const Row = (props: EventHistory) => {
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <tr onClick={() => setOpen(!open)}>
                <td>{moment(props.modifiedDate).format('yyyy.MM.DD')} {moment(props.modifiedDate).format('HH:mm')}</td>
                <td>
                    <UiStatus
                        label={props.flowStatus}
                        name={props.flowStatus?.toLowerCase().replace(/ /g, '').replace('.', '') as StatusType} />
                </td>
                <td>{props.modifiedBy}</td>
                <td>{props.flowOwner ? props.flowOwner : ' - '}</td>
            </tr>
            <tr>
                <td style={{ paddingBottom: 0, paddingTop: 0, color: '#000' }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <table className="comments">
                            <tbody>
                                <tr>
                                    <td>Comment: </td>
                                    <td>{props.comment}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Collapse>
                </td>
            </tr>
        </React.Fragment>
    );
}

const ProjectOwners = forwardRef((props: ProjectOwnersProps, ref: any) => {
    const [open, setOpen] = React.useState(false);
    const [sendingMail, setSendingMail] = React.useState(false);
    const modalHandleOpen = (email:string, name: string) => {
        sendEmailInitialValues.email = email;
        sendEmailInitialValues.name = name;
        setOpen(true);
    }
    const modalHandleClose = () => setOpen(false);
    const [projectOwners, setProjectOwners] = useState<User[]>();
    const [projectOwnersSubscription, setProjectOwnersSubscription] = useState<Subscription>();
    const [loading, setLoading] = useState(false);

    const reloadProjectOwners = () => {
        setLoading(true);
        const projectOwnersSubscription = FormService.GetProjectOwners(props.id).subscribe((data: User[]) => {
            setProjectOwners(data);
            setLoading(false);
        });
        setProjectOwnersSubscription(projectOwnersSubscription);
    }

    useEffect(() => {
        reloadProjectOwners();
        return(() => {
            projectOwnersSubscription?.unsubscribe();
        })
    }, []);

    useImperativeHandle(ref, () => {
        return {
            reloadProjectOwners,
        }
    });

    const submit = function (values: ISendEmail, formProps: FormikHelpers<ISendEmail>) {
        setSendingMail(true);

        const emailDto: EmailDataFromDashboardDto = {
            email: values.email,
            emailContent: values.message,
            subject: values.subject,
            questionnaireId: props.id
        };

        EmailService.SendEmailFromDashboard(emailDto).subscribe((data) => {
            baseCreateNotification(!data ? NotificationType.success : NotificationType.error, !data ? "Email Sent" : "Error while sending the email");
            formProps.resetForm();
            modalHandleClose();
            setSendingMail(false);
        });
    }

    return (
        <>
            <div className="ProjectOwners">
                <div className="ProjectOwners__title">
                    <h3>Project Owners</h3>
                    {
                        loading &&
                            <CircularProgress size={25} />
                    }
                </div>
                <div className="profiles-container">
                    {
                        projectOwners?.map(user => {
                            return (
                                <div key={user.id} className="row profile-info">
                                    <img className="profile" src="/assets/img/user.png" alt="profile" />
                                    <label>{user.fullName}</label>
                                    <IconButton className="mail-button" onClick={() => modalHandleOpen(user.email, user.fullName)}> <Mail color="primary" /> </IconButton>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <Formik
                initialValues={sendEmailInitialValues}
                validationSchema={sendEmailValidationSchema}
                onSubmit={submit}
            >
                {formProps => (
                    <UiModal title={`Send an email to ${sendEmailInitialValues.name}`} open={open} onClose={modalHandleClose}>
                        <form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <Field name="subject" label="Subject" component={UiTextField} value={formProps.values.subject} />
                            </div>
                            <div className="row">
                                <Field name="message" label="Message" component={UiTextareaField} value={formProps.values.message} rows={7} />
                            </div>
                            <div className="ui-modal__footer ui-modal__footer--buttons">
                                <Button onClick={modalHandleClose} variant="outlined" color="primary" key="cancel">Cancel</Button>
                                <Button type="submit" variant="contained" color="primary" startIcon={sendingMail ? <CircularProgress size={20} /> : <Mail />} disabled={sendingMail}>
                                    Send
                                </Button>
                            </div>
                        </form>
                    </UiModal>
                )}
            </Formik>
        </>
    );
});

const AssignedSecurityUser = forwardRef((props: AssignedSecurityUserProps, ref: any) => {
    const [open, setOpen] = React.useState(false);
    const [sendingEmail, setSendingEmail] = React.useState(false);
    const modalHandleOpen = (email: string, name: string) => {
        sendEmailInitialValues.email = email;
        sendEmailInitialValues.name = name;
        setOpen(true);
    }
    const modalHandleClose = () => setOpen(false);
    const [assignedSecurityUser, setAssignedSecurityUser] = useState<User>();
    const [assignedSecurityUserSubscription, setAssignedSecurityUserSubscription] = useState<Subscription>();
    const [isLoading, setIsLoading] = useState(false);

    const reloadAssignedSecurityUser = () => {
        setIsLoading(true);
        const assignedSecurityUserSubscription = FormService.GetAssignedSecurityUser(props.id).subscribe((data: User) => {
            if (data.id) {
                setAssignedSecurityUser(data);
            }
            setIsLoading(false);
        });
        setAssignedSecurityUserSubscription(assignedSecurityUserSubscription);
    }

    useEffect(() => {
        reloadAssignedSecurityUser();
        return(() => {
            assignedSecurityUserSubscription?.unsubscribe();
        })
    }, []);

    useImperativeHandle(ref, () => {
        return {
            reloadAssignedSecurityUser,
        }
    });

    const submit = function (values: ISendEmail, formProps: FormikHelpers<ISendEmail>) {
        setSendingEmail(true);

        const emailDto: EmailDataFromDashboardDto = {
            email: values.email,
            emailContent: values.message,
            subject: values.subject,
            questionnaireId: props.id
        };

        EmailService.SendEmailFromDashboard(emailDto).subscribe((data) => {
            baseCreateNotification(!data ? NotificationType.success : NotificationType.error, !data ? "Email Sent" : "Error while sending the email");
            formProps.resetForm();
            modalHandleClose();
            setSendingEmail(false);
        });
    }

    return (
        <>
            <div className="ProjectOwners">
                <div className="ProjectOwners__title">
                    <h3>Assigned Security User</h3>
                    {
                        isLoading &&
                            <CircularProgress size={25} />
                    }
                </div>
                {
                    assignedSecurityUser
                    ? <div className="profiles-container">
                        <div key={assignedSecurityUser.id} className="row profile-info">
                            <img className="profile" src="/assets/img/user.png" alt="profile" />
                            <label>{assignedSecurityUser.fullName}</label>
                            <IconButton className="mail-button" onClick={() => modalHandleOpen(assignedSecurityUser.email, assignedSecurityUser.fullName)}> <Mail color="primary" /> </IconButton>
                        </div>
                    </div>
                    : <div className="profiles-container">
                        <div className="row profile-info">
                            <img className="profile" src="/assets/img/user.png" alt="profile" />
                            <label>Not assigned</label>
                        </div>
                    </div>
                }
            </div>
            <Formik
                initialValues={sendEmailInitialValues}
                validationSchema={sendEmailValidationSchema}
                onSubmit={submit}
            >
                {formProps => (
                    <UiModal title={`Send an email to ${sendEmailInitialValues.name}`} open={open} onClose={modalHandleClose}>
                        <form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <Field name="subject" label="Subject" component={UiTextField} value={formProps.values.subject} />
                            </div>
                            <div className="row">
                                <Field name="message" label="Message" component={UiTextareaField} value={formProps.values.message} rows={7} />
                            </div>
                            <div className="ui-modal__footer ui-modal__footer--buttons">
                                <Button onClick={modalHandleClose} variant="outlined" color="primary" key="cancel">Cancel</Button>
                                <Button type="submit" variant="contained" color="primary" startIcon={sendingEmail ? <CircularProgress size={20} /> : <Mail />} disabled={sendingEmail}>
                                    Send
                                </Button>
                            </div>
                        </form>
                    </UiModal>
                )}
            </Formik>
        </>
    );
});

export const ProjectPage = () => {
    const ctx = useContext(LoadingContext);
    const location = useLocation<StateProps>();
    const [usersList, setUsersList] = useState<User[]>([]);
    const [idStatus, setIdStatus] = useState<number>(0);
    const [projectOwner, setProjectOwner] = useState<string>("");
    const { id, projectName } = useParams<{ id: string, projectName: string }>();
    const [questionnaireHistory, setQuestionnaireHistory] = useState<EventHistory[]>();
    const [files, setFiles] = React.useState<CustomFile[]>([]);
    const [renderFiles, setRenderFiles] = useState(false);
    const notificationService = useNotificationContext();
    const projectService = useProjectContext();
    const [sendingNote, setSendingNote] = React.useState(false);
    const childRef = useRef();
    const [chosenSecurityAuditList, setChosenSecurityAuditList] = useState<string[]>();
    const [securityAuditList, setSecurityAuditList] = useState<Reference[]>();
    const [modalOpen, setModalOpen] = React.useState(false);
    const modalHandleClose = () => setModalOpen(false);
    const [adUsersInput, setAdUsersInput] = useState<string>('');
    const [adUsersList, setAdUsersList] = useState([])
    const [adUsersSubscription, setAdUsersSubscription] = useState<Subscription>()
    const [sendingInvitation, setSendingInvitation] = React.useState(false);
    const projectOwnersComponent = useRef();
    const assignedSecurityUserComponent = useRef();
    const history = useHistory();
    shareProjectInitialValues.idQuestionnaire = Number(id);

    const submitNote = function (values: ISendNote, formProps: FormikHelpers<ISendNote>) {
        setSendingNote(true);
        values.noteText = values.noteText.trim();
        values.idQuestionnaire = Number(id);
        NoteService.Add(values).subscribe(data => {
            baseCreateNotification(data ? NotificationType.success : NotificationType.error, data ? "Note Added" : "Error while adding note");
            formProps.resetForm();
            if(childRef && childRef.current)
            {
                //TODO change 'any' to a known type
                (childRef.current as any).reloadNotes();
            }
            setSendingNote(false);
        });
    }

    const share = () => {
        setModalOpen(true);
    }

    const submit = (values: any, formProps: FormikHelpers<IShareProject>) => {
        setSendingInvitation(true);
        if(!values.userEmail.id) {
            baseCreateNotification(NotificationType.error, notificationMessage.User_not_found.title, notificationMessage.User_not_found.message);
            setSendingInvitation(false);
            return;
        }
        values.userEmail = values.userEmail.id;
        FormService.inviteUser(values).subscribe(_data => {
            setSendingInvitation(false);
            formProps.resetForm();
            modalHandleClose();
            if (projectOwnersComponent) {
                (projectOwnersComponent.current as any).reloadProjectOwners();
            }
        });
    }

    const findUsers = function (userSearch: string) {
        setAdUsersInput(userSearch);
        if (userSearch.length > 2) {
            const tempSubscription = ReferenceService.GetListAdUsers(userSearch).subscribe(adUsersFilteredList => {
                setAdUsersList(adUsersFilteredList);
            });
            setAdUsersSubscription(tempSubscription);
        }
    }

    const handleAccessToQuestionnaire = function() {
        const userName = LocalStorageTokenService.getUserNameToken();
        const mode = (projectOwner === userName && idStatus === Status.draft) ? 'edit' : 'read';
        history.push(`/dashboard/selfAssessment/${mode}/${id}/${projectName}`, {secAuditList: chosenSecurityAuditList});
    }

    const handleAccessToDashboard = function() {
        history.push('/dashboard');
    }

    useEffect(() => {
        ctx.startLoading()

        if (!location.state) {
            const usersListSubscription = ReferenceService.GetSecurityTeamUsers().subscribe(users => {
                setUsersList(users);
            });

            const createdBySubscription = FormService.GetCreatedBy(Number(id)).subscribe(createdBy => {
                setProjectOwner(createdBy);
            })

            usersListSubscription.unsubscribe();
            createdBySubscription.unsubscribe();
        } else {
            setIdStatus(location.state.idStatus);
            setUsersList(location.state.usersList);
            setProjectOwner(location.state.projectOwner);
        }


        const historySubscription = FormService.getHistory(id).subscribe(questionnaireHistory => {
            const flowId = questionnaireHistory[questionnaireHistory.length - 1].idFlowStatus;
            setIdStatus(flowId);
            setQuestionnaireHistory(questionnaireHistory);
            if (flowId === Status.ongoing_security_audit) {
                const sendForAudit = questionnaireHistory.filter(qh => qh.idFlowStatus === Status.ongoing_security_audit);
                const securityAuditArray = sendForAudit[sendForAudit.length - 1].securityAudit?.split(',');
                setChosenSecurityAuditList(securityAuditArray);
            }
            ctx.stopLoading();
        });

        verifyIfThereIsAnyNotificationToChangeStatus(notificationService, projectService);

        return () => {
            historySubscription.unsubscribe();
            adUsersSubscription?.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        let securityAuditSubscription: Subscription | null = null;
        let idGroup = getMappingRoles(LocalStorageTokenService.getUserRoleToken());

        const filesSubscription = FileService.getAllDocuments(idGroup, Number(id)).subscribe(filesData => {
            setFiles(filesData);
            setRenderFiles(true);
            ctx.stopLoading();
        });

        securityAuditSubscription = ReferenceService.GetSecurityAuditList().subscribe(
            (securityAuditRef) => setSecurityAuditList(securityAuditRef)
            , () => {
            });

        return () => {
            securityAuditSubscription?.unsubscribe();
            filesSubscription.unsubscribe();
        }
    }, [idStatus])


    return (
        <div>
            <div className="readonly">
                <Container maxWidth="xl" className="content projectRead">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                        <Button
                                style={{marginBottom: 10}}
                                variant="contained"
                                color="primary"
                                onClick={handleAccessToDashboard}>
                                <Undo/>
                                Back to Dashboard
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Button
                                style={{marginBottom: 10}}
                                variant="contained"
                                color="primary"
                                onClick={handleAccessToQuestionnaire}>
                                <OpenInBrowser/>
                                See questionnaire
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} className="readonly__left">
                            <UiBox title="Project history" name="event-history" isChild={true}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Updated by</th>
                                            <th>Role</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            questionnaireHistory && questionnaireHistory.length > 0 ?
                                                questionnaireHistory.map((elemOfEventHistory: EventHistory) => {
                                                    elemOfEventHistory.comment = elemOfEventHistory.comment && elemOfEventHistory.comment.length ? elemOfEventHistory.comment : '-';
                                                    return <Row key={elemOfEventHistory.id} {...elemOfEventHistory} />
                                                })
                                                : <tr><td colSpan={5} style={{ textAlign: "center" }}>No status found</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </UiBox>

                            {
                                <UiBox title="Project notes" name="event-history">
                                    <>
                                        <Formik
                                            initialValues={sendNoteInitialValues}
                                            validationSchema={sendNoteValidationSchema}
                                            onSubmit={submitNote}
                                        >
                                            {formProps => (
                                                <form onSubmit={formProps.handleSubmit}>
                                                    <div className="row">
                                                        <Field name="noteText" label="Note" component={UiTextareaField} value={"noteText"} rows={3} inputProps={{maxLength:MAX_NOTE_CHARS}}/>
                                                    </div>
                                                    <CountCharacters noteText={formProps.values.noteText}></CountCharacters>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={sendingNote ? <CircularProgress size={20} /> : <Add />}
                                                        disabled={sendingNote}
                                                    >
                                                        Add note
                                                    </Button>
                                                </form>
                                            )}
                                        </Formik>
                                        <NotesTable ref={childRef} idQuestionnaire={id}  />
                                    </>
                                </UiBox>
                            }

                        </Grid>
                        <Grid item xs={12} md={4} className="declaration-right">
                            <UiBox title="Next actions" altStyle={true}>
                                {idStatus > 0 ?
                                    (<NextActions
                                            isSingleButton={false}
                                            enableEditButton={false}
                                            questionnaireId={id}
                                            enableSaveAndCloseButton={false}
                                            idStatus={idStatus}
                                            usersList={usersList}
                                            securityAuditList={securityAuditList}
                                            chosenSecurityAuditList={chosenSecurityAuditList}
                                        />
                                    )
                                    :
                                    <></>}
                            </UiBox>

                            {
                                LocalStorageTokenService.getUserRoleToken() !== UserGroup.SecurityTeam
                                    ? <AssignedSecurityUser ref={assignedSecurityUserComponent} id={Number(id)} />
                                    : <ProjectOwners ref={projectOwnersComponent} id={Number(id)} />
                            }

                            {
                                renderFiles &&
                                    <Upload
                                    title="Manage documents"
                                    fileTypesAccepted={fileTypesAccepted}
                                    maxFileSize={MAX_FILE_SIZE()}
                                    urlUpload={urlPartialUpload}
                                    urlProgress={urlPartialProgress}
                                    urlDownload={urlPartialDownload}
                                    questionnaireId={id}
                                    files={files}
                                    progressActivated={true}
                                />
                            }

                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
}
