import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import UiTooltipLabel from './UiTooltipLabel';
import NumberFormat from 'react-number-format';
import EuroIcon from '@material-ui/icons/Euro';
import UiFieldWrapper from './UiFieldWrapper';
import UiBaseField from './UiBaseField';
import { getFieldData } from './UiFieldHelpers';


interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            allowLeadingZeros={false}
        />
    );
}

export const UiAmountField = (props : UiBaseField)  => {
    const {error, name, value} = getFieldData(props);
    const label = UiTooltipLabel({ label: props.label, tooltip: props.tooltip })
    const [amount, setAmount] = React.useState<number>(value);
    useEffect(() => setAmount(Number(value)), [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(Number(event.target.value));
        if(props.form){
            props.form.setFieldValue(name, Number(event.target.value))
        }
        if(props.onChange){
            props.onChange(event, Number(event.target.value))
        }
    };

    return (
        <React.Fragment>
            <UiFieldWrapper name="ui-field-amount" {...props}>
                <TextField
                    fullWidth
                    label={label}
                    value={amount}
                    variant="outlined"
                    onChange={handleChange}
                    name={name}
                    error={error.hasError}
                    helperText={error.errorMessage}
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom as any,
                        startAdornment: (
                            <EuroIcon color="action" fontSize="small" />
                        ),
                    }}

                />
            </UiFieldWrapper>
        </React.Fragment>
    )
}
export default UiAmountField