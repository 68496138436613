import { AxiosRequestConfig } from "axios";
import "react-notifications/lib/notifications.css";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as yup from "yup";
import { urlPartialAddNote, urlPartialCountNotesByQuestionnaire, urlPartialDeleteNote, urlPartialGetNotesByQuestionnaire } from "../shared/config/GlobalAppConfig";
import { ApiCallingMethods } from "../shared/helpers/AxiosHelper";
import DataConnectionFactory from "../shared/helpers/DataConnectionFactory";
import { ApiResponse } from "../shared/models/interfaces";
import {MAX_NOTE_CHARS} from "../shared/components/note/NotesTable";

export interface ISendNote {
    id?: number
    noteText: string
    idQuestionnaire: number
    createdBy?: string
    modifiedBy?: string
    createdDate?: Date
    modifiedDate?: Date
    projectName: string
    createdByFullName?: string
}

export const sendNoteValidationSchema = yup.object().shape({
  noteText: yup.string().trim().required("This is a required field").max(MAX_NOTE_CHARS)
});

export class NoteService {

  static Add(note: ISendNote) {
    const options: AxiosRequestConfig = {
      method: ApiCallingMethods.post,
      url: urlPartialAddNote,
      data: note,
    };

    const res = DataConnectionFactory.request(options).pipe(
      map((response: ApiResponse<any>) => {
        return response.data.model;
      })
    );
    return res;
  }

  static Update(note: ISendNote) {
    const options: AxiosRequestConfig = {
      method: ApiCallingMethods.put,
      url: urlPartialAddNote,
      data: note,
    };

    const res = DataConnectionFactory.request(options).pipe(
      map((response: ApiResponse<any>) => {
        return response.data.model;
      })
    );
    return res;
  }

  static Delete(id?: number) {
    const options: AxiosRequestConfig = {
      method: ApiCallingMethods.delete,
      url: `${urlPartialDeleteNote}/${id}`,
    };

    const res = DataConnectionFactory.request(options).pipe(
      map((response: ApiResponse<any>) => {
        return response.data.model;
      })
    );
    return res;
  }

  static GetByQuestionnaire(id: string): Observable<ISendNote[]> {
    const options : AxiosRequestConfig = {
        method: ApiCallingMethods.get,
        url: `${urlPartialGetNotesByQuestionnaire}/${id}`
    };
    
    const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<ISendNote[]>) => {
        return response.data.model;
    }));
    return res;
  }

  static CountByQuestionnaire(id: string): Observable<number> {
    const options : AxiosRequestConfig = {
        method: ApiCallingMethods.get,
        url: `${urlPartialCountNotesByQuestionnaire}/${id}`
    };
    
    const res =  DataConnectionFactory.request(options).pipe(map((response: ApiResponse<number>) => {
        return response.data.model;
    }));
    return res;
  }
}
