import { AxiosRequestConfig } from 'axios';
import "react-notifications/lib/notifications.css";
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { urlPartialAssignTo, urlPartialChangeStatus, urlPartialGetAllUsersInvolved, urlPartialGetDashboard, urlPartialGetStatusList, urlPartialGetKPIs, urlPartialGetAllUsersInvolvedInQuestionnaires, urlPartialExportQuestionnaires } from '../shared/config/GlobalAppConfig';
import { ApiCallingMethods } from '../shared/helpers/AxiosHelper';
import DataConnectionFactory from '../shared/helpers/DataConnectionFactory';
import LocalStorageTokenService from '../shared/helpers/LocalStorageTokenService';
import { ApiResponse, NextActionBehavior, Pagination, Reference, User } from '../shared/models/interfaces';
import { getMappingRoles } from '../shared/models/records';
import "react-notifications/lib/notifications.css";
import { IDashboardItem } from '../screens/dashboard/Dashboard';

export interface KPIs {
    draft: number,
    assigned: number,
    inWaiting: number,
    done: number
}

export interface DashboardRequestBody {
    pageNumber?: number,
    pageSize?: number,
    orderBy?: number,
    orderDir?: number,
    assignedToMe?: boolean,
    thirdPartyList?: string | null,
    usernameList?: string | null,
    statusList?: string | null,
    searchText?: string | null,
}
export interface StatusItem {
    idStatus: number,
    name: string
}

export const projectData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

export class DashboardService {
    static getDashboard(body: DashboardRequestBody) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialGetDashboard,
            data: body ?? null
        };

        const res = DataConnectionFactory.request(options).pipe(map((response: ApiResponse<Pagination<IDashboardItem>>) => {
            response.data.model.results = response.data.model.results.map((di: IDashboardItem) => {
                return {
                    ...di,
                    notesCount: -1
                };
            });
            return response.data.model;
        }));
        return res;
    }

    static exportQuestionnaires(body: DashboardRequestBody) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialExportQuestionnaires,
            data: body ?? null
        };

        const res = DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.model;
        }));
        return res;
    }

    static getAllUsersInvolvedInQuestionnaires() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetAllUsersInvolvedInQuestionnaires,
            data: null
        };

        return DataConnectionFactory.request(options).pipe(map((response: ApiResponse<User[]>): Reference[] => {
            return response.data.model.map(tp => {
                return {
                    id: tp.id,
                    itemContent: tp.fullName,
                    itemtKey: tp.email
                };
            });
        }));
    }

    static assignTo(email: string, questionnaireId: number) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: urlPartialAssignTo,
            data: {
                email: email,
                selfAssessmentQuestionnaireId: questionnaireId
            }
        };

        const res = DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.model;
        }));
        return res;
    }

    static getStatusList(questionnaireId: number) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetStatusList}/${questionnaireId}`,
            data: null
        };

        const res = DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => {
            return response.data.model;
        }));
        return res;
    }

    static changeStatus(changeStatusBodyReq: NextActionBehavior) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialChangeStatus,
            data: changeStatusBodyReq
        };

        const res = DataConnectionFactory.request(options).pipe(map((response: ApiResponse<StatusItem[]>) => {
            return response.data.model;
        }));
        return res;
    }

    static getAllUserInvolved() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetAllUsersInvolved,
            data: null
        };

        return DataConnectionFactory.request(options).pipe(map((response: ApiResponse<User[]>): Reference[] => {
            return response.data.model.map(tp => {
                return {
                    id: tp.id,
                    itemContent: tp.fullName,
                    itemtKey: tp.email
                };
            });
        }));
    }

    static getKPIs() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetKPIs,
            data: null
        };

        const res = DataConnectionFactory.request(options).pipe(map((response: ApiResponse<KPIs>) => {
            return response.data.model;
        }));
        return res;
    }
}