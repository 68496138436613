import { Grid } from '@mui/material';
import React from 'react';
import { AvatarWithText, Person } from '../avatar/AvatarWithText';

export interface Team {
    name: string
    teamMembers: Person[]
}

export const Teams = function({ data } : { data: Team[] }) {
    return (
        <div className="team-intro">
            {
                data.map(team => (
                    <Grid key={team.name} container spacing={2}>
                        <Grid item xs={12}>
                            <h2>{team.name}</h2>
                        </Grid>
                        {team.teamMembers.map(member => (
                            <Grid key={member.name} item xs={4}>
                                <AvatarWithText person={member}/>
                            </Grid>
                        ))}
                    </Grid>
                ))
            }
        </div>
    )
}