import { Button, Container } from '@material-ui/core';
import { Save, SaveAltOutlined, SaveSharp } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import WbCloudy from '@material-ui/icons/WbCloudy';
import { Divider } from '@mui/material';
import { Field, Formik } from 'formik';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Subscription } from 'rxjs';
import LoadingContext from '../../contexts/LoadingContext';
import { FlowRefService } from '../../services/flowRefService';
import { FormService } from '../../services/formService';
import { ReferenceService } from '../../services/referenceService';
import { ContactUs } from '../../shared/components/contactUs/contactUs';
import { Files } from '../../shared/components/files/Files';
import NextActions, { NextActionOption } from '../../shared/components/nextActions/NextActions';
import UiBox from '../../shared/components/ui/UiBox';
import { UiCollapsable } from '../../shared/components/ui/UiCollapsable';
import { notificationMessage } from '../../shared/config/GlobalAppConfig';
import { baseCreateNotification } from '../../shared/helpers/HelpersFunc';
import { Action, FlowStatus, NotificationType, Status } from '../../shared/models/enums';
import { ApiResponse, CommonProperties, NextActionBehavior, Reference, User } from '../../shared/models/interfaces';
import DataInformation, { IDataInformation } from './components/DataInformation';
import DigitalApplication, { IDigitalApplication } from './components/DigitalApplication';
import { GeneralInformation, IGeneralInformation } from './components/GeneralInformation';
import { Hosting, IHosting } from './components/Hosting';
import ThirdParties, { IThirdPartyInformation, newThirdPartyInformation } from './components/ThirdParty';
import { digitalApplicationInitialValues, selfAssessmentQuestionnaireInitialValues } from './models/initialValues';
import { saveValidationSchema, submitValidationSchema } from './models/validationSchemas';

export interface ISelfAssessmentQuestionnaire extends CommonProperties {
    generalInformation: IGeneralInformation,
    isThereAnyThirdPartyInvolved: boolean,
    thirdPartyNameInvolved: string,
    thirdParties: IThirdPartyInformation[] | null,
    thirdPartiesSaved?: IThirdPartyInformation[],
    isThereAnyDigitalApplicationInvolved: boolean,
    digitalApplication: IDigitalApplication | null,
    hosting: IHosting | null,
    dataInformation: IDataInformation,
    isShadowIT?: boolean
}

export interface ISelfAssessmentQuestionnaireDTO extends ISelfAssessmentQuestionnaire {
    projectName: string
}

export type Mode = 'edit';
export type Save = 'saveAsDraft' | 'submit';

interface MatchParams {
    id: string,
    mode: Mode
}

interface StateProps {
    idStatus: number,
    usersList: User[]
}

interface HistoryState {
    status: Status
}

interface Props extends RouteComponentProps<MatchParams> {
    history: History<HistoryState>
}

const thirdPartyPopupContent = (
    <div>Any external vendors that are involved in delivering this project (e.g. Saas providers, external web agency, external consultants, etc)
        It is possible to add several third parties.</div>
)

const digitalApplicationPopupContent = (
    <div>Any computer program, developed to carry out specific operations and that can be used by a computer, mobile device, or tablet
        (e.g. CRM software, Point of sale, Automation software, etc)</div>
)

const SelfAssessmentQuestionnaire = (props: Props) => {
    const [validationSchema, setValidationSchema] = useState({});
    const [saveType, setSaveType] = useState<Save>('submit');
    const [isReadOnly, setIsReadOnly] = useState<boolean>();
    const { match, history } = props;
    const ctx = React.useContext(LoadingContext);
    const [countriesList, setCountriesList] = useState<Reference[]>([])
    const [values, setValues] = useState(selfAssessmentQuestionnaireInitialValues);
    const [securityAuditList, setScurityAuditList] = useState<Reference[]>();
    const [chosenSecurityAuditList, setChosenSecurityAuditList] = useState<string[]>();
    const [usersList, setUsersList] = useState<User[]>([]);
    const [usersListv2, setUsersListv2] = useState([]);
    const [actionsList, setActionsList] = useState<NextActionOption[]>([]);
    const [statusId, setStatusId] = useState<number>();
    const [saveWithoutQuit, setSaveWithoutQuit] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const submitSuccess = function (selfAssessmentQuestionnaire: ISelfAssessmentQuestionnaireDTO) {
        history.push(saveWithoutQuit ? `/dashboard/selfAssessment/edit/${selfAssessmentQuestionnaire.id}/${selfAssessmentQuestionnaire.projectName}` : '/dashboard');
        let saveTypeTitle = saveType === 'submit' ? notificationMessage.Form_submitted.title : notificationMessage.Form_save.title;
        let saveTypeMessage = saveType === 'submit' ? notificationMessage.Form_submitted.message : notificationMessage.Form_save.message;

        //Dans le cas ou on crée un questionnaire et qu'on save sans quit on setReload a true pour trigger le useEffect
        if (saveWithoutQuit && values.id === undefined) {
            setReload(saveWithoutQuit)
        }

        baseCreateNotification(NotificationType.success, saveTypeTitle, saveTypeMessage);
    }

    const submit = function (values: ISelfAssessmentQuestionnaire) {
        values.digitalApplication = { ...values.digitalApplication, ...values.hosting } as IDigitalApplication
        let nextAction: NextActionBehavior;
        if (saveType === 'saveAsDraft') {
            nextAction = {
                idNextTask: FlowStatus.draft
            }
        } else {
            if (actionsList.length) {
                const action = actionsList.filter(action => action.nextAction === FlowStatus.new)[0]
                nextAction = {
                    idFlowActions: action.id,
                    idFlowStatus: statusId,
                    idNextTask: action.nextAction
                }
            }
            else {
                nextAction = { idNextTask: FlowStatus.new }
            }
        }
        ctx.startLoading();
        if (match.params.mode === 'edit') {
            FormService.update(values, nextAction, Number(match.params.id)).subscribe(
                (newValues) => {
                    setValues(newValues);
                    submitSuccess(newValues as ISelfAssessmentQuestionnaireDTO);

                    ctx.stopLoading();
                },
                (error) => {
                    if (error.status === 400 && nextAction.idNextTask === FlowStatus.new) {
                        history.push('/dashboard');
                        baseCreateNotification(NotificationType.warning, notificationMessage.ErrorGenericMessage.title, error.data?.errorMessage ?? notificationMessage.ErrorGenericMessage.message);
                    }
                    else {
                        baseCreateNotification(NotificationType.error, notificationMessage.ErrorGenericMessage.title, error?.data?.errorMessage ?? notificationMessage.ErrorGenericMessage.message);
                        ctx.stopLoading()
                    }
                    ctx.stopLoading()
                },
                () => ctx.stopLoading()
            )
        }
        else {
            FormService.submitForm(values, nextAction).subscribe((response: ApiResponse<any>) => {
                if (response.data?.didError) {
                    baseCreateNotification(NotificationType.error, notificationMessage.ErrorGenericMessage.title, notificationMessage.ErrorGenericMessage.message);
                    ctx.stopLoading()
                }
                else {
                    submitSuccess(response.data.model as ISelfAssessmentQuestionnaireDTO);
                }
            }, (error) => {
                ctx.stopLoading();
                if (error.status === 400) {
                    history.push('/dashboard');
                    baseCreateNotification(NotificationType.warning, notificationMessage.ErrorGenericMessage.title, error?.data?.errorMessage ?? notificationMessage.ErrorGenericMessage.message);
                }
                else {
                    baseCreateNotification(NotificationType.error, notificationMessage.ErrorGenericMessage.title, error?.data?.errorMessage ?? notificationMessage.ErrorGenericMessage.message);
                    ctx.stopLoading()
                }
                ctx.stopLoading()
            },
                () => ctx.stopLoading());
        }
    }

    const handleThirdPartyToggle = function (
        el: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
        thirdPartiesSaved: IThirdPartyInformation[] | undefined
    ) {
        setFieldValue('isThereAnyThirdPartyInvolved', el.target.checked);

        if (!el.target.checked && thirdPartiesSaved && thirdPartiesSaved.length) {
            const tpsDeleted = thirdPartiesSaved.map(tp => {
                tp.isDeleted = true;
                tp.thirdParty = tp.thirdParty;
                tp.tasks = typeof tp.tasksSaved === 'string' ? [] : tp.tasksSaved;
                return tp;
            });
            setFieldValue('thirdPartiesSaved', tpsDeleted);
        }

        setFieldValue('thirdParties', el.target.checked ? [Object.assign({}, newThirdPartyInformation)] : null);
    }

    const handleDigitalAppToggle = function (
        el: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
        digitalApplication: IDigitalApplication | null) {
        setFieldValue('isThereAnyDigitalApplicationInvolved', el.target.checked);

        if (!el.target.checked && digitalApplication) {
            if (digitalApplication.id) {
                const newDigitalApplication = Object.assign({}, digitalApplicationInitialValues);
                newDigitalApplication.id = digitalApplication.id;

                if (digitalApplication.programmingLanguagesSaved?.length) {
                    const newProgrammingLanguagesSaved = digitalApplication.programmingLanguagesSaved.map(pl => {
                        pl.isDeleted = true;
                        return pl;
                    });
                    newDigitalApplication.programmingLanguagesSelected = newProgrammingLanguagesSaved;
                    newDigitalApplication.programmingLanguagesSaved = newProgrammingLanguagesSaved;
                }

                if (digitalApplication.appTypesSaved?.length) {
                    const newAppTypes = digitalApplication.appTypesSaved.map(at => {
                        at.isDeleted = true;
                        return at;
                    });

                    newDigitalApplication.appTypesSelected = newAppTypes;
                    newDigitalApplication.appTypesSaved = newAppTypes;
                }

                if (digitalApplication.scopesSaved?.length) {
                    const newScopesSaved = digitalApplication.scopesSaved.map(s => {
                        s.isDeleted = true;
                        return s;
                    });

                    newDigitalApplication.scopesSaved = newScopesSaved;
                    newDigitalApplication.scopesSelected = newScopesSaved;
                }

                setFieldValue('digitalApplication', newDigitalApplication);
            }
            else {
                setFieldValue('digitalApplication', null);
            }
        }

        if (el.target.checked) {
            if (digitalApplication) {
                digitalApplication.isDeleted = false;
            }
            else {
                digitalApplication = Object.assign({}, digitalApplicationInitialValues);
            }

            setFieldValue('digitalApplication', digitalApplication);
        }
    }

    const checkValidationSchema = function (saveType: Save, saveWithoutQuit: boolean) {
        setSaveWithoutQuit(saveWithoutQuit)
        setSaveType(saveType);
        if (saveType !== 'submit') {
            setValidationSchema(saveValidationSchema);
        }
        else {
            setValidationSchema(submitValidationSchema);
        }
    }

    const cancel = function () {
        history.push('/dashboard');
    }

    const handleStepClick = function (stepNumber: number) {
        const stepElement = document.getElementById(`step-${stepNumber}`);

        if (stepElement) {
            const headerOffset = 45;
            const elementPosition = stepElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    }, [validationSchema])

    useEffect(() => {
        const id = match.params.id;
        let countriesSubscription: Subscription | null = null;
        let nextActionsSubscription: Subscription | null = null;

        if (id) {
            ctx.startLoading();

            if (!history.location.state || !history.location.state.status) {
                FormService.getLastStatus(id).subscribe(lastStatus => {
                    setStatusId(lastStatus.idFlowStatus)
                    setIsReadOnly(lastStatus.idFlowStatus > Status.draft);

                    if (lastStatus.idFlowStatus === Status.draft) {
                        nextActionsSubscription = FlowRefService.GetNextActions(lastStatus.idFlowStatus, id).subscribe(nextActions => {
                            const actionsList = nextActions?.map(action => {
                                return {
                                    id: action.idFlowActions,
                                    name: action.flowAction,
                                    label: action.flowAction,
                                    nextAction: action.idNextTask,
                                    idFlowStatus: action.id
                                }
                            }) as NextActionOption[];
                            setActionsList(actionsList)
                        })
                    }
                })

            }

            const subscription = FormService.getForm(id).subscribe((res) => {
                ctx.stopLoading();
                setValues(res.selfAssessmentQuestionnaire);
                setCountriesList(res.countries);
            }, (error) => {
                baseCreateNotification(NotificationType.error, notificationMessage.ErrorGenericMessage.title, error.data?.errorMessage);
                history.push('/dashboard');
            })

            const usersListSubscription = ReferenceService.GetSecurityTeamUsers().subscribe(users => {
                setUsersList(users);
                const newUsersList = users.map((user: User, index: number): Reference => {
                    return {
                        id: index,
                        itemtKey: user.email,
                        itemContent: user.fullName,
                        displayOrder: index,
                        isVisible: true,
                        itemContent2: ''
                    }
                })

                setUsersListv2(newUsersList);
            })

            return () => {
                subscription.unsubscribe();
                countriesSubscription?.unsubscribe();
                usersListSubscription?.unsubscribe();
                nextActionsSubscription?.unsubscribe();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    return (
        <Formik
            onSubmit={submit}
            enableReinitialize={true}
            initialValues={values}
            validationSchema={validationSchema}
            validateOnChange={false}
        >
            {({ values, handleSubmit, setFieldValue }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="wizard">
                            <div className="step" onClick={() => handleStepClick(1)}>
                                <div className="number number__selected"><span>1</span></div>
                                <div className="step-name">General Information</div>
                            </div>
                            <div className="line">
                                <Divider className="dash" />
                            </div>
                            <div className="step" onClick={() => handleStepClick(2)}>
                                <div className="number"><span>2</span></div>
                                <div className="step-name">Third Party</div>
                            </div>
                            <div className="line">
                                <Divider className="dash" />
                            </div>
                            <div className="step" onClick={() => handleStepClick(3)}>
                                <div className="number"><span>3</span></div>
                                <div className="step-name">Hosting & Interconnections</div>
                            </div>
                            <div className="line">
                                <Divider className="dash" />
                            </div>
                            <div className="step" onClick={() => handleStepClick(4)}>
                                <div className="number"><span>4</span></div>
                                <div className="step-name">Digital Application</div>
                            </div>
                            <div className="line">
                                <Divider className="dash" />
                            </div>
                            <div className="step" onClick={() => handleStepClick(5)}>
                                <div className="number"><span>5</span></div>
                                <div className="step-name">Data Information</div>
                            </div>
                        </div>
                        <UiBox title="" headerClassnames="selfassessment-header">
                            <Container maxWidth="xl" className="content declaration">
                                <UiCollapsable
                                    id="step-1"
                                    withNav={true}
                                    number={1}
                                    title="General Information">
                                    <Field
                                        name="generalInformation"
                                        disable={isReadOnly}
                                        countriesList={countriesList}
                                        component={GeneralInformation} />
                                </UiCollapsable>
                                <UiCollapsable
                                    id="step-2"
                                    withNav={true}
                                    number={2}
                                    title="Third Party"
                                    tooltipText="What is a 3rd party?"
                                    tooltip="vendor/supplier would be better than third parties for the chinese market"
                                    showToggle={true}
                                    toggleName={'isThereAnyThirdPartyInvolved'}
                                    toggleText={'Is there any third party involved in this project?'}
                                    showPopup={true}
                                    popupTitle={'Third party'}
                                    popupContent={thirdPartyPopupContent}
                                    onToggleClick={el => handleThirdPartyToggle(el, setFieldValue, values.thirdPartiesSaved)}
                                    disabled={isReadOnly}>
                                    <Field
                                        name="thirdParties"
                                        isThereThirdParty={values.isThereAnyThirdPartyInvolved}
                                        disable={isReadOnly}
                                        thirdPartiesList={values.thirdParties}
                                        component={ThirdParties} />
                                </UiCollapsable>
                                <UiCollapsable
                                    id="step-3"
                                    withNav={true}
                                    number={3}
                                    title="Hosting & Interconnections">
                                    <Field
                                        name="hosting"
                                        countriesList={countriesList}
                                        isDisabled={isReadOnly}
                                        component={Hosting} />
                                </UiCollapsable>
                                <UiCollapsable
                                    id="step-4"
                                    withNav={true}
                                    number={4}
                                    title="Digital Application"
                                    showToggle={true}
                                    tooltipText="What is a digital application?"
                                    toggleName="isThereAnyDigitalApplicationInvolved"
                                    toggleText="Is there a digital application involved in the project?"
                                    showPopup={true}
                                    popupTitle={'Digital Application'}
                                    popupContent={digitalApplicationPopupContent}
                                    onToggleClick={el => handleDigitalAppToggle(el, setFieldValue, values.digitalApplication)}
                                    disabled={isReadOnly}>
                                    <Field
                                        name="digitalApplication"
                                        isDisabled={isReadOnly}
                                        countriesList={countriesList}
                                        isThereAnyDigitalApplicationInvolved={values.isThereAnyDigitalApplicationInvolved}
                                        component={DigitalApplication} />
                                </UiCollapsable>

                                <UiCollapsable
                                    id="step-5"
                                    withNav={true}
                                    number={5}
                                    hideDivider={true}
                                    title="Data Information">
                                    <Field
                                        name="dataInformation"
                                        disabled={isReadOnly}
                                        component={DataInformation} />
                                </UiCollapsable>
                                <div className="actions-section">

                                    <UiBox
                                        isChild={true}
                                        title="Is there any documents about this project that you would like to share with us?"
                                    >
                                        <Files id={Number(match.params.id)} />
                                    </UiBox>

                                    {!isReadOnly && <div style={{ width: '100%' }}>
                                        <UiBox
                                            title="Next actions"
                                            altStyle={true}
                                        >
                                            <>
                                                <Button
                                                    startIcon={<CancelIcon />}
                                                    style={{ margin: '0 5px' }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={cancel}>
                                                    Cancel
                                                </Button>
                                                <Button
                                                    id="save"
                                                    className="btn-white"
                                                    style={{ margin: '0 5px' }}
                                                    type="submit"
                                                    startIcon={<SaveSharp />}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => checkValidationSchema('saveAsDraft', true)}>
                                                    Save
                                                </Button>
                                                <Button
                                                    id="save"
                                                    className="btn-white"
                                                    style={{ margin: '0 5px' }}
                                                    type="submit"
                                                    startIcon={<WbCloudy />}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => checkValidationSchema('saveAsDraft', false)}>
                                                    Save & Close
                                                </Button>
                                                <Button
                                                    id="submit"
                                                    className="btn-submit"
                                                    style={{ margin: '0 5px' }}
                                                    type="submit"
                                                    startIcon={<CheckCircle />}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => checkValidationSchema('submit', false)}>
                                                    Submit questionnaire
                                                </Button>
                                            </>
                                        </UiBox>
                                    </div>}
                                    {
                                        isReadOnly &&
                                        <div style={{ width: '100%' }}>
                                            <UiBox title="Next actions" altStyle={true} >
                                                <NextActions
                                                    isSingleButton={false}
                                                    enableEditButton={false}
                                                    questionnaireId={match.params.id}
                                                    enableSaveAndCloseButton={false}
                                                    idStatus={statusId}
                                                    usersList={usersList}
                                                    securityAuditList={securityAuditList}
                                                    chosenSecurityAuditList={chosenSecurityAuditList}
                                                />
                                            </UiBox>
                                        </div>
                                    }
                                </div>

                                <div className="contact-us">
                                    <ContactUs />
                                </div>
                            </Container >
                        </UiBox >
                    </form >
                )
            }}
        </Formik >
    )
}

export default SelfAssessmentQuestionnaire;