import { ReactText } from "react";
import { NotificationManager } from 'react-notifications';
import { Download } from "../models/interfaces";
import { truncateMaxCharNb, truncateText } from "../config/GlobalAppConfig";
import { notificationDisplayTime, notificationMessage } from '../config/GlobalAppConfig'
import { Action, NotificationType } from '../models/enums'
import { IAppType, Scope } from "../../screens/selfAssessmentQuestionnaire/components/DigitalApplication";
import { ProgrammingLanguage } from "../../services/formService";
import { IUiMultiselectOption } from "../components/fields/UiAutocompleteField";
import { FieldInputProps } from "formik";

// function mergeSchemas(...schemas: Array<Yup.ObjectSchema<object>>) {
export function MergeSchemas(...schemas: any[]) {
    const [first, ...rest] = schemas;

    const merged = rest.reduce(
        (mergedSchemas, schema) => mergedSchemas.concat(schema),
        first
    );
    return merged;
}



export const ArrayUtils = {
    getUniqueBySingleKey(arr: any[], index: any) {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter((e: any) => arr[e]).map((e: any) => arr[e]);

        return unique;
    },
}

export const TruncateUtils = {
    truncateText(textValue: string) {
        if (textValue !== undefined && textValue.length > truncateMaxCharNb) {
            return textValue.slice(0, truncateMaxCharNb) + truncateText;
        }
        else {
            return textValue;
        }
    }
}

// export function ObjectUtils<T>(value: T){
export const ObjectUtils = {
    getInterfacePropertyValue: (objData: any, key: any) => {
        const indexKey = Object.keys(objData).indexOf(key);
        return Object.entries(objData)[indexKey][1];
    },
    getInterfaceProperties: (objInterface: any) => {
        let propsName: string[] = [];
        Object.keys(objInterface).forEach(
            (key) => propsName.push(key)
        );
        return propsName;
    },
}

export const numberScaleFormat = (amount: number): ReactText => {
    let divider: number = 1;
    let symbol: string = '';

    amount = Math.abs(amount);

    // Billions
    if (amount >= 1.0e+9) {
        divider = 1.0e+9;
        symbol = 'B';
    }
    // Millions
    else if (amount >= 1.0e+6) {
        divider = 1.0e+6;
        symbol = 'M';
    }
    // Thousands
    else if (amount >= 1.0e+3) {
        divider = 1.0e+3;
        symbol = 'K';
    }

    const result: number = Number((amount / divider).toFixed(1));

    return result.toString().concat(symbol);
}


export const sortArrayObject = (list: any, ascending: boolean, prop: string) => {
    if (list.length) {
        if (!isNaN(list[0][prop])) {
            (ascending) ? list.sort((a: any, b: any) => a[prop] - b[prop]) : list.sort((a: any, b: any) => b[prop] - a[prop]);
        }
        else {
            (ascending) ?
                list.sort((a: any, b: any) => a[prop] > b[prop] ? -1 : a[prop] < b[prop] ? 1 : 0) :
                list.sort((a: any, b: any) => a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0);
        }
    }
}

export const EnumUtils = {

    getEnumKeyByEnumValue: (myEnum: any, enumValue: any) => {
        let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
        return keys.length > 0 ? keys[0] : null;
    },

    getEnumValueByEnumKey: (myEnum: any, enumKey: any) => {
        return myEnum[enumKey];
    },

    getMapKeyByEnumValue: (myMap: Map<any, any>, enumValue: any) => {
        let keys: string = '';
        myMap.forEach((value: any, key: any) => {
            if (value === enumValue) {
                keys = key;
            }
        })
        return keys.length > 0 ? keys : null;
    },

    convertStringToEnum<T extends string, TEnumValue extends string>(enumVariable: { [key in T]: TEnumValue }, strValue: string | undefined): TEnumValue {
        const enumValues = Object.values(enumVariable);
        if (strValue !== undefined && enumValues.includes(strValue)) {
            return enumVariable[strValue];
        }
        else {
            return (enumValues[0] as TEnumValue);
        }
    },
}


export const formatBytes = (bytes: number) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}

export const isDateInRange = (startDate: Date, endDate: Date, dateToCheck: Date): boolean => {
    return isDateSuperiorOrEqualTo(startDate, dateToCheck) && isDateLowerOrEqualTo(endDate, dateToCheck)
}

export const isDateSuperiorOrEqualTo = (referenceDate: Date, dateToCompare: Date) => {
    //Avoid error in date comparison because of hours.
    referenceDate.setHours(0, 0, 0, 0)
    dateToCompare.setHours(0, 0, 0, 0)
    return (dateToCompare >= referenceDate)
}

export const isDateLowerOrEqualTo = (referenceDate: Date, dateToCompare: Date) => {
    //Avoid error in date comparison because of hours.
    referenceDate.setHours(0, 0, 0, 0)
    dateToCompare.setHours(0, 0, 0, 0)
    return (dateToCompare <= referenceDate)
}

export const createNotification = (type: NotificationType, idTask: number, customTitle: string = '', customMessage: string = '', duration : number = 5000) => {
    const { titleFormated, messageFormated } = formatMessage(type, idTask, customTitle, customMessage)
    switch (type) {
        case NotificationType.info:
            NotificationManager.info(messageFormated, titleFormated, notificationDisplayTime.info, duration);
            break;
        case NotificationType.success:
            NotificationManager.success(messageFormated, titleFormated, notificationDisplayTime.succes, duration);
            break;
        case NotificationType.warning:
            NotificationManager.warning(messageFormated, titleFormated, notificationDisplayTime.warning, duration);
            break;
        case NotificationType.error:
            NotificationManager.error(messageFormated, titleFormated, notificationDisplayTime.error, duration);
            break;
    }
};

export const baseCreateNotification = (type: NotificationType, customTitle: string = '', customMessage: string = '', duration : number = 5000) => {
    switch (type) {
        case NotificationType.info:
            NotificationManager.info(customMessage, customTitle, duration);
            break;
        case NotificationType.success:
            NotificationManager.success(customMessage, customTitle, duration);
            break;
        case NotificationType.warning:
            NotificationManager.warning(customMessage, customTitle, duration);
            break;
        case NotificationType.error:
            NotificationManager.error(customMessage, customTitle, duration);
            break;
    }
};

export const formatMessage = (type: NotificationType, idTask: number, customTitle: string = '', customMessage: string = '') => {
    let titleFormated = '';
    let messageFormated = '';
    if (type === NotificationType.success) {
        switch (idTask) {
            case Action.Complete_Review:
                titleFormated = notificationMessage.Complete_review.title;
                messageFormated = notificationMessage.Complete_review.message;
                break;
            case Action.Send_for_security_audit:
                titleFormated = notificationMessage.Send_for_security_audit.title;
                messageFormated = notificationMessage.Send_for_security_audit.message;
                break;
            case Action.Cancel_project:
                titleFormated = notificationMessage.Cancel_project.title;
                messageFormated = notificationMessage.Cancel_project.message;
                break;
            case Action.Assign_to:
                titleFormated = notificationMessage.Assign_to.title;
                messageFormated = notificationMessage.Assign_to.message;
                break;
            case Action.Duplicate_the_project:
                titleFormated = notificationMessage.Duplicate_the_project.title;
                messageFormated = notificationMessage.Duplicate_the_project.message;
                break;
            case Action.Invite_someone:
                titleFormated = notificationMessage.Invite_someone.title;
                messageFormated = notificationMessage.Invite_someone.message;
                break;
            case Action.Send_to_close:
                titleFormated = notificationMessage.Send_to_close.title;
                messageFormated = notificationMessage.Send_to_close.message;
                break;
            case Action.Toggle_ShadowIt:
                titleFormated = notificationMessage.ShadowIt_On.title;
                messageFormated = notificationMessage.ShadowIt_On.message;
                break;
            case Action.Send_to_review:
                titleFormated = notificationMessage.Sent_to_review.title;
                messageFormated = notificationMessage.Sent_to_review.message;
                break;
            case Action.Accept_the_assessment:
                titleFormated = notificationMessage.Accept_the_assessment.title;
                messageFormated = notificationMessage.Accept_the_assessment.message;
                break;
            case Action.Reopen_the_project:
                titleFormated = notificationMessage.Reopen_project.title;
                messageFormated = notificationMessage.Reopen_project.message;
                break;
        }
    } else if (type === NotificationType.error) {
        titleFormated = notificationMessage.ErrorGenericMessage.title;
        messageFormated = notificationMessage.ErrorGenericMessage.message;
    }
    return { titleFormated, messageFormated }
}

export const downloadFile = (object: Download, filename: string): void => {
    let mime = object.headers['content-type'];
    const url = window.URL.createObjectURL(
        new Blob([mime === 'application/json' ? JSON.stringify(object.data) : object.data],
            { type: mime }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export const StringFormat = (str: string, ...args: string[]) =>
    str.replace(/{(\d+)}/g, (match, index) => args[index] || ''
    );

export const ScrollToTopHelper = (val: number = 0) => {
    window.scrollTo(0, val);
};

type CustomSelectType = IAppType | Scope | ProgrammingLanguage;

const removeLastChar = (str: string): string => {
    const len = str.charAt(str.length - 1) === 's' ? str.length - 1 : str.length;
    return str.charAt(0).toUpperCase() + str.slice(1, len)
};

export const updateMultipleSelectedValues = function (
    values: Array<IUiMultiselectOption>,
    name: string,
    field: FieldInputProps<any>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    otherId?: number,
    otherValue?: string
) {
    const isArray = Array.isArray(field.value);
    const fieldname = isArray ? name.split('.')[2] : name.split('.')[1];
    const finalValues = isArray ? field.value[name.split('.')[1]][`${fieldname}Saved`]?.slice() ?? [] : field.value[`${fieldname}Saved`]?.slice() ?? [];
    const prop = `id${removeLastChar(fieldname)}`;
    let endingValues = values.map(value => {
        const valueFound = finalValues.find((fv: CustomSelectType, index: number) => {
            if (fv && fv[prop] === value.id) {
                finalValues.splice(index, 1);
                return true;
            }
            return false;
        });
        if (valueFound) {
            valueFound.isDeleted = false;
            return valueFound
        }
        const newObj = {};
        newObj[prop] = value.id;
        if(otherId && value.id === otherId) {
            newObj['other'] = otherValue;
        }
        return newObj;
    });

    endingValues = endingValues.concat(finalValues.map((e: CustomSelectType) => {
        e.isDeleted = true;
        return e;
    }));

    setFieldValue(`${name}Selected`, endingValues);
}