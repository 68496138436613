import { FormikProps } from "formik";
import { IUiFieldError } from "./UiBaseField";

const deep_value = (obj: any, path: string) =>
  path
    ?.replace(/\[|\]\.?/g, ".")
    .split(".")
    .filter((s) => s)
    .reduce((acc, val) => acc && acc[val], obj);

/**
 * Get Error of given field from FORMIK
 * @param field
 * @param form
 */
export const getError = (
  field?: any,
  form?: FormikProps<any>
): IUiFieldError => {
  const fieldError =
    form && form.errors ? deep_value(form?.errors, field.name) : false;
  if (field && form && fieldError) {
    return {
      hasError: true,
      errorMessage: fieldError,
    };
  } else {
    return {
      hasError: false,
      errorMessage: null,
    };
  }
};

/**
 * Make UiField work inside and outside FORMIK context
 */
export const getFieldData = (props: any) => {
  if (props.field && props.form) {
    // We are inside formik context

    // Si le composant <Field> a un prop onChange on l'utilise sinon on délègue le on change à formik
    const onChange = (event: any, value?: any) =>
      props.onChange
        ? props.onChange(event, props.field, value)
        : props.field.onChange(event);

    const error = getError(props.field, props.form);
    const { value, name } = props.field;
    return {
      error,
      name,
      value,
      onChange,
    };
  } else {
    // We are outside formik context
    const error = {
      hasError: props.error && props.error.length >= 1,
      errorMessage: props.error,
    };
    // ici onChange prend également un paramètre "value"
    // Dans le cas d'un champs non natif p.ex (Autocomplete multi select) la variable event ne contiendra pas la valeur du champs
    const onChange = (event: any, value?: any) =>
      props.onChange ? props.onChange(value ? value : event) : false;
    const { value, name } = props;
    return {
      error,
      name,
      value,
      onChange,
    };
  }
};
