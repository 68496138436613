
const getStringComparator = (sortKey:string, a:any, b:any, asc: boolean) => {
    if(asc){
        return a[sortKey].localeCompare(b[sortKey]);
    }else{

        return b[sortKey].localeCompare(a[sortKey]);

    }
}
const getNumericComparator = (sortKey:string, a:any, b:any, asc: boolean) => {
    if(asc){
        return a[sortKey] - b[sortKey]
    }else{
        return b[sortKey] - a[sortKey]
    }
}
export const getComparator = (sortKey: any, a:any, b:any, asc:boolean) =>{
    if(!a[sortKey] ){
        return 1
    }
    if(!b[sortKey]){
        return -1
    }
    
    if(typeof a[sortKey] === 'number'){
        return getNumericComparator(sortKey, a, b, asc)
    }else{
        return getStringComparator(sortKey,a, b, asc)
    }
}