import React from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import EditIcon from '@material-ui/icons/Create';

export const ListMenu = <T,>({list, onClick, prop}: {list : T[], onClick: (event: React.MouseEvent<any>, item: T) => void, prop: string}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: React.MouseEvent<any>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleMenuItemClick = (event: React.MouseEvent<any>, item: T) => {
        event.stopPropagation();
        setAnchorEl(null);
        onClick(event, item)
    }

    return (
        <>
            <IconButton
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <EditIcon color="primary" fontSize="small"/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {list.map((item: T, index: number) =>
                    <MenuItem key={`user-menu-item-${index}`} onClick={ev => handleMenuItemClick(ev, item)}>{item[prop]}</MenuItem>
                )}
            </Menu>
        </>
    )
}