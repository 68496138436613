import { AxiosRequestConfig } from 'axios';
import "react-notifications/lib/notifications.css";
import { map } from 'rxjs/operators';
import { IThirdParty } from '../screens/thirdParties/components/AddOrEditThirdPartyModal';
import { urlPartialAddThirdParty, urlPartialDeleteThirdParty, urlPartialGetThirdPartyDashboard, urlPartialUpdateThirdParty } from '../shared/config/GlobalAppConfig';
import { ApiCallingMethods } from '../shared/helpers/AxiosHelper';
import DataConnectionFactory from '../shared/helpers/DataConnectionFactory';
import { ApiResponse } from '../shared/models/interfaces';

export class ThirdPartyService {
    static getDashboard() {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetThirdPartyDashboard
        };

        return DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => response.data.model ));
    }

    static add(thirdParty: IThirdParty) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialAddThirdParty,
            data: thirdParty
        };
        
        return DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => response.data.model ));
    }

    static delete(id: number) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.delete,
            url: `${urlPartialDeleteThirdParty}/${id}`
        };

        return DataConnectionFactory.request(options);
    }

    static update(thirdParty: IThirdParty) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: urlPartialUpdateThirdParty,
            data: thirdParty
        };

        return DataConnectionFactory.request(options).pipe(map((response: ApiResponse<any>) => response.data.model ));
    }
}