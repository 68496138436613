import { Button } from "@mui/material";
import React, { Component } from "react";
import { ArrayUtils, baseCreateNotification, downloadFile } from "../../helpers/HelpersFunc";
import { DocAccessType, DocumentActions, NotificationType } from "../../models/enums";
import Dropzone from "../dropzone/Dropzone";
import ProgressBar from '../progressBar/ProgressBar';
import { UiModal } from "../ui/UiModal/UiModal";
import { CustomFile, DocumentRights, Props, StateProps } from "./interfaces";
import { NotificationContainer } from 'react-notifications';
import { DocumentsListing } from "./components/DocumentsListing";
import { getMappingRoles } from "../../models/records";
import LocalStorageTokenService from "../../helpers/LocalStorageTokenService";
import './Upload.css';
import './Upload.scss';
import { ApiCallingMethods } from "../../helpers/AxiosHelper";
import CustomAxios from "../../helpers/AxiosHelper"
import { AxiosRequestConfig } from "axios";
import { notificationMessage, UserGroup } from "../../config/GlobalAppConfig";
import { FileService } from "../../../services/fileService";
import { LoggingServie } from "../../../services/LoggingService";

class Upload extends Component<Props, StateProps> {
    fileExtensionsList = [];
    extension = null;
    requestProgress: boolean;
    displayUploadSection: boolean = true;

    constructor(props: any) {
        super(props);

        this.state = {
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false,
            files: this.props.files,
            isModalOpen: false,
            isModalToDeleteOpen: false,
            documentRights: [],
            isLoading: false
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.renderProgress = this.renderProgress.bind(this);

        Object.values(this.props.fileTypesAccepted).forEach((value: any) => {
            this.fileExtensionsList = this.fileExtensionsList.concat(Object.values(value));
        })

        this.requestProgress = false;
    }

    documentRightsforSelectField = (documentRights: DocumentRights[]): DocumentRights[] => ArrayUtils.getUniqueBySingleKey(
        documentRights.map((documentRights: DocumentRights) => ({
            itemContent: documentRights.documentTypes.itemContent,
            id: documentRights.documentTypes.id
        })
    ), 'id');

    documentRightsforSelectFieldUploadOnly = (documentRights: DocumentRights[]): DocumentRights[] => ArrayUtils.getUniqueBySingleKey(
        documentRights.filter((documentRights: DocumentRights) => documentRights.documentActions.itemContent.toLowerCase() === DocumentActions.Upload)
            .map((documentRights: DocumentRights) => ({
                itemContent: documentRights.documentTypes.itemContent,
                id: documentRights.documentTypes.id
            }))
        , 'id');

    isUploadAllowedForDocumentType = (docAccessType: DocAccessType, documentRights: DocumentRights[]) => {
        //if no docType it's new file so upload is allowed
        if (docAccessType === DocAccessType["No Type"]) {
            return true
        } else {
            return documentRights.find((documentRight) => documentRight.documentTypes.id === docAccessType && (documentRight.documentActions.itemContent.toLowerCase() === DocumentActions.Upload))
                ? true : false
        }
    }

    isUploadAllowedForUser = (idUserRole: any) => {
        switch (idUserRole) {
            case UserGroup.SecurityTeam:
            case UserGroup.ProjectUser:
                return true;
        }
    }

    isUploadAllowedForUserIdWithDocumentType = (idUserRole: any, docAccessType: DocAccessType, documentRights: DocumentRights[]) => {
        return this.isUploadAllowedForUser(idUserRole) && this.isUploadAllowedForDocumentType(docAccessType, documentRights);
    }

    addDocumentRightsToFile = (file: CustomFile, documentrights?: DocumentRights[]) => {
        const docAccessType = Number(file.docAccessType) ? Number(file.docAccessType) : DocAccessType["No Type"];
        const rights = documentrights ? documentrights : this.state.documentRights
        file.docRights = this.isUploadAllowedForDocumentType(docAccessType, rights)
            ? this.documentRightsforSelectFieldUploadOnly(rights)
            : this.documentRightsforSelectField(rights);
    }

    onFilesAdded(files: CustomFile[]) {
        let filesToBeAdded: CustomFile[] = [];
        let filesToBeRemoved: CustomFile[] = [];
        let filesFoundToBeReplace: CustomFile[] = [];
        let filesToBeReplace: CustomFile[] = [];

        if (this.state.files.length) {
            files.forEach((file: CustomFile) => {
                this.addDocumentRightsToFile(file);
                const filesFound = this.state.files.filter(f => f.name === file.name)

                if (filesFound.length) {
                    // Add inside an array a list of files found that will be replaced
                    filesFoundToBeReplace.push(filesFound[0]);
                    // Add inside an array a list of new files to be replaced
                    filesToBeReplace.push(file);
                    this.setState({
                        isModalOpen: true,
                        fileToReplace: filesToBeReplace,
                        filesFound: filesFoundToBeReplace,
                        filesToBeAdded: filesToBeAdded,
                        filesToBeRemoved: filesToBeRemoved
                    })
                }
                else {
                    file.docAccessType = ''; //FIX is necessary to add this line ?
                    filesToBeAdded.push(file);

                    setTimeout(() => {
                        this.setState(prevState => ({
                            files: prevState.files.concat(filesToBeAdded.filter(value => !prevState.files.includes(value)))
                        }));
                    }, 100)
                }
            })
        }
        else {
            files.forEach((file: CustomFile) => {
                this.addDocumentRightsToFile(file);
            })
            this.setState(prevState => ({
                files: prevState.files.concat(files)
            }));
        }

        files.forEach((file: CustomFile) => {
            this.uploadFile(file);
        });
    }

    renderProgress(file: CustomFile) {
        const uploadProgress = this.state?.uploadProgress[file.name];
        if (file.uploading && (this.state?.uploading || this.state?.successfullUploaded)) {
            return (
                <ProgressBar progress={uploadProgress ? uploadProgress.percentage : 0} />
            );
        }
    }

    followProgress = (interval: any, file: CustomFile, fullurl: string, filename: string) => {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${fullurl}&fileName=${filename}`,
            data: null
        };
        this.requestProgress = true;
        CustomAxios.request(options).subscribe(res => {
            if (String(res.data).includes("Upload - Encrypt - Init") && !file.encrypting) {
                file.uploading = false;
                file.encrypting = true;
                file.encryptionDone = false;
                file.isToReplace = false;
            }
            else if (String(res.data).includes("Upload - Encrypt - Done")) {
                file.uploading = false;
                file.encrypting = true;
                file.encryptionDone = false;
                file.isToReplace = false;

                this.setState({ uploading: true });
            }
            else if (String(res.data).includes("Upload - Init") || String(res.data).includes("Upload - Save")) {
                file.uploading = true;
                file.encrypting = false;
                file.encryptionDone = false;
                file.isToReplace = false;

                this.setState({ uploading: true });
            }
            else {
                file.uploading = false;
                file.encrypting = false;
                file.encryptionDone = true;
                file.isToReplace = false;
                this.setState({ uploading: false });
                clearInterval(interval);
            }
            this.requestProgress = false;
            this.setState({
                files: this.state.files
            });
        });
    }

    
    onUploadProgress = (progressEvent: any, file: CustomFile, fullurl: string, filename: string) => {
        if (this.props.progressActivated) {
            let interval: any;
            const copy = { ...this.state.uploadProgress };
            let state = 'pending';
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

            if (percentCompleted === 100) {
                state = 'done';
                interval = setInterval(() => {
                    if (!this.requestProgress) {
                        this.followProgress(interval, file, fullurl, filename);
                    }
                }, 1000);
            }

            copy[file.name] = { state: state, percentage: percentCompleted };
            this.setState({
                uploadProgress: copy
            })
        }
    }

    async uploadFile(file: CustomFile) {
        //Set full url to upload file
        let fullurl = `${this.props.urlProgress}?idQuestionnaire=${this.props.questionnaireId}`;

        this.setState({ uploadProgress: {}, uploading: true });
        const filename = file.name.replace(/\s/g, '%20');
        if (!file.id || (file.isToReplace && file.id)) {
            const formData = new FormData();
            formData.append("idQuestionnaire", this.props.questionnaireId.toString());

            file.docAccessType = DocAccessType["Visible to all"].toString();

            const pos = Number(file.docAccessType);
            formData.append("docType", DocAccessType[pos]);
            formData.append("files", file, file.name);

            let method: ApiCallingMethods = ApiCallingMethods.post;
            let uploadUrl = this.props.urlUpload;
            if (file.id) {
                uploadUrl += "/update/" + file.id;
                method = ApiCallingMethods.put;
            }

            const options: AxiosRequestConfig = {
                method: method,
                url: uploadUrl,
                data: formData,
                responseType: 'json',
                onUploadProgress: (event) => this.onUploadProgress(event, file, fullurl, filename)
            };
            CustomAxios.request(options).subscribe((response) => {
                if (response.data && typeof response.data.id === 'number') {
                    file.id = response.data.id;
                    file.filenameInBe = response.data.filenameConverted;
                }
            }, (error) => {

                baseCreateNotification(NotificationType.error, notificationMessage.ErrorGenericMessage.title, notificationMessage.ErrorGenericMessage.message);
            });
        }
        else {
            FileService.update(this.props.urlUpload, file).subscribe(() => {
                baseCreateNotification(NotificationType.success, "", "File updated");
                this.setState({ uploading: false });
            })
        }
    }

    updateAccessType = (event: any, file: CustomFile) => {
        file.docAccessType = event.target.value;
        if (!file.encryptionDone) {
            file.uploading = true;
        }
        this.setState({
            files: this.state.files
        });
        this.uploadFile(file);
    }

    deleteFile = (file: CustomFile, fromPopup: boolean = false) => {
        let pos = -1;

        if (file.id && !fromPopup) {
            FileService.delete(file.id).subscribe(() => {
                baseCreateNotification(NotificationType.success, "", 'File deleted successfully');
            }, () => {
                baseCreateNotification(NotificationType.error, "Something went wrong");
            });
        }

        const fileToDel = this.state.files.filter((f, index) => {
            if (f.name === file.name && f.idQuestionnaire === file.idQuestionnaire) {
                pos = index;
                return true;
            }
            return false;
        })[0];

        if (fileToDel) {
            this.state.files.splice(pos, 1);
            this.setState({
                files: this.state.files
            })
        }
    }

    handleModalClose = () => {
        this.setState({
            isModalOpen: false,
            isModalToDeleteOpen: false
        })
    }

    cancel = () => {
        this.handleModalClose();
    }

    onDeleteCrossClick = (file: CustomFile) => {
        this.setState({
            isModalToDeleteOpen: true,
            fileToDelete: file
        })
    }

    deleteFilePopup = () => {
        if (this.state.fileToDelete) {
            this.deleteFile(this.state.fileToDelete);
        }
        this.handleModalClose();
    }

    onDownload = (file: CustomFile) => {
        FileService.download(this.props.urlDownload, file.filenameInBe).subscribe((res) => {
            downloadFile(res, file.name);
        })
    }

    handleReplace = () => {
        if (this.state.filesFound && this.state.fileToReplace) {
            const filesToBeAdded = this.state.filesToBeAdded;
            const filesToBeRemoved = this.state.filesToBeRemoved;

            this.state.fileToReplace.forEach((item: CustomFile) => {
                item.docAccessType = '';
                item.id = this.state.filesFound?.find(exp => exp.name === item.name)?.id
                filesToBeAdded?.push(item);
                filesToBeRemoved?.push(item);
                item.isToReplace = true;
            });

            if (filesToBeRemoved && filesToBeRemoved.length) {
                filesToBeRemoved.forEach((customFile: CustomFile) => {
                    const fileToRemove = this.state.files.filter(f => f.name === customFile.name)[0]
                    this.deleteFile(fileToRemove, true);
                })
            }

            if (filesToBeAdded && filesToBeAdded.length) {
                setTimeout(() => {
                    this.setState(prevState => ({
                        files: prevState.files.concat(filesToBeAdded.filter(value => !prevState.files.includes(value)))
                    }));
                }, 100)
            }
            this.handleModalClose();
        }
    }

    updateAndGetFileProps = (documentRights: DocumentRights[]) => {
        return this.props.files?.map(
            file => {
                const docAccessType = Number(file.docAccessType) ? Number(file.docAccessType) : DocAccessType["No Type"];
                this.addDocumentRightsToFile(file, documentRights);
                const idUserRole = getMappingRoles(LocalStorageTokenService.getUserRoleToken());
                file.disable = !this.isUploadAllowedForUserIdWithDocumentType(idUserRole, docAccessType, documentRights)
                return file;
            }
        )
    }

    componentDidMount() {

        //TODO change param with role id
        const uploadSubscription = FileService.getDocumentRightsByRole(1).subscribe(
            (response) => {
                this.displayUploadSection = response.some((documentRights: DocumentRights) => documentRights.documentActions.itemContent.toLowerCase() === DocumentActions.Upload)
                const newFileList = this.updateAndGetFileProps(response);
                this.setState({
                    ...this.state,
                    documentRights: response,
                    files: newFileList,
                    isLoading: false
                });
            },
            (error) => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                LoggingServie.LogError(error, '')
            }
        );
        this.setState(  {
            ...this.state,
            uploadSubscription: uploadSubscription
        });
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.files !== this.props.files && this.state.documentRights.length) {
            this.setState({
                ...this.state,
                isLoading: true
            });

            this.setState({
                ...this.state,
                files: this.updateAndGetFileProps(this.state.documentRights),
                isLoading: false
            })
        }
    }

    componentWillUnmount() {
        this.state.subscription?.unsubscribe();
        this.state.uploadSubscription?.unsubscribe();
    }

    render() {
        return (
            <div className="upload">
                <div className="upload__title"><h3>{this.props.title}</h3>
                    {this.state.files?.length === 1 ? (
                        <p className="upload__number"><span>{this.state.files.length}</span>Document</p>
                    ) : this.state.files?.length > 1 && (
                        <p className="upload__number"><span>{this.state.files.length}</span> Documents</p>
                    )}
                </div>
                <div>
                    {this.state.files.length > 0 && !this.state.isLoading ? (
                        <React.Fragment>
                            {/* Declaration listing linked only to the Event */}
                            {
                                <div className="files">
                                    <DocumentsListing
                                        files={this.state.files}
                                        fileTypesAccepted={this.props.fileTypesAccepted}
                                        updateAccessType={this.updateAccessType}
                                        deleteFile={this.onDeleteCrossClick}
                                        renderProgress={this.renderProgress}
                                        download={this.onDownload}
                                    />
                                </div>
                            }
                        </React.Fragment>
                    ) :
                        <DocumentsListing
                            files={this.state.files}
                            fileTypesAccepted={this.props.fileTypesAccepted}
                            updateAccessType={this.updateAccessType}
                            deleteFile={this.onDeleteCrossClick}
                            renderProgress={this.renderProgress}
                            download={this.onDownload}
                        />
                    }
                    <div>
                        {
                            this.displayUploadSection ? (
                                <Dropzone
                                    maxFileSize={this.props.maxFileSize}
                                    supportedExtensions={this.fileExtensionsList}
                                    onFilesAdded={this.onFilesAdded}
                                    disabled={this.state.uploading} />
                            ) : ''
                        }
                    </div>
                    <NotificationContainer />
                </div>
                <UiModal id={'replacePopup'} title={'Warning'} onClose={this.handleModalClose} open={this.state.isModalOpen}>
                    <div>
                        <div className="row">
                            <ul>
                                <li>
                                    We have detected {this.state.filesFound?.length} identitical files. Are you sure you want to replace them :
                                    <ul>
                                        {this.state.filesFound?.map((value: CustomFile, index: number) => {
                                            return <li key={index}>{value.name}</li>
                                        })}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="ui-modal__footer ui-modal__footer--buttons">
                            <Button onClick={this.cancel} variant="outlined" color="primary" key="cancel">Cancel</Button>
                            <Button onClick={() => { this.handleReplace() }} variant="contained" color="primary" key="apply">Confirm</Button>
                        </div>
                    </div>
                </UiModal>
                <UiModal id={'deletePopup'} title={'Warning - Delete'} onClose={this.handleModalClose} open={this.state.isModalToDeleteOpen}>
                    <div>
                        <div className="row">
                            Are you sure you want to delete {this.state.fileToDelete?.name}?
                        </div>
                        <div className="ui-modal__footer ui-modal__footer--buttons">
                            <Button onClick={this.cancel} variant="outlined" color="primary" key="cancel">Cancel</Button>
                            <Button onClick={this.deleteFilePopup} variant="contained" color="primary" key="apply">Confirm</Button>
                        </div>
                    </div>
                </UiModal>
            </div>
        )
    }
}

export default Upload;
