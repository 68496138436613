import * as yup from 'yup';
import { Reference } from '../../../shared/models/interfaces';
import { DataSubjectsType, DataTypeBU, PersonalDataRetained, SensitiveDataIds } from '../../../shared/models/enums';
const MAX_OTHER_LENGTH = 150;

export const saveValidationSchema = yup.object().shape({
  generalInformation: yup.object({
    projectName: yup
      .string()
      .matches(
        /^[\w\d\s\-\.\:\?\#\[\]\@\!\&\'\(\)\*\+\,\;\=]*$/,
        "Unvalid character. Please enter a valid name (valid characters: -.:?#[]@!&'()*+,;=)"
      )
      .required('This is a required field'),
  }),
  thirdParties: yup.mixed().when('isThereAnyThirdPartyInvolved', {
    is: true,
    then: yup.array().of(
      yup.object().shape({
        thirdParty: yup.object().shape({
          companyName: yup.string().trim().required('This is a required field'),
          companyWebsite: yup.string().trim().required('This is a required field'),
        }),
      })
    ),
  }),
  digitalApplication: yup.mixed().when('isThereAnyDigitalApplicationInvolved', {
    is: true,
    then: yup.object().shape({
      appName: yup.string().required('This is a required field'),
    }),
  }),
  dataInformation: yup.object().shape({
    otherSensitiveInformation: yup.mixed().when('sensitiveCompanyInfo', {
      is: (sensitiveCompanyInfo) =>
        sensitiveCompanyInfo?.filter((t: Reference) => t.id === SensitiveDataIds.Other).length,
      then: yup
        .string()
        .max(MAX_OTHER_LENGTH, `Maximum of ${MAX_OTHER_LENGTH} characters`)
        .required('This is a required field'),
    }),
    otherDataUsedFor: yup.mixed().when('dataUsedFor', {
      is: (dataUsedFor) => dataUsedFor?.filter((t: Reference) => t.id === DataTypeBU.Other).length,
      then: yup.string().max(MAX_OTHER_LENGTH, `Maximum of ${MAX_OTHER_LENGTH} characters`),
    }),
    otherPersonalData: yup.mixed().when('idPersonalDataRetainedBy', {
      is: (id) => id === PersonalDataRetained.Other,
      then: yup.string().max(MAX_OTHER_LENGTH, `Maximum of ${MAX_OTHER_LENGTH} characters`),
    }),
    otherSubjectInvolved: yup.mixed().when('dataSubjectInvolved', {
      is: (dataSubjectInvolved) =>
        dataSubjectInvolved?.filter((t: Reference) => t.id === DataSubjectsType.Other).length,
      then: yup.string().max(MAX_OTHER_LENGTH, `Maximum of ${MAX_OTHER_LENGTH} characters`),
    }),
  }),
});

export const submitValidationSchema = yup.object().shape({
  generalInformation: yup.object({
    projectName: yup
      .string()
      .matches(
        /^[\w\d\s\-\.\:\?\#\[\]\@\!\&\'\(\)\*\+\,\;\=]*$/,
        "Unvalid character. Please enter a valid name (valid characters: -.:?#[]@!&'()*+,;=)"
      )
      .required('This is a required field'),
    projectDescription: yup.string().required('This is a required field'),
    maison: yup.array().of(yup.object()).required('This is a required field'),
    region: yup.array().of(yup.object()).required('This is a required field'),
    maisonProjectOwner: yup.string().required('This is a required field'),
  }),
  thirdParties: yup.mixed().when('isThereAnyThirdPartyInvolved', {
    is: true,
    then: yup.array().of(
      yup.object().shape({
        thirdParty: yup.object().shape({
          companyName: yup.string().trim().required('This is a required field'),
          companyWebsite: yup.string().trim().required('This is a required field'),
        }),
      })
    ),
  }),
  digitalApplication: yup.mixed().when('isThereAnyDigitalApplicationInvolved', {
    is: true,
    then: yup.object().shape({
      appName: yup.string().required('This is a required field'),
    }),
  }),
  dataInformation: yup.object().shape({
    sensitiveCompanyInfo: yup.array().of(yup.object()).required('This is a required field'),
    otherSensitiveInformation: yup.mixed().when('sensitiveCompanyInfo', {
      is: (sensitiveCompanyInfo) =>
        sensitiveCompanyInfo?.filter((t: Reference) => t.id === SensitiveDataIds.Other).length,
      then: yup.string().max(MAX_OTHER_LENGTH, `Maximum of ${MAX_OTHER_LENGTH} characters`),
    }),
    otherDataUsedFor: yup.mixed().when('dataUsedFor', {
      is: (dataUsedFor) => dataUsedFor?.filter((t: Reference) => t.id === DataTypeBU.Other).length,
      then: yup.string().max(MAX_OTHER_LENGTH, `Maximum of ${MAX_OTHER_LENGTH} characters`),
    }),
    otherPersonalData: yup.mixed().when('idPersonalDataRetainedBy', {
      is: (id) => id === PersonalDataRetained.Other,
      then: yup.string().max(MAX_OTHER_LENGTH, `Maximum of ${MAX_OTHER_LENGTH} characters`),
    }),
    otherSubjectInvolved: yup.mixed().when('dataSubjectInvolved', {
      is: (dataSubjectInvolved) =>
        dataSubjectInvolved?.filter((t: Reference) => t.id === DataSubjectsType.Other).length,
      then: yup.string().max(MAX_OTHER_LENGTH, `Maximum of ${MAX_OTHER_LENGTH} characters`),
    }),
    isTherePersonalDataInvolved: yup.bool().required('This is a required field'),
    dataUsedFor: yup.mixed().when('isTherePersonalDataInvolved', {
      is: true,
      then: yup.array().required('This is a required field'),
    }),
    idPersonalDataRetainedBy: yup.mixed().when('isTherePersonalDataInvolved', {
      is: true,
      then: yup.string().required('This is a required field'),
    }),
    idDataSubjectsCollected: yup.mixed().when('isTherePersonalDataInvolved', {
      is: true,
      then: yup.string().required('This is a required field'),
    }),
    dataSubjectInvolved: yup.mixed().when('isTherePersonalDataInvolved', {
      is: true,
      then: yup.array().required('This is a required field'),
    }),
    dataProcessing: yup.mixed().when('isTherePersonalDataInvolved', {
      is: true,
      then: yup.array().required('This is a required field'),
    }),
  }),
});
