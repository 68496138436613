import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { Field, Formik, FormikHelpers } from "formik";
import moment from "moment";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { Subscription } from "rxjs";
import LoadingContext from "../../../contexts/LoadingContext";
import { ISendNote, NoteService, sendNoteValidationSchema } from "../../../services/noteService";
import { notificationMessage } from "../../config/GlobalAppConfig";
import { baseCreateNotification } from "../../helpers/HelpersFunc";
import { NotificationType } from "../../models/enums";
import { UiTextareaField } from "../fields";
import { UiModal } from "../ui/UiModal/UiModal";

interface INotesTable {
    idQuestionnaire: string
}

interface INotesTableRow {
    note: ISendNote,
    triggerReload: () => void,
    index: number,
    count: number
}
export const MAX_NOTE_CHARS = 300;

const NoteRow = (props: INotesTableRow) => {
    const [openNoteModal, setOpenNoteModal] = React.useState(false);
    const [sendingNote, setSendingNote] = React.useState(false);
    const noteModalHandleClose = () => setOpenNoteModal(false);
    const noteModalHandleOpen = () => setOpenNoteModal(true);

    const sendNoteInitialValues: ISendNote =
    {
        id: props.note.id,
        idQuestionnaire: props.note.idQuestionnaire,
        noteText: props.note.noteText,
        projectName: ''
    };

    const submitNote = function (values: ISendNote, formProps: FormikHelpers<ISendNote>) {
        setSendingNote(true);

        values.noteText = values.noteText.trim();
        NoteService.Update(values).subscribe(data => {
            baseCreateNotification(data ? NotificationType.success : NotificationType.error,
                data ? notificationMessage.Note_updated.title : notificationMessage.Note_update_error.title,
                data ? notificationMessage.Note_updated.message : notificationMessage.Note_update_error.message);
            noteModalHandleClose();
            setSendingNote(false);
            props.triggerReload();
        });
    }

    const deleteNote = (id?: number) => {
        NoteService.Delete(id).subscribe(data => {
            baseCreateNotification(data ? NotificationType.success : NotificationType.error,
                data ? notificationMessage.Note_deleted.title : notificationMessage.Note_delete_error.title,
                data ? notificationMessage.Note_deleted.message : notificationMessage.Note_delete_error.message);
            noteModalHandleClose();
            setSendingNote(false);
            props.triggerReload();
        });
    }

    return (
        <React.Fragment>
            <div className={`container ${props.index + 1 !== props.count ? 'bot-border' : ''}`}>
                {/*TODO Get profile image */}
                <img className="profile" src="/assets/img/user.png" alt="profile" />
                <div className="note">
                    <label className="user-name">{props.note.createdByFullName}</label>
                    <label className="created-date">
                        {moment(props.note.createdDate).format('yyyy.MM.DD')} {moment(props.note.createdDate).format('HH:mm ')}
                        {
                            props.note.createdDate !== props.note.modifiedDate
                                ? `- Modified on ${moment(props.note.modifiedDate).format('yyyy.MM.DD')} ${moment(props.note.createdDate).format('HH:mm')} by ${props.note.createdByFullName}`
                                : ''
                        }
                    </label>
                    {
                        props.note.noteText.split("\n").map((line, index) =>
                            <p className="note-line" key={`${line}-${index}`} > {line} </p>
                        )
                    }
                </div>
                <div className="actions">
                    <IconButton color="primary" onClick={noteModalHandleOpen}><Edit /></IconButton>
                    <IconButton style={{ color: "#EA4335" }} onClick={() => deleteNote(props.note.id)}><Delete /></IconButton>
                </div>
            </div>

            <Formik
                initialValues={sendNoteInitialValues}
                validationSchema={sendNoteValidationSchema}
                onSubmit={submitNote}
            >
                {formProps => (
                    <UiModal title="Update note" open={openNoteModal} onClose={noteModalHandleClose}>
                        <form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <Field name="noteText" label="Note" component={UiTextareaField} value={"noteText"} rows={7} inputProps={{maxLength:MAX_NOTE_CHARS}} />
                            </div>
                            <div className="ui-modal__footer ui-modal__footer--buttons">
                                <Button onClick={noteModalHandleClose} variant="outlined" color="primary" key="cancel">Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={sendingNote ? <CircularProgress size={20} /> : <Edit />}
                                    disabled={sendingNote}
                                >
                                    Update note
                                </Button>
                            </div>
                        </form>
                    </UiModal>
                )}
            </Formik>

        </React.Fragment>
    );
}

const RenderLoading = ({ loading, notes }: { loading: boolean, notes?: ISendNote[] }) => {
    return (
        <>
            {
                loading && (
                    <div className="loading">
                        <CircularProgress color="primary" thickness={10} size={50} />
                    </div>
                )
            }
            {
                notes?.length === 0 && (
                    <div className="loading">
                        <p>No notes found</p>
                    </div>
                )
            }
        </>
    );
}

export const NotesTable = forwardRef((props: INotesTable, ref: any) => {
    const ctx = useContext(LoadingContext);
    const [notesSubscription, setNotesSubscription] = useState<Subscription>();
    const [notes, setNotes] = useState<ISendNote[]>();
    const [loading, setLoading] = useState(true);

    const reloadNotes = () => {
        ctx.startLoading()
        setLoading(true);
        const tmpNotesSubscription = NoteService.GetByQuestionnaire(props.idQuestionnaire).subscribe(notes => {
            setNotes(notes);
            setLoading(false);
            ctx.stopLoading();
        });
        setNotesSubscription(tmpNotesSubscription);
    }

    useImperativeHandle(ref, () => {
        return {
            reloadNotes,
        }
    });

    useEffect(() => {
        reloadNotes();

        return () => {
            notesSubscription?.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="NotesTable">
            {
                notes && notes.length > 0
                    ?
                    notes.map((_note: ISendNote, index) => {
                        return <NoteRow key={_note.id} note={_note} triggerReload={reloadNotes} index={index} count={notes.length} />
                    })
                    :
                    <RenderLoading loading={loading} notes={notes} />
            }
        </div>
    );
});