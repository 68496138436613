import React, { ReactNode, ReactChild, ReactChildren } from "react"
import { Box } from "@material-ui/core"
import UiTooltipLabel from "../fields/UiTooltipLabel"

type IPropsUiBox = {
    name?: string,
    title: string | ReactNode,
    actions?: ReactNode,
    children: ReactChild | ReactChildren,
    altStyle?: boolean,
    collapsed?: boolean,
    isChild?: boolean,
    headerClassnames?: string
    tooltipText?: string,
    tooltip?: string
}
export const UiBox = (props: IPropsUiBox) => {
    const label = UiTooltipLabel({ label: ' ', tooltip: props.tooltip });
    const cssClass = ['box'];

    if (props.name) {
        cssClass.push(props.name)
    }
    if (props.altStyle) {
        cssClass.push('box--alt')
    }
    if (props.collapsed) {
        cssClass.push('collapsed')
    }

    return (
        <Box className={cssClass.join(' ')}>
           <div className={`box-header ${props.headerClassnames ?? ''}`}>
                <div className="box-header__title">
                    <h2>{props.title}</h2>
                    {label}
                </div>
                {props.actions && (
                    <div className="box-header__actions">
                        {props.actions}
                    </div>
                )}
            </div>
            <div className={`${!props.isChild ? 'box-content' : 'box-content-child'}`}>
                {props.children}
            </div>
        </Box>
    )

}

export default UiBox;