import { Button, Snackbar } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { Subject } from "rxjs";
import NotificationContext from "../contexts/NotificationContext";
import { notificationDisplayTime } from "../shared/config/GlobalAppConfig";

export interface SnackbarMessage {
    message: string;
    key: number;
}

export interface State {
    open: boolean;
    snackPack: readonly SnackbarMessage[];
    messageInfo?: SnackbarMessage;
}

export default React.memo(function NotificationController({ children }: { children: ReactNode }) {
    const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
    const [open, setOpen] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [myObservable, setMyObservable] = useState<Subject<boolean>>(new Subject());
    const time = notificationDisplayTime.cancel;
    const [messageInfo, setMessageInfo] = useState<SnackbarMessage>();
    const [hasStartedReq, setHasStartedReq] = useState<boolean>(false);

    const cleanSessionStorage = () => {
        sessionStorage.removeItem('notification');
        sessionStorage.removeItem('message');
    }

    const handleClose = (
        _event: React.SyntheticEvent | MouseEvent,
        reason?: string,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        if (reason === 'timeout') {
            myObservable.next(true);
        }
        else {
            myObservable.next(false);
        }

        myObservable.complete();
        setMyObservable(myObservable);
        setMyObservable(new Subject());
        setOpen(false);
        setHasStartedReq(false);
        cleanSessionStorage();
        if (timer) clearTimeout(timer);
    };

    const init = function (message: string) {
        setHasStartedReq(true);
        setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);

        sessionStorage.setItem('notification', JSON.stringify(myObservable))
        sessionStorage.setItem('message', message)

        const tempTimer = setTimeout(function () {
            myObservable.next(true);
            myObservable.complete();
            setMyObservable(myObservable);
            setMyObservable(new Subject());
            setOpen(false);
            setHasStartedReq(false);
            cleanSessionStorage();
        }, time);

        setTimer(tempTimer);

        return myObservable;
    }

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
            // Close an active snack when a new one is added
            setOpen(false);
        }
    }, [snackPack, messageInfo, open]);

    useEffect(() => {
        const notification = sessionStorage.getItem('notification');
        const message = sessionStorage.getItem('message');
        if (notification && message) {
            const observableFromSession = JSON.parse(notification);
            setMyObservable(myObservable)
            setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);

            if (!observableFromSession.isStopped) {
                setOpen(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleExited = () => {
        setMessageInfo(undefined);
    };

    const get = () => {
        if (hasStartedReq || !!sessionStorage.getItem('notification')) return myObservable;
    }

    const value = {
        init,
        get
    }

    return (
        <NotificationContext.Provider value={value}>
            {children}

            <Snackbar
                key={messageInfo ? messageInfo.key : undefined}
                open={open}
                autoHideDuration={time}
                onClose={handleClose}
                TransitionProps={{ onExited: handleExited }}
                message={messageInfo ? messageInfo.message : undefined}
                action={
                    <Button color="inherit" size="small" onClick={handleClose}>
                        ANNULER
                    </Button>
                }
            />
        </NotificationContext.Provider>
    )
})