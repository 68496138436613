import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";
import { Field } from "formik";
import React, { ReactChild, ReactChildren, ReactNode, useState } from "react";
import { UiSwitchField } from "../fields";
import UiTooltipLabel from "../fields/UiTooltipLabel";

type IPropsUiCollapsable = {
    id?: string,
    name?: string,
    children: ReactChild | ReactChildren,
    title: string | ReactNode
    number?: number,
    showNumber?: boolean,
    tooltipText?: string,
    tooltip?: string,
    showToggle?: boolean,
    toggleText?: string,
    toggleName?: string,
    showPopup?: boolean,
    popupTitle?: string,
    popupContent?: ReactChild | ReactChildren,
    withNav?: boolean,
    hideDivider?: boolean,
    onToggleClick?: (value: React.ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean,
}
export const UiCollapsable = (props: IPropsUiCollapsable) => {
    const label = UiTooltipLabel({
        label: props.tooltipText,
        tooltip: props.tooltip ? props.tooltip : 'no tooltip',
        activatePopup: props.showPopup,
        popupTitle: props.popupTitle,
        popupContent: props.popupContent
    })
    const cssClass = ['ui-collapsable'];
    const containerCssClass = props.withNav ? 'ui-collapsable-container' : '';
    const [stepClass, setStepClass] = useState('step step--expanded');

    if (props.name) {
        cssClass.push('ui-collapsable--' + props.name)
    }

    const handleOnChange = function (event: React.SyntheticEvent, expanded: boolean) {
        setStepClass(expanded ? 'step step--expanded' : 'step');
    }

    return (
        <div className={containerCssClass}>
            {props.withNav &&
                <div className={stepClass}>
                    <div className={'number'}><span>{props.number}</span></div>
                    {!props.hideDivider && <div className='line'><Divider orientation="vertical" textAlign="right" /></div>}
                </div>
            }
            <Accordion defaultExpanded={true} className={cssClass.join(' ')} elevation={0} onChange={handleOnChange}>
                <AccordionSummary id={props.id}>
                    {props.showNumber && <div className="number"><span>{props.number}</span></div>}
                    <h3>{props.title}</h3>
                    {props.showToggle &&
                        <Field name={props.toggleName}
                            label={props.toggleText}
                            fieldtochange={props.toggleName}
                            onChange={props.onToggleClick}
                            component={UiSwitchField}
                            disabled={props.disabled}
                        />
                    }
                    <div className="right">{label}</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="box-fullwidth">
                        {props.children}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
