import { Grid } from "@material-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { ReferenceService } from "../../../services/referenceService";
import {
  UiAutocompleteField,
  UiSelectField,
  UiSwitchField,
} from "../../../shared/components/fields";
import { Reference } from "../../../shared/models/interfaces";
import { HostedLabel } from "./DigitalApplication";

export interface IHosting {
    appHost?: string;
    appHostedCountrySelected?: Reference | string;
    dataHostedCountrySelected?: Reference | string;
    digitalApplicationAccessibleFrom?: number | string;
    isThereAnyInterconnections?: boolean;
}

interface HostingProps extends FieldProps, FormikProps<any> {
  countriesList: Reference[];
  isDisabled?: boolean;
}

type CountryIsFor = "data" | "application";

export const Hosting: React.FunctionComponent<HostingProps> = (
  props: HostingProps
) => {
  const { field, form, isDisabled } = props;
  const { setFieldValue } = form;

  const [applicationHostEnvList, setApplicationHostEnvList] = useState([]);
  const [countriesListForApp, setCountriesListForApp] = useState<Reference[]>(
    []
  );
  const [countriesListForData, setCountriesListForData] = useState<Reference[]>(
    []
  );
  const [countriesSubscription, setCountriesSubscription] =
    useState<Subscription>();
  const [applicationAccessUsersList, setApplicationAccessUsersList] = useState(
    []
  );

  let timer: NodeJS.Timeout | null = null;

  useEffect(() => {
    const applicationHostEnvSubscription =
      ReferenceService.GetListApplicationHostEnv().subscribe((hostEnvList) => {
        setApplicationHostEnvList(hostEnvList);
        if(field.value?.appHost && typeof field.value?.appHost === 'number'&& hostEnvList.length){
          const selectedAppHost = hostEnvList.find((clapp: Reference) => clapp.id === field.value?.appHost) as Reference | undefined
          setFieldValue(`${field.name}.appHost`, selectedAppHost);
        }
      });
    const applicationAccessUsersSubscription =
      ReferenceService.GetListApplicationAccessUsers().subscribe(
        (accessUsersList) => {
          setApplicationAccessUsersList(accessUsersList);
        }
      );

    const tempSubscription = ReferenceService.GetListCountries().subscribe((countryList) => {
      
        setCountriesListForApp(countryList);
        setCountriesListForData(countryList);
       
            
        if (field.value?.appHostedCountrySelected && typeof field.value?.appHostedCountrySelected === 'number' && countryList.length) {
          const selectedAppHostedCountry = countryList.find((clapp: Reference) => clapp.id === field.value?.appHostedCountrySelected) as Reference | undefined
          setFieldValue(`${field.name}.appHostedCountrySelected`, selectedAppHostedCountry);
        }
    
        if (field.value?.dataHostedCountrySelected && typeof field.value?.dataHostedCountrySelected === 'number' && countryList.length) {
          const selectedDataHostedCountry = countryList.find((clData: Reference) => clData.id === field.value?.dataHostedCountrySelected) as Reference | undefined
          setFieldValue(`${field.name}.dataHostedCountrySelected`, selectedDataHostedCountry ?? '');
        }
    
    });

    return () => {
      tempSubscription.unsubscribe();
      applicationAccessUsersSubscription.unsubscribe();
      applicationHostEnvSubscription.unsubscribe();
    }
  }, [
    field.value?.appHostedCountrySelected,
    field.value?.dataHostedCountrySelected,
    field.value]);

  const findCountries = function (
    event: React.ChangeEvent,
    countrySearch: string,
    countryIsFor: CountryIsFor
  ) {
    if (timer) clearTimeout(timer);

    // Avoid multiple requests in a row almost instantly
    timer = setTimeout(() => {
      if (event.type === "change") {
        const tempSubscription = ReferenceService.GetListCountries(
          countrySearch
        ).subscribe((countryList) => {
          if (countryIsFor === "data") setCountriesListForData(countryList);
          else {
            setCountriesListForApp(countryList);
          }
        });
        setCountriesSubscription(tempSubscription);
      }
    }, 500);
  };

  return (
    <>
      <div className="section-title">Hosting</div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Field
            name={`${field.name}.appHost`}
            label=" Where will the application be hosted?"
            component={UiAutocompleteField}
            showSecondLabel={true}
            options={applicationHostEnvList}
            disabled={isDisabled}
            /*column="half"*/
          /> 
          <Field
            name={`${field.name}.appHostedCountrySelected`}
            label={<HostedLabel word="application" />}
            component={UiAutocompleteField}
            options={countriesListForApp}
            getValue={(item : Reference) => item?.id}
            getOptionSelected={(a: any,b: any) => {
              return a.id === b
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name={`${field.name}.digitalApplicationAccessibleFrom`}
            label="Where can the digital application be accessed from?"
            component={UiSelectField}
            options={applicationAccessUsersList}
            disabled={isDisabled}
          />
          <Field
            name={`${field.name}.dataHostedCountrySelected`}
            label={<HostedLabel word="data" />}
            component={UiAutocompleteField}
            options={countriesListForData}
            onInputChange={(countrySearch: string, event: React.ChangeEvent) =>
              findCountries(event, countrySearch, "data")
            }
            getOptionSelected={(a: any,b: any) => {
              return a.id === b
            }}
            disabled={isDisabled}
          />
        </Grid>
  
      </Grid>
      <div className="section-title">Interconnections</div>
      <Field
        name={`${field.name}.isThereAnyInterconnections`}
        label="Will this application be interconnected with any other Richemont/Maison applications or systems?"
        component={UiSwitchField}
        column="half"
      />
    </>
  );
};
