import { Grid } from '@material-ui/core';
import { Field, FieldProps, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import LoadingContext from '../../../contexts/LoadingContext';
import { ReferenceService } from '../../../services/referenceService';
import { UiAutocompleteField, UiTextareaField, UiTextField } from '../../../shared/components/fields';
import { Option } from './../../../shared/components/SelectField';
import { CommonProperties, Reference } from '../../../shared/models/interfaces';
import { updateMultipleSelectedValues } from '../../../shared/helpers/HelpersFunc';
import { IUiMultiselectOption } from '../../../shared/components/fields/UiAutocompleteField';

export interface IGeneralInformation {
    projectName: string,
    projectDescription: string,
    country: Option[],
    countrySaved?: Option[],
    countrySelected?: any,
    maison?: Option[],
    maisonSaved?: Maison[],
    maisonSelected?: any,
    region?: Option[],
    regionSaved?: Region[],
    regionSelected?: any,
    maisonProjectOwner?: Reference | string,
    technicalPointOfContact?: Reference | string,
    department: string,
}

interface Props extends FieldProps, FormikProps<any> {
    countriesList: Reference[],
    disable?: boolean
}

interface Maison extends CommonProperties {
    idMaison: number,
    idQuestionnaire: number
}

interface Region extends CommonProperties {
    idRegion: number,
    idQuestionnaire: number
}

export const GeneralInformation = (props: Props) => {
    const { field, disable, form } = props;
    const { setFieldValue } = form;
    const ctx = React.useContext(LoadingContext);
    const [maisonsOptions, setMaisonOptions] = useState([])
    const [regionList, setRegionList] = useState([])
    const [countriesList, setCountriesList] = useState<Reference[]>([]);
    const [adUsersList, setAdUsersList] = useState([])
    const [adOwnerList, setAdOwnerList] = useState([])
    const [countriesSubscription, setCountriesSubscription] = useState<Subscription>()
    const [adUsersSubscription, setAdUsersSubscription] = useState<Subscription>()
    const [countriesInput, setCountriesInput] = useState<string>('');
    const [countriesSelected, setCountriesSelected] = useState<Option[]>([]);
    const [adUsersInput, setAdUsersInput] = useState<string>('');
    const [adOwnerInput, setAdOwnerInput] = useState<string>('');

    const handleCustomSelect = function (values: Array<IUiMultiselectOption>, _projectId: number, name: string) {
        updateMultipleSelectedValues(values, name, field, setFieldValue);
    }

    useEffect(() => {
        if (field.value?.projectSpecificCountry && typeof field.value?.projectSpecificCountry === 'number' && countriesList.length) {
            const countryFound = countriesList.find((ref: Reference) => ref.id === field.value?.projectSpecificCountry)
            setFieldValue(`${field.name}.projectSpecificCountry`, countryFound ?? '')
        }

        if (field.value?.maisonSaved) {
            const selectedPls = field.value.maisonSaved.map((pl: Maison) => {
                return maisonsOptions.find((maison: Reference) => maison.id === pl.idMaison)
            })
            setFieldValue(`${field.name}.maison`, selectedPls);
        }

        if (field.value?.regionSaved) {
            const selectedPls = field.value.regionSaved.map((pl: Region) => {
                return regionList.find((region: Reference) => region.id === pl.idRegion)
            })
            setFieldValue(`${field.name}.region`, selectedPls);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value?.projectSpecificCountry, countriesList])

    useEffect(() => {
        const selectedCountries = props.countriesList.slice();
        setCountriesList(selectedCountries);

        if(field.value.maisonProjectOwner) {
            setAdOwnerList([field.value.maisonProjectOwner] as any);
            setAdOwnerInput(field.value.maisonProjectOwner.itemContent);
        }
        if(field.value.technicalPointOfContact) {
            setAdUsersList([field.value.technicalPointOfContact] as any);
            setAdUsersInput(field.value.technicalPointOfContact.itemContent);
        }
        setFieldValue(`${field.name}.country`, selectedCountries);
    }, [props.countriesList, field.value.maisonProjectOwner, form.values.technicalPointOfContact])

    useEffect(() => {
        const maisonsSubscription = ReferenceService.GetListMaisonBrands().subscribe(maisonList => setMaisonOptions(maisonList));
        const regionSubscription = ReferenceService.GetListProjectRegion().subscribe(regionList => setRegionList(regionList));

        ctx.stopLoading();

        return () => {
            maisonsSubscription.unsubscribe();
            regionSubscription.unsubscribe();
            countriesSubscription?.unsubscribe();
            adUsersSubscription?.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findCountries = function (countrySearch: string) {
        setCountriesSelected(field.value.country);
        setCountriesInput(countrySearch);
        const tempSubscription = ReferenceService.GetListCountries(countrySearch).subscribe(localCountriesList => {
            let tempList = localCountriesList;
            if (countriesSelected.length) {
                tempList = localCountriesList.filter((country: Reference) => {
                    if (countriesSelected.find(c => c.id === country.id)) {
                        return null;
                    }
                    return country;
                });
            }
            tempList = countriesSelected.concat(tempList);
            setCountriesList(tempList);
        });
        setCountriesSubscription(tempSubscription);
    }

    const findUsers = function (userSearch: string) {
        setAdUsersInput(userSearch);
        if (userSearch.length > 2) {
            const tempSubscription = ReferenceService.GetListAdUsers(userSearch).subscribe(adUsersFilteredList => {
                setAdUsersList(adUsersFilteredList);
            });
            setAdUsersSubscription(tempSubscription);
        }
    }

    const findOwner = function (userSearch: string) {
        setAdOwnerInput(userSearch);
        if (userSearch.length > 2) {
            const tempSubscription = ReferenceService.GetListAdUsers(userSearch).subscribe(adUsersFilteredList => {
                setAdOwnerList(adUsersFilteredList);
            });
            setAdUsersSubscription(tempSubscription);
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Field
                    name={`${field.name}.projectName`}
                    label="Name of the project*"
                    component={UiTextField}
                    disabled={disable}
                />
                <Field
                    name={`${field.name}.projectDescription`}
                    label="Short description of the project and project objectives*"
                    component={UiTextareaField}
                    rows={7}
                    disabled={disable}
                />
                <Field
                    label="Name of the Richemont/Maison Project Owner* (start with last name)"
                    name={`${field.name}.maisonProjectOwner`}
                    component={UiAutocompleteField}
                    onInputChange={findOwner}
                    inputValue={adOwnerInput}
                    options={adOwnerList}
                    disabled={disable}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Field
                    label="Name of the technical point of contact, if any (start with last name)"
                    name={`${field.name}.technicalPointOfContact`}
                    component={UiAutocompleteField}
                    tooltip="Technical point of contact: Internal collaborator which is the main referent for technical topics (e.g. developemnt of the application, integration of the solution in the Richemont technology landscape, etc)"
                    onInputChange={findUsers}
                    inputValue={adUsersInput}
                    options={adUsersList}
                    disabled={disable}
                />
                <Field
                    label="Maison(s) in which the project will operate*"
                    name={`${field.name}.maison`}
                    component={UiAutocompleteField}
                    multiple={true}
                    options={maisonsOptions}
                    disabled={disable}
                    onCustomSelect={handleCustomSelect}
                />
                <Field
                    name={`${field.name}.region`}
                    label="Region(s) in which the project will operate*"
                    component={UiAutocompleteField}
                    multiple={true}
                    options={regionList}
                    disabled={disable}
                    onCustomSelect={handleCustomSelect}
                />
                <Field
                    label="In case the project is for specific countries, specify which ones"
                    name={`${field.name}.country`}
                    component={UiAutocompleteField}
                    options={countriesList}
                    onInputChange={findCountries}
                    inputValue={countriesInput}
                    multiple={true}
                    onCustomSelect={handleCustomSelect}
                    disabled={disable}
                />
                <Field
                    name={`${field.name}.department`}
                    label="In case the project is for a specific department, specify which one"
                    component={UiTextField}
                    disabled={disable}
                />
            </Grid>
        </Grid>
    )
}