import { Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { ExitToApp } from '@material-ui/icons';
import React, {useMemo} from "react";
import { NotificationContainer } from 'react-notifications';
import { Redirect, Route, Switch } from "react-router-dom";
import UiLogo from "../../assets/ui/UiLogo";
import LoadingContext from "../../contexts/LoadingContext";
import NotificationController from "../../controllers/NotificationController";
import ProjectController from "../../controllers/ProjectController";
import ErrorPage from "../../Error/ErrorPage";
import Dashboard from "../../screens/dashboard/Dashboard";
import { IntroPage } from "../../screens/introPage/IntroPage";
import { ProjectPage } from "../../screens/projectPage/ProjectPage";
import SelfAssessmentQuestionnaire from "../../screens/selfAssessmentQuestionnaire/selfAssessmentQuestionnaire";
import { ThirdPartiesDashboard } from "../../screens/thirdParties/ThirdPartiesDashboard";
import authRedirect from "../../services/authentification/authentificationServiceRedirect"
import { Loading } from "../components/loading/Loading";
import { AppBreadcrumbs } from "../components/MyBreadCrumb";
import PrivateRoute from "../components/PrivateRoute";
import AppErrorBoundary from "../helpers/AppErrorBoundary";
import LocalStorageTokenService from "../helpers/LocalStorageTokenService";
import {AuthContextProvider} from "../../contexts/AuthContext";
import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "../../services/authentification/authentificationConfig";

const Routes = () => {
  const [showLoading, setShowLoading] = React.useState(true);
  const [showDropdownMenu, setShowDropdownMenu] = React.useState(false);
  const pca = new PublicClientApplication(msalConfig);
  
  document.addEventListener("click", (event: any) => {
    if (!event.target.classList.contains('header-login') && showDropdownMenu) {
      setShowDropdownMenu(false);
    }
  })

  const myctx = {
    startLoading: () => setShowLoading(true),
    stopLoading: () => setShowLoading(false),
  };

  const cancel = () => {
    setShowLoading(false);
  }

  const toggleDropdownMenu = () => {
    setShowDropdownMenu(!showDropdownMenu);
  }

  const version = useMemo(() => process.env.REACT_APP_VERSION, [])

  return (
    <>
      <LoadingContext.Provider value={myctx}>
        <MsalProvider instance={pca}>
          <AuthContextProvider>
            <NotificationController>
              <ProjectController>
                <AppErrorBoundary>
                  <header className="header">
                    <Container className="header-container" maxWidth="xl">
                      <div className="header-selfAssessmentWarningMessage">
                       A new version of the MyCyberJourney platform has been launched. This old version provides read-only mode to view previous requests. 
                       Click the following link to access the new version, where you can register a digital project and access your requests:<a href="https://mycyberjourney.richemont.com"> https://mycyberjourney.richemont.com</a>.
                      </div>
                      </Container>
                    <Container maxWidth="xl">
                      <div className="header-content">
                        <div className="header-logo">
                          <UiLogo/>
                          <span>Group Security</span>
                        </div>
                        <div className="header-breadcrumbs">
                          <AppBreadcrumbs/>
                        </div>
                        <div className="dropdown">
                          <button className="header-login" type="button" onClick={toggleDropdownMenu}>
                            {LocalStorageTokenService.getUserFullName()}
                          </button>
                          {
                              showDropdownMenu &&
                              <div className="dropdown-menu" style={{padding: 0}}>
                                <Button
                                    className="dropdown-item"
                                    style={{padding: 10}}
                                    size="small"
                                    onClick={() => authRedirect.logOut()}>
                                  <ExitToApp/> Logout
                                </Button>
                              </div>
                          }
                        </div>
                      </div>
                    </Container>
                  </header>

                  <Switch>
                    <PrivateRoute auth={authRedirect} exact={true} path="/">
                      <Redirect to="/intro"/>
                    </PrivateRoute>
                    <PrivateRoute
                        auth={authRedirect}
                        exact={true}
                        path="/intro"
                        component={IntroPage}
                    />
                    <PrivateRoute
                        auth={authRedirect}
                        exact={true}
                        path="/dashboard/selfAssessment/new"><Redirect to="/dashboard"/></PrivateRoute>                       
                    <PrivateRoute
                        auth={authRedirect}
                        exact={true}
                        path="/dashboard/selfAssessment/:mode/:id/:projectName"
                        component={SelfAssessmentQuestionnaire}
                    />
                    <PrivateRoute auth={authRedirect}
                                  exact
                                  path="/dashboard"
                                  name="Dashboard"
                                  component={Dashboard}
                    />
                    <PrivateRoute auth={authRedirect}
                                  exact
                                  path="/dashboard/project/:id/:projectName"
                                  name="Project Details"
                                  component={ProjectPage}
                    />
                    <PrivateRoute
                        auth={authRedirect}
                        exact={true}
                        path="/thirdParty"
                        component={ThirdPartiesDashboard}
                    />
                    {/* Error page */}
                    <Route exact path="/Error" component={ErrorPage}/>
                    {/* Not Found */}
                    <PrivateRoute auth={authRedirect} component={() => <Redirect to="/"/>}/>
                  </Switch>
              </AppErrorBoundary>
              {showLoading && <Loading cancel={cancel} />}
                <footer className="footer">
                  <Container maxWidth="xl">
                    <div className="footer-content">
                      <span>© Richemont 2020 - {version}</span>
                    </div>
                  </Container>
                </footer>
                <NotificationContainer/>
              </ProjectController>
            </NotificationController>
          </AuthContextProvider>
        </MsalProvider>
      </LoadingContext.Provider>
    </>
  );
};

export default Routes;