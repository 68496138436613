import { FormControl, FormHelperText, InputAdornment, InputLabel } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import React, { ReactNode } from 'react';
import UiBaseField from './UiBaseField';
import { getFieldData } from './UiFieldHelpers';
import UiFieldWrapper from './UiFieldWrapper';
type IPropsUiTextField = UiBaseField & {
    type?:string,
    prefix?: string | ReactNode,
    suffix?: string | ReactNode,
    placeholder?: string,
    fullWidth?: boolean,
    tooltipv2?: string
}
export const UiTextField = (props: IPropsUiTextField) => {

    const {error, name, value, onChange} = getFieldData(props);
    const label=(!props.inline) ? props.label : undefined;
    const classes = (props.tooltipLabel) ? 'ui-text-field ui-field__before-tooltip' : 'ui-text-field';
    const prefix=(props.prefix) ? (<InputAdornment position="start">{props.prefix}</InputAdornment>) : ''
    const suffix=(props.suffix) ? (<InputAdornment position="end">{props.suffix}</InputAdornment>) : ''
    
    return (
        <React.Fragment>
            <UiFieldWrapper name={classes} {...props}>
                {props.inline && (
                    <div className="ui-field__label">{props.label}</div>
                )}
                <span className="ui-text-field__field ui-field__field">
                    <FormControl fullWidth variant="outlined">
                        {!props.inline && (
                            <InputLabel error={error.hasError}>{label}</InputLabel>
                        )}
                        <OutlinedInput
                            fullWidth={props.fullWidth===false ? false : true}
                            label={label}
                            type={props.type}
                            value={value}
                            disabled={props.disabled}
                            name={name}
                            placeholder={props.placeholder}
                            onChange={onChange}
                            onBlur={props.onBlur}
                            error={error.hasError}
                            startAdornment={prefix}
                            endAdornment={suffix}
                            labelWidth={0}
                        />
                        {error.hasError && (
                            <FormHelperText error={error.hasError}>{ error.errorMessage }</FormHelperText>
                        )}

                    </FormControl>
                    
                </span>
            </UiFieldWrapper>
        </React.Fragment>
    )
}
export default UiTextField