import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { teamList } from "../../../screens/introPage/constants";
import { Teams } from "../teams/Teams";
import { UiModal } from "../ui/UiModal/UiModal";
import './contactUs.scss';

export const ContactUs = () => {
    const email = process.env.REACT_APP_CONTACT_EMAIL;
    const [open, setOpen] = React.useState(false);
    const modalHandleOpen = () => setOpen(true);
    const modalHandleClose = () => setOpen(false);

    useEffect(() => {
        document.addEventListener('scroll', function() {
            const classList = document.getElementById('contactUs')?.classList;
            if(window.scrollY >= 55 && classList && !classList.contains('contacts-container-full')) {
                classList.replace('contacts-container', 'contacts-container-full');
            }
            else if(window.scrollY < 55 && classList?.contains('contacts-container-full')) {
                classList.replace('contacts-container-full', 'contacts-container');
            }
        })
    },[window.scrollY])

    return (
        <>
            <div id="contactUs" className="contacts-container">
                <h3>If any question :</h3>
                <span onClick={modalHandleOpen}><a>{email}</a></span>
            </div>
            <UiModal title={'CONTACT US'} open={open} onClose={modalHandleClose} maxWidth={1000}>
                <div className="contacts-modal">
                    <div className="flex-container">
                        <h3>
                            If you have any questions, please feel free to contact us on Microsoft Teams or by email.
                        </h3>
                        <Teams data={teamList}/>
                    </div>
                    <div className="ui-modal__footer ui-modal__footer--buttons">
                        <Button onClick={modalHandleClose} variant="outlined" color="primary" key="cancel">Close</Button>
                    </div>
                </div>
            </UiModal>
        </>
    )
}

export default ContactUs;