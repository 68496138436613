import axios from "axios-observable";
import { AxiosRequestConfig } from 'axios'
import LocalStorageTokenService from "./LocalStorageTokenService";
 import { isTestsRunning, kindTestExecutionEnv, localUrlBase, mockTestFileExtension, mockTestPath, serverUrlBase } from '../config/GlobalAppConfig'
import { EnumUtils } from "./HelpersFunc";
import { KindTestExecution } from "../models/enums";
import https from "https";

export enum ApiCallingMethods {
    get = 'GET',
    delete = 'DELETE',
    head = 'HEAD',
    options = 'OPTIONS',
    post = 'POST',
    put = 'PUT',
    patch = 'PATCH',
    link = 'LINK',
    unlink = 'UNLINK'
}

class CustomAxios {
    instance: axios;
    // LocalstorageService
    localStorageTokenService = LocalStorageTokenService.getService();
    callBack: (() => void) | null;
    private accessToken = "";
    
    constructor(axiosConfig : AxiosRequestConfig) {
        this.instance = axios.create(axiosConfig);
        this.callBack = null
        // Interceptor for request
        this.instance.interceptors.request.use(
            config => {
                const token = this.accessToken;
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }
                if(axiosConfig.responseType !== undefined && axiosConfig.responseType !== null){
                    config.headers['Content-Type'] = axiosConfig.responseType?.valueOf();
                }
                else{
                    config.headers['Content-Type'] = 'application/json';
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });

        // Interceptor for response
        this.instance.interceptors.response.use((response) => {
            if (this.callBack) this.callBack();
            return response;
        }, error => {
            if (this.callBack) this.callBack();
            return Promise.reject(error.response);
        });
    }

    setAccessToken(accessToken: string){
        this.accessToken = accessToken
    }

    private mustActivateTestEnv = (options: AxiosRequestConfig): AxiosRequestConfig => {
        // check if we test or not the app
        if(isTestsRunning !== undefined && (isTestsRunning as unknown as number) === 1){
            // Must be used in test case with the api
            // Disable authorization
            const httpsAgent = new https.Agent({
                rejectUnauthorized: false,
            })
            this.instance.defaults.httpsAgent = httpsAgent;
            options.httpsAgent = httpsAgent;
            this.instance.defaults.adapter = require('axios/lib/adapters/http');
            options.adapter = require('axios/lib/adapters/http');
            // get kind of test must be done
            const isRangeMode: KindTestExecution = EnumUtils.convertStringToEnum(KindTestExecution, kindTestExecutionEnv);

            if(isRangeMode === KindTestExecution.INTEGRATION_LOCAL_TST){
                if(serverUrlBase !== undefined){
                    const mockUrl: string = localUrlBase + mockTestPath;
                    options.url = options.url?.replace(serverUrlBase, mockUrl) + mockTestFileExtension;
                }
            }
        }
        return options;
    }

    request = (options: AxiosRequestConfig, callBack: (() => void) | null = null ) => {
        this.callBack = callBack;
        // Check if must activate the test env before query
        options = this.mustActivateTestEnv(options);
        // Execute the query
        return this.instance.request(options);
    };
}

export default new CustomAxios({baseURL : serverUrlBase});