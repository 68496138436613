import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Observable } from "rxjs";
import { mockTestFileExtension, mockTestPath, serverUrlBase } from '../config/GlobalAppConfig';


class FileHelper {

    request = (options: AxiosRequestConfig, callBack: (() => void) | null = null ) => {
        let options_url: string = '';
        if(serverUrlBase !== undefined){
            options_url = options.url?.replace(serverUrlBase, mockTestPath) + mockTestFileExtension;
            options_url = process.env.INIT_CWD + '/' + options_url;
        }
        let mockData : AxiosResponse<any>|null = null;
        let res: Observable<AxiosResponse<any>>;
        const fPath:string = options_url;
        let data = require(fPath);

        mockData = {
            // data: JSON.parse(data),
            data: data,
            status: 200,
            statusText: 'OK',
            headers: '',
            config: options,
            request: null
        };

        res = new Observable((observe) => {
            if(mockData !== null){
                observe.next(mockData);
            }
            observe.complete();
        });
        return res;
    };
}

export default new FileHelper();