import React, { useState, useEffect} from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom';
import ErrorPage from "../../Error/ErrorPage"
import { AuthAppResponse } from '../../services/authentification/authentificationConfig';
import {useAuthContext} from "../../contexts/AuthContext";

const PrivateRoute = ({ component: Component, render: Render, auth, path, ...rest }: any) => {

    const { isAuth, isError, role } = useAuthContext();
    const history = useHistory();

    return (
        (isAuth
                ?
                <Route
                    exact path={path}
                    {...rest}
                    render={Render}
                    component={Component}
                /> : (isError ? (
                        <Route component={ErrorPage}/>
                    ) : (
                        <Redirect to="/"/>)
                )
        )
    )
}

export default PrivateRoute