import { IDigitalApplication } from "../components/DigitalApplication";
import { ISelfAssessmentQuestionnaire } from "../selfAssessmentQuestionnaire";

export const digitalApplicationInitialValues: IDigitalApplication = {
  appName: "",
  appTypes: [],
  appTypesSelected: [],
  scopes: [],
  scopesSelected: [],
  usersToInteractWithApp: "",
  appHost: "",
  digitalApplicationAccessibleFrom: "",
  programmingLanguages: [],
  programmingLanguagesSelected: [],
  appHostedCountry: "",
  dataHostedCountry: "",
  doesItCollectInformation: false,
  isApplicationAllowingUserInputs: false,
  isItDesignedDeveloperForThePurposedOfTheProject: false,
  idQuestion: "",
  isThereAnyInterconnections: false,
  projectRegionToOperate: [],
  usersWithDataPrivilegedAccess: "",
  usersWithFunctionalPrivilegedAccess: "",
  appTypeOther: "",
  otherProgrammingLanguage: "",
};

export const selfAssessmentQuestionnaireInitialValues: ISelfAssessmentQuestionnaire =
  {
    generalInformation: {
      projectDescription: "",
      maison: [],
      projectName: "",
      maisonProjectOwner: "",
      region: [],
      country: [],
      department: "",
      technicalPointOfContact: "",
    },
    thirdPartyNameInvolved: "",
    isThereAnyThirdPartyInvolved: false,
    thirdParties: null,
    isThereAnyDigitalApplicationInvolved: false,
    digitalApplication: digitalApplicationInitialValues,
    hosting: null,
    dataInformation: {
      idPersonalDataRetainedBy: "",
      idDataSubjectsCollected: "",
      dataUsedFor: [],
      haveClearProcess: false,
      isPersonalDataNecessary: false,
      isTherePersonalDataInvolved: false,
      isTherePersonalDataUsed: false,
      sensitiveCompanyInfo: [],
      idProductInvolved: "",
      dataProcessing: [],
      dataSubjectInvolved: [],
      personalDataType: [],
      otherSubjectInvolved: "",
      otherPersonalData: "",
      otherDataUsedFor: "",
      isThereInternationalTransfer: false,
      otherSensitiveInformation: "",
    },
  };
