import { Button, CircularProgress, FormHelperText, Typography } from "@material-ui/core";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Subscription } from "rxjs";
import LoadingContext from "../../../contexts/LoadingContext";
import { submitValidationSchema } from "../../../screens/selfAssessmentQuestionnaire/models/validationSchemas";
import { ISelfAssessmentQuestionnaire } from "../../../screens/selfAssessmentQuestionnaire/selfAssessmentQuestionnaire";
import { DashboardService } from "../../../services/dashboardService";
import { FlowRefService } from "../../../services/flowRefService";
import { FormService } from "../../../services/formService";
import { ProjectService } from "../../../services/projectService";
import { notificationMessage, UserGroup } from "../../config/GlobalAppConfig";
import { baseCreateNotification, createNotification } from "../../helpers/HelpersFunc";
import LocalStorageTokenService from "../../helpers/LocalStorageTokenService";
import { Action, NotificationType, Status } from "../../models/enums";
import { NextActionBehavior, Reference, User } from "../../models/interfaces";
import { UiAutocompleteField, UiSelectField, UiTextareaField } from "../fields";
import { UiModal } from "../ui/UiModal/UiModal";
import UiSplitButton from "../ui/UiSplitButton";
import { Field, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { Mail } from "@material-ui/icons";
import { ReferenceService } from "../../../services/referenceService";


type PropsUiNextActions = {
    isSingleButton: boolean
    enableEditButton: boolean
    enableSaveAndCloseButton: boolean
    handleSubmit?: () => boolean
    questionnaireId: string
    handleSubmitWithoutFormik?: (redirectToReadOnly: boolean | null) => void
    actions?: NextActionOption[]
    form?: any
    isSubmitting?: boolean
    idStatus?: number
    usersList: User[]
    securityAuditList?: Reference[]
    chosenSecurityAuditList?: string[]
}

export interface NextActionOption {
    id: number;
    name: any;
    label: string;
    idFlowStatus?: number;
    nextAction: number | null;
}

interface StateProps {
    secAuditList: string[]
}

export interface IShareProject {
    userEmail: string,
    idQuestionnaire: number
}

const shareProjectInitialValues: IShareProject =
{
    userEmail: "",
    idQuestionnaire: 0
};

export const shareProjectValidationSchema = yup.object().shape({
    userEmail: yup.string().required("This is a required field").nullable(),
    idQuestionnaire: yup.string().required("This is a required field"),
});

const NextActions = (props: PropsUiNextActions) => {
    const ctx = useContext(LoadingContext);
    const [listButton, setListButton] = useState<NextActionOption[]>([]);
    const [openMessageModal, setOpenMessageModal] = useState<boolean>(false);
    const [securityValues, setSecurityValues] = useState<string[]>([]);
    const [securityAuditValuesChecked, setSecurityAuditValuesChecked] = useState<string[]>([]);
    const [nextAction, setNextAction] = useState<NextActionBehavior>({ selfAssessmentQuestionnaireId: parseInt(props.questionnaireId) });
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>('Message');
    const [selfAssessmentQuestionnaire, setSelfAssessmentQuestionnaire] = useState<ISelfAssessmentQuestionnaire>();
    const list = props.usersList.map(user => ({ id: user.email, itemContent: user.fullName }) as Reference);
    const history = useHistory();
    const location = useLocation<StateProps>();
    const securityAuditList = props.chosenSecurityAuditList ?? location.state?.secAuditList
    const localStorageTokenService = LocalStorageTokenService;
    const [modalInvitSomeOneOpen, setModalInvitSomeOneOpen] = React.useState(false);
    const modalHandleClose = () => setModalInvitSomeOneOpen(false);
    shareProjectInitialValues.idQuestionnaire = Number(parseInt(props.questionnaireId));
    const [sendingInvitation, setSendingInvitation] = React.useState(false);
    const projectOwnersComponent = useRef();
    const [adUsersInput, setAdUsersInput] = useState<string>('');
    const [adUsersList, setAdUsersList] = useState([])
    const [adUsersSubscription, setAdUsersSubscription] = useState<Subscription>()


    const isSecurityAuditFormValid = (): boolean => {
        return securityValues.length ? true : false;
    }

    const submitInvitSomeone = (values: any, formProps: FormikHelpers<IShareProject>) => {
        setSendingInvitation(true);
        if(!values.userEmail.id) {
            baseCreateNotification(NotificationType.error, notificationMessage.User_not_found.title, notificationMessage.User_not_found.message);
            setSendingInvitation(false);
            return;
        }
        values.userEmail = values.userEmail.id;
        FormService.inviteUser(values).subscribe(_data => {
            setSendingInvitation(false);
            formProps.resetForm();
            modalHandleClose();
            if (projectOwnersComponent) {
                (projectOwnersComponent.current as any).reloadProjectOwners();
            }
        });
    }

    const findUsers = function (userSearch: string) {
        setAdUsersInput(userSearch);
        if (userSearch.length > 2) {
            const tempSubscription = ReferenceService.GetListAdUsers(userSearch).subscribe((adUsersFilteredList : any) => {
                setAdUsersList(adUsersFilteredList);
            });
            setAdUsersSubscription(tempSubscription);
        }
    }

    const handleClick = async (option: NextActionOption) => {
        try {
            if (option.id === Action.Invite_someone) {
                console.warn("invit")
            }

            if(option.id === Action.Submit_project) {
                await submitValidationSchema.validate(selfAssessmentQuestionnaire);
            }
            setIsInvalid(false);
            setNextAction({
                ...nextAction,
                idFlowActions: option.id,
                idNextTask: option.nextAction,
                idFlowStatus: option.idFlowStatus
            })
            setModalTitle(option.name);
            option.id === Action.Invite_someone ? setModalInvitSomeOneOpen(true) : setOpenMessageModal(true);
        }
        catch(error) {
            baseCreateNotification(NotificationType.error, notificationMessage.Form_not_valid.title, notificationMessage.Form_not_valid.message);
        }
    };

    const singleAction = (e: any) => {
        e.stopPropagation();
        //TODO
    }

    const send = () => {
        if (props.idStatus === Status.ongoing_security_audit) {
            sendCompleteReview();
            return;
        }

        if (nextAction.idFlowActions === Action.Send_for_security_audit) {
            if (!isSecurityAuditFormValid()) {
                setIsInvalid(true);
                return;
            }
        }

        if (nextAction.idFlowActions === Action.Assign_to) {
            if (!nextAction.email) {
                setIsInvalid(true);
                return;
            }
        }
        ctx.startLoading();
        setOpenMessageModal(false);

        ProjectService.performAction(nextAction).subscribe(() => {
            history.push('/dashboard');
            if (nextAction.idFlowActions) {
                createNotification(NotificationType.success, nextAction.idFlowActions);
            }
        });
    }

    const cancel = () => {
        setOpenMessageModal(false);
        setIsInvalid(false);
        if (securityAuditValuesChecked.length) setSecurityAuditValuesChecked([]);
    }

    const handleCheckboxGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = securityValues.indexOf(event.target.value);
        if (event.target.checked && index < 0) {
            securityValues.push(event.target.value)
        }
        if (!event.target.checked && index > -1) {
            securityValues.splice(index, 1);
        }
        setSecurityValues(securityValues);
        setNextAction({ ...nextAction, securityAudit: securityValues.toString() })
    }

    const handleOnChangeCompleteReview = function (event: React.ChangeEvent<HTMLInputElement>) {
        const index = securityAuditValuesChecked.indexOf(event.target.value);
        if (event.target.checked && index < 0) {
            securityAuditValuesChecked.push(event.target.value)
        }
        if (!event.target.checked && index > -1) {
            securityAuditValuesChecked.splice(index, 1);
        }
        if (props.chosenSecurityAuditList && securityAuditValuesChecked.length === props.chosenSecurityAuditList.length) {
            setOpenMessageModal(true);
        }

        setSecurityAuditValuesChecked(securityAuditValuesChecked.slice());
    }

    const sendCompleteReview = function () {
        ctx.startLoading();
        const nextActionToSend: NextActionBehavior = {
            selfAssessmentQuestionnaireId: parseInt(props.questionnaireId),
            idFlowStatus: Status.review_completed,
            securityAudit: securityAuditValuesChecked.toString(),
            comment: nextAction.comment
        }

        DashboardService.changeStatus(nextActionToSend).subscribe(() => {
            history.push('/dashboard');
            createNotification(NotificationType.success, Action.Complete_Review);
        })
        setOpenMessageModal(false);
    }
    useEffect(()=>{
        let nextActionsSubscription: Subscription;

        if (props.idStatus && props.idStatus !== Status.canceled) {
            nextActionsSubscription = FlowRefService.GetNextActions(props.idStatus, props.questionnaireId).subscribe(nextActions => {
                const actionsList = nextActions?.map(action => {
                    return {
                        id: action.idFlowActions,
                        name: action.flowAction,
                        label: action.flowAction,
                        nextAction: action.idNextTask,
                        idFlowStatus: action.id
                    }
                }) as NextActionOption[];
                if (localStorageTokenService.getUserRoleToken() === UserGroup.SecurityTeam && props.idStatus !== Status.ongoing_security_audit ) {
                    const label = "Turn " + (selfAssessmentQuestionnaire?.isShadowIT === true ? "off" : "on") + " Shadow IT "
                    const Toggle_ShadowIt: NextActionOption = {
                        id: Action.Toggle_ShadowIt,
                        name: "shadowIt",
                        label: label,
                        nextAction: null,
                        idFlowStatus: 1
                    };
                    actionsList.push(Toggle_ShadowIt);
                }
                setListButton(actionsList)
            })
        }

        return () => {
            if (nextActionsSubscription) nextActionsSubscription.unsubscribe();
        }
    },[selfAssessmentQuestionnaire])


    useEffect(() => {
        if (props.enableEditButton) {
            const editOption: NextActionOption = { id: 0, name: "edit", label: "Edit Questionnaire", nextAction: null };
            listButton.push(editOption);
        }

        // if(props.idStatus === Status.draft) {
            const subscription = FormService.getForm(props.questionnaireId).subscribe((res) => {
                setSelfAssessmentQuestionnaire(res.selfAssessmentQuestionnaire);
            }, (error)=> {
                baseCreateNotification(NotificationType.error, notificationMessage.ErrorGenericMessage.title, error.data?.errorMessage);
                history.push('/dashboard');
            })
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            {
                !props.isSingleButton ?
                    listButton?.length > 0 ? (
                        <div className="box-content">
                            {
                                props.idStatus === Status.ongoing_security_audit
                                    ? (
                                        <div className="box-security">
                                            <div className="box-security-header">
                                                <Typography>Complete Review</Typography>
                                            </div>
                                            <div className="security-audit">
                                                <FormGroup className="ui-field" style={{ alignItems: 'start' }} onChange={handleOnChangeCompleteReview}>
                                                    {securityAuditList?.map((sa, index) => (
                                                        <FormControlLabel
                                                            key={`action-securityaudit-${index}`}
                                                            control={<Checkbox name={sa.replace(/\s+/g, '')} />}
                                                            label={sa}
                                                            value={sa}
                                                            checked={securityAuditValuesChecked.includes(sa)}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    )
                                    : <Button style={{ marginLeft: '10px' }} color="primary" variant="contained" onClick={() => handleClick(listButton[0])}>{listButton[0].label}</Button>
                            }
                            {
                                listButton.length > 1 && (
                                    <Fragment>
                                        <p>or</p>
                                        <UiSplitButton
                                            actions={listButton.slice(1, listButton.length)}
                                            default={0}
                                            onClick={(_event: any, _actionName: any, action: any) => handleClick(action)}
                                        />
                                    </Fragment>
                                )
                            }
                        </div>
                    ) :
                        'No available actions'
                    :
                    <Button variant="contained" color="primary" onClick={(e) => singleAction(e)}>
                        No Action
                    </Button>
            }
            <UiModal title={modalTitle} open={openMessageModal} onClose={cancel}>
                <div>
                    <div className="row">
                        {nextAction.idFlowActions === Action.Send_for_security_audit &&
                            <Fragment>
                                <FormGroup className="ui-field" style={{ alignItems: 'start' }} onChange={handleCheckboxGroupChange}>
                                    {props.securityAuditList?.map(sa => (
                                        <FormControlLabel
                                            key={`modal-securityaudit-${sa.id}`}
                                            control={<Checkbox name={sa.itemContent.replace(/\s+/g, '')} />}
                                            label={sa.itemContent}
                                            value={sa.itemContent}
                                        />
                                    ))}
                                </FormGroup>
                                {isInvalid && <FormHelperText className="modal-error" error={isInvalid}>Please choose at least one</FormHelperText>}
                            </Fragment>
                        }
                        {nextAction.idFlowActions === Action.Assign_to &&
                            <Fragment>
                                <UiSelectField
                                    enableValue_None={false}
                                    options={list}
                                    label="Security User"
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setNextAction({ ...nextAction, email: e.target.value })}
                                    value={nextAction.email ?? ''} />
                                {isInvalid && <FormHelperText className="modal-error" error={isInvalid}>Please choose one</FormHelperText>}
                            </Fragment>
                        }
                        <UiTextareaField
                            name="nextAction.comment"
                            label="Comment"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNextAction({ ...nextAction, comment: e.target.value })}
                            value={nextAction.comment}
                        />
                    </div>
                    <div className="ui-modal__footer ui-modal__footer--buttons">
                        <Button onClick={cancel} variant="outlined" color="primary" key="cancel">Cancel</Button>
                        <Button onClick={send} variant="contained" color="primary" key="apply">Confirm</Button>
                    </div>
                </div>
            </UiModal>

              <UiModal title={`Invite someone`} open={modalInvitSomeOneOpen} onClose={modalHandleClose}>
                <Formik
                    initialValues={shareProjectInitialValues}
                    validationSchema={shareProjectValidationSchema}
                    onSubmit={submitInvitSomeone}
                >
                    {formProps => (
                            <form onSubmit={formProps.handleSubmit}>
                                <div className="row">
                                    <Field
                                        name="userEmail"
                                        component={UiAutocompleteField}
                                        onInputChange={findUsers}
                                        inputValue={adUsersInput}
                                        options={adUsersList}
                                    />
                                </div>
                                <div className="ui-modal__footer ui-modal__footer--buttons">
                                    <Button onClick={modalHandleClose} variant="outlined" color="primary" key="cancel">Cancel</Button>
                                    <Button type="submit" variant="contained" color="primary" startIcon={sendingInvitation ? <CircularProgress /> : <Mail />} disabled={sendingInvitation}>
                                        Send
                                    </Button>
                                </div>
                            </form>
                    )}
                </Formik>
            </UiModal>
        </Fragment>
    )
}

export default NextActions;