import { Button } from '@material-ui/core';
import { Add, Search, CloudDownload, FilterList, Filter3, CloudUpload } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import { debounce } from 'ts-debounce';
import { UiSwitchField, UiTextField } from '../../../shared/components/fields';
import { searchTimeout, UserGroup } from '../../../shared/config/GlobalAppConfig';
import LocalStorageTokenService from '../../../shared/helpers/LocalStorageTokenService';
//import { UserGroup } from '../../../shared/models/enums';
import { Reference } from '../../../shared/models/interfaces';
import { IModalFilter, ModalFilters } from './ModalFilters';
import {useHistory} from "react-router";

type IPropsDashboardActions = {
    setAssignedToMe?(value: boolean): void
    assignedToMe?: boolean
    setSearchQuery(searchQuery: string): void,
    searchQuery: string,
    setModalFilter(modalFilterValue: IModalFilter): void,
    setMultipleSelection?(value: boolean): void,
    multipleSelection?: boolean,
    onApplyfilter: (modalFilterValues: IModalFilter) => void,
    modalFilter: IModalFilter | undefined,
    countriesRef: Reference[],
    projectStatusListRef: Reference[],
    thirdPartyList: Reference[],
    usersInvolvedList: Reference[],
    isFileUploading : boolean,
    onExport: () => void,
    onImport: () => void
}
export const DashboardActions = (props: IPropsDashboardActions) => {
    const [modalFilterActive, setModalFilterActive] = useState(false);
    const [exportModalFilterActive, setExportModalFilterActive] = useState<boolean>(false)
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const { setAssignedToMe, assignedToMe } = props;
    const localStorageTokenService = LocalStorageTokenService;




    const history = useHistory();

    const updateSearchQuery = (value: string) => {
        props.setSearchQuery(value)
    }

    const debouceFunction = debounce(updateSearchQuery, 100)

    const handleSearchChange = (event: any) => {
        if(timer) clearTimeout(timer);
        const tempTimer = setTimeout(() => {
            debouceFunction(event.target.value)
        }, searchTimeout);
        setTimer(tempTimer);
    }

    const handleOpenModal = () => {
        setModalFilterActive(true);
    }

    const handleCloseModal = () => {
        setModalFilterActive(false);
    }

    const handleAssignedToMeChange = function (event: React.ChangeEvent<HTMLInputElement>) {
        if (setAssignedToMe) setAssignedToMe(event.target.checked)
    }
    return (
        <>
            <div className="dashboard-actions-left">
                {
                    localStorageTokenService.getUserRoleToken() === UserGroup.SecurityTeam &&
                    <UiSwitchField
                        name="assignedToMe"
                        label="Assigned to me"
                        notShowingNoYes={true}
                        value={assignedToMe}
                        onChange={handleAssignedToMeChange} />
                }
            </div>
            <div className="dashboard-actions">
                <div className="dashboard-actions__item dashboard-actions__item--search">
                    <UiTextField
                        label="Search"
                        fullWidth={false}
                        onChange={handleSearchChange}
                        prefix={<Search />}
                    />
                </div>
                <div className="dashboard-actions__item">
                    <Button id="filters-btn" color="primary" variant="contained" startIcon={<FilterList />} onClick={handleOpenModal}>Add Filters</Button>
                    <ModalFilters
                        open={modalFilterActive}
                        isListViewFilter={true}
                        onClose={handleCloseModal}
                        onApply={props.onApplyfilter}
                        modalFilter={props.modalFilter}
                        countriesRef={props.countriesRef}
                        thirdPartyList={props.thirdPartyList}
                        usersInvolvedList={props.usersInvolvedList}
                        projectStatusListRef={props.projectStatusListRef} />
                </div>
                <div className="dashboard-actions__item">
                   { localStorageTokenService.getUserRoleToken() === UserGroup.SecurityTeam &&
                    <Button
                        id="import"
                        color="primary"
                        variant="outlined"
                        onClick={props.onImport}
                        startIcon={<CloudUpload/>}
                        disabled={props.isFileUploading}
                        >
                        Import
                    </Button>}
                </div>
                <div className="dashboard-actions__item">
                    <Button id="add-questionnaire" color="primary" variant="outlined" onClick={props.onExport} startIcon={<CloudDownload />}>
                        Export
                    </Button>
                </div>
                <div className="dashboard-actions__item">
                    <Button id="see-third-parties" color="primary" variant="outlined" onClick={() => history.push("/thirdParty")}  startIcon={<Filter3 />}>
                        See Assessed Third Parties
                    </Button>
                </div>
                {/* <div className="dashboard-actions__item">
                    <Button id="add-questionnaire" color="primary" variant="outlined" onClick={() => history.push("/dashboard/selfAssessment/new")} startIcon={<Add />}>
                        New Questionnaire
                    </Button>
                </div> */}
            </div>
        </>
    )
}