import React from "react";
import { Reference } from "../shared/models/interfaces";

export interface ChangeStatus {
    idProject: number,
    idStatus: number,
    status: string,
    comment: string
}

export interface ProjectService {
    changeStatusReq: ChangeStatus | undefined
    setChangeStatusReq(changeStatusReq: ChangeStatus | undefined): void
    handlePersistentChangeStatusReq(changeStatusReq: ChangeStatus): void
    thirdPartyActivities: Reference[] | undefined
    setThirdPartyActivities(changThirdPartyActivities: Reference[] | undefined): void
}

export default React.createContext<ProjectService>({} as ProjectService);