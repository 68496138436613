import React, { ReactNode } from 'react';
import UiTooltip from './UITooltip';
import UiBaseField from './UiBaseField';
type IPropsUiFieldWrapper = UiBaseField & {
    children: ReactNode,
    name: string,
    hideTooltip?: boolean
}
export const UiFieldWrapper = (props: IPropsUiFieldWrapper) => {
    const fieldClass = props.name;
    const tooltipClass = (props.tooltipLabel) ? 'ui-field__tooltip--bottom' : 'ui-field__tooltip';
    const sizeClass = (props.column) ? 'ui-field--' + props.column : 'ui-field--full';
    const cssClasses = [fieldClass, sizeClass, 'ui-field'];
    if(props.inline){
        cssClasses.push('ui-field--inline');
    }else{
        cssClasses.push('ui-field--block');
    }
    if(props.size){
        cssClasses.push('ui-field--'+props.size);
    }
    return (
        <div className={cssClasses.join(' ')}>
            {props.children}
            {!props.hideTooltip && props.tooltip && <span className={tooltipClass}> <UiTooltip title={props.tooltip} isClickable={props.isTooltipClickable} label={props.tooltipLabel}/></span>}
        </div>
    )
}
export default UiFieldWrapper