import { Avatar } from '@mui/material';
import React from 'react';

export interface Person {
    name: string,
    role?: string,
    imageSrc?: string,
    description?: string,
    zoomImage?: boolean
}

export const AvatarWithText = function({ person }: { person: Person }) {
    return (
        <div className='card'>
            <Avatar
                className={person.zoomImage ? 'zoom-image' : ''}
                alt={person.name}
                src={person.imageSrc}
                sx={{ width: 130, height: 130 }}
            />
            <h3>{person.name}</h3>
        </div>
    )
}