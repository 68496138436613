import { Container } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Subscription } from 'rxjs';
import XLSX from 'xlsx';
import LoadingContext from '../../contexts/LoadingContext';
import { DashboardRequestBody, DashboardService, KPIs } from '../../services/dashboardService';
import { FlowRefService } from '../../services/flowRefService';
import { ReferenceService } from '../../services/referenceService';
import UiBox from '../../shared/components/ui/UiBox';
import {ActionType, IStateOrder} from '../../shared/components/ui/UiTable/types';
import { UiTable } from '../../shared/components/ui/UiTable/UiTable';
import {dashboardRowPerPage, LoadingDelay, notificationMessage, urlPartialImport, UserGroup} from '../../shared/config/GlobalAppConfig';
import LocalStorageTokenService from '../../shared/helpers/LocalStorageTokenService';
import { Status } from '../../shared/models/enums';
import { CommonProperties, Pagination, Reference, User } from '../../shared/models/interfaces';
import { DashboardActions } from './components/DashboardActions';
import { IModalFilter } from './components/ModalFilters';
import { stakeHoldersTableHeads, tableHeads } from './components/UiTableHead';
import CustomAxios, { ApiCallingMethods } from "../../shared/helpers/AxiosHelper";
import { DocAccessType, NotificationType } from "../../shared/models/enums";
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { baseCreateNotification } from '../../shared/helpers/HelpersFunc';
import {PAGE_NUMBER_KEY} from "../introPage/constants";



export interface IDashboardItem extends CommonProperties {
   expanded: boolean,
   projectName: string,
   projectDescription: string,
   contactEmail: string,
   contactName: string,
   submissionDate: Date,
   statusName: string,
   idFlowStatus: number,
   thirdPartyNames: string,
   thirdPartyScores: string,
   assignedTo?: string,
   fetch? : any,
   isShadowIT?: boolean
}

export const Dashboard = () => {
   const [errorState, setError] = useState<boolean | string>(false);
   const [page, setPage] = useState<number>(sessionStorage.getItem(PAGE_NUMBER_KEY) !== null ? parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!) : 1);
   const [itemsList, setItemsList] = useState<IDashboardItem[]>([]);
   const [dashboardSubscription, setDashboardSubscription] = useState<Subscription>();
   const [usersList, setUsersList] = useState<User[]>([]);
   const [usersListv2, setUsersListv2] = useState([]);
   const [countries, setCountries] = useState([]);
   const [projectStatusList, setProjectStatusList] = useState([]);
   const [thirdPartyList, setThirdPartyList] = useState<Reference[]>([]);
   const [usersInvolvedList, setUsersInvolvedList] = useState<Reference[]>([]);
   const [modalFilter, setModalFilter] = useState<IModalFilter | undefined>(undefined);
   const [searchQueryState, setSearchQueryState] = useState<string>('');
   const [kpis, setKPIs] = useState<KPIs>();
   const [totalCases, setTotalCases] = useState<number | undefined>();
   const [isFromFilters, setIsFromFilters] = useState<boolean>(false);
   const [sortColumnId, setSortColumnId] = useState<number>();
   const [isSortAsc, setIsSortAsc] = useState<IStateOrder>();
   const [dashboardHeaderReq, setDashboardHeaderReq] = useState<DashboardRequestBody>({
      pageNumber: page,
      pageSize: dashboardRowPerPage
   });
   const ctx = React.useContext(LoadingContext);
   const localStorageTokenService = LocalStorageTokenService;
   const history = useHistory();
   const inputFile = useRef<HTMLInputElement>(null);
   const [loading, setLoading] = useState<boolean>(false);
   const [isFileUploading, setIsFileUploading] = useState(false);
   const [preventRefresh, setPreventRefresh] = useState<boolean>(false)

   const assignedToMe = sessionStorage.getItem('assignToMe');
   const showAssignedToMe = assignedToMe && assignedToMe === 'true' ? true : false;
   const MODAL_FILTER_KEY = 'modalFilter';
   const SORT_COLUMN_KEY = 'sortColumnId';
   const SORT_DIR_KEY = 'isSortAsc';

   useEffect(() => {
      if (sessionStorage.getItem(PAGE_NUMBER_KEY) !== null) {
         setPage(parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!))
      } else {
         sessionStorage.setItem(PAGE_NUMBER_KEY, (1).toString());
      }
   }, []);


   const ErrorVerification = (error: { status: string | number; statusText: string; }) => {
      if (error) {
         if (error.status === 404) {
            setError('something wrong: role undefined (' + error.status + ')');
         }
         else {
            setError('something wrong: ' + error.statusText);
         }
      }
      else {
         setError('something went wrong with the connection');
      }
   }

   const fetchDashboard = (assignedToMe: boolean = false) => {
      if (!preventRefresh) {
         let request: DashboardRequestBody = {
            ...dashboardHeaderReq,
            pageNumber: page,
            searchText: searchQueryState,
            orderBy: sortColumnId,
            orderDir: isSortAsc === 'asc' ? 2 : 1,
            assignedToMe
         }
         if (modalFilter) {
            request = {
               ...dashboardHeaderReq,
               assignedToMe,
               orderBy: sortColumnId,
               orderDir: isSortAsc === 'asc' ? 2 : 1,
               pageNumber: page,
               searchText: searchQueryState,
               thirdPartyList: modalFilter.thirdPartyList && modalFilter.thirdPartyList.length ? modalFilter.thirdPartyList.map(tp => tp.id.toString()).reduce((previousVal, currentVal) => `${previousVal},${currentVal}`) : null,
               usernameList: modalFilter && modalFilter.usernamesList.length ? modalFilter.usernamesList.map(tp => tp.itemtKey).reduce((previousVal, currentVal) => `${previousVal},${currentVal}`) : null,
               statusList: modalFilter && modalFilter.onlyNewStatus ? Status.new.toString() : modalFilter.statusList.length ? modalFilter.statusList.map(tp => tp.id.toString()).reduce((previousVal, currentVal) => `${previousVal},${currentVal}`) : null
            }
         }

         const toLoading = setTimeout(() => setLoading(true), LoadingDelay);

         setDashboardHeaderReq(request);

         const tempDashboardService = DashboardService.getDashboard(request).subscribe((response: Pagination<IDashboardItem>) => {
            const dashboard = response.results ?? [];
            setTotalCases(response.total);
            if (!dashboard.length) {
               if (localStorageTokenService.getUserRoleToken() !== UserGroup.SecurityTeam && (!kpis || kpis && kpis?.draft < 1)) {
                  history.push('/intro');
               }
            }

            setItemsList(dashboard);
         }, (error: { status: string | number; statusText: string; }) => {
            ErrorVerification(error)
         }, () => {
            clearTimeout(toLoading);
            setLoading(false);
         });

         setDashboardSubscription(tempDashboardService)
      }
      setPreventRefresh(false)
   }

   const onChangeFile = (event: any) => {
        setIsFileUploading(true);
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];

        const formData = new FormData();

        file.docAccessType = DocAccessType["Visible to all"].toString();

        const pos = Number(file.docAccessType);
        formData.append("files", file, file.name);

        let method: ApiCallingMethods = ApiCallingMethods.post;
        let uploadUrl = urlPartialImport;

        const options: AxiosRequestConfig = {
            method: method,
            url: uploadUrl,
            data: formData,
            responseType: 'json'
        };

        CustomAxios.request(options).subscribe((response: AxiosResponse<any>) => {
            baseCreateNotification(NotificationType.success, notificationMessage.File_Uploaded.title, notificationMessage.File_Uploaded.message, 10000);
            setIsFileUploading(false);
            fetchDashboard(showAssignedToMe);
            event.target.value = null;
        }, (error) => {
            setIsFileUploading(false);
            baseCreateNotification(NotificationType.error, notificationMessage.Import_Error.title, error?.data?.errorMessage ?? notificationMessage.Import_Error.message, 10000);
            event.target.value = null;
        });
    };

    const handleImportData = () => {
       if(inputFile != null && inputFile.current != null)
        {
            inputFile.current.click();
        }
    }
   const handleExportData = () => {
      var request: DashboardRequestBody = {
         ...dashboardHeaderReq
      }

      DashboardService.exportQuestionnaires(request).subscribe(response => {
         if (response != null) {
            const data: any = response;
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
            const workbook: XLSX.WorkBook = { Sheets: { 'Questionnaires': worksheet }, SheetNames: ['Questionnaires'] };
            const filename: string = 'Questionnaires.xlsx';
            XLSX.writeFile(workbook, filename);
         }
      }, error => {
         ErrorVerification(error);
      });
   }

   const handleCellClick = (form: IDashboardItem, event: any) => {
      if (event.target.localName === 'input') {
         event.stopPropagation();
         return;
      }

      history.push({
         pathname: `/dashboard/project/${form.id}/${form.projectName}`,
         state: { idStatus: form.idFlowStatus, usersList: usersList, projectOwner: form.createdBy }
      })
   }

   const setSearchQuery = (searchQuery: string) => {
      setSearchQueryState(searchQuery)
   }

   const handleAssignedToMeChange = function (assignedToMe: boolean) {
      sessionStorage.setItem('assignToMe', assignedToMe ? 'true' : 'false');
      if (!assignedToMe) {
         if (page === parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!)) {
            fetchDashboard(assignedToMe)
         } else {
            setPage(parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!))
         }
      } else {
         setPage(1);
         if (parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!) === 1) {
            fetchDashboard(assignedToMe)
         }
      }
   }

   const handleChange = function (event: any | User, action: ActionType, id?: number) {
      if (action === 'assign') {
         const updatedList = itemsList.map(item => {
            if (item.id === id) {
               item.idFlowStatus = Status.under_review;
               item.statusName = 'Under Review';
               item.assignedTo = event.email;
            }
            return item;
         });

         setItemsList(updatedList);
      }

      if (action === 'sort') {
         if (sortColumnId === event) {
            setIsSortAsc(isSortAsc === 'asc' ? 'desc' : 'asc')
         }
         else {
            setSortColumnId(event);
            setIsSortAsc('desc');
         }
      }
   }

   const saveAndLoadFilterStatus = () => {

      if(modalFilter) {
         sessionStorage.setItem(MODAL_FILTER_KEY, JSON.stringify(modalFilter));
         return;
      }
      const statusStorage = sessionStorage.getItem(MODAL_FILTER_KEY);
      if(statusStorage !== null){

         const storedFilter:IModalFilter =  JSON.parse(statusStorage);
         setModalFilter(storedFilter);
      }

   }

   const saveAndLoadSortStatus = () => {

      if(sortColumnId || isSortAsc) {
         sessionStorage.setItem(SORT_DIR_KEY, JSON.stringify(isSortAsc));
         sessionStorage.setItem(SORT_COLUMN_KEY, JSON.stringify(sortColumnId));
         return;
      }
      const sortDirStatusStorage = sessionStorage.getItem(SORT_DIR_KEY);
      const sortColumnStatusStorage = sessionStorage.getItem(SORT_COLUMN_KEY);
      if((sortDirStatusStorage && sortDirStatusStorage !== 'undefined') || (sortColumnStatusStorage && sortColumnStatusStorage!== 'undefined')){
         setSortColumnId(JSON.parse(sortColumnStatusStorage!));
         setIsSortAsc(JSON.parse(sortDirStatusStorage!));
      }

   }

   const handleApplyFilters = function (filterValues: IModalFilter) {
      setPreventRefresh(true);
      if(filterValues === undefined){
         sessionStorage.removeItem(MODAL_FILTER_KEY);
      }
      sessionStorage.setItem(PAGE_NUMBER_KEY, (1).toString());
      setPage(1)
      setModalFilter(filterValues);
      setIsFromFilters(true);
      const request: DashboardRequestBody = {
         ...dashboardHeaderReq,
         pageNumber: 1,
         thirdPartyList: filterValues?.thirdPartyList && filterValues.thirdPartyList.length ? filterValues.thirdPartyList.map(tp => tp.id.toString()).reduce((previousVal, currentVal) => `${previousVal},${currentVal}`) : null,
         usernameList: filterValues && filterValues.usernamesList.length ? filterValues.usernamesList.map(tp => tp.itemtKey).reduce((previousVal, currentVal) => `${previousVal},${currentVal}`) : null,
         statusList: filterValues && filterValues.onlyNewStatus ? Status.new.toString() : filterValues?.statusList.length ? filterValues.statusList.map(tp => tp.id.toString()).reduce((previousVal, currentVal) => `${previousVal},${currentVal}`) : null
      }

      setDashboardHeaderReq(request);
      setLoading(true);
      const tempDashboardService = DashboardService.getDashboard(request).subscribe((response: Pagination<IDashboardItem>) => {
         setItemsList(response.results);
         setTotalCases(response.total);

      }, (error: { status: string | number; statusText: string; }) => {
         ErrorVerification(error);
      }, () => {
         setLoading(false);
         setPreventRefresh(false);
      });

      setDashboardSubscription(tempDashboardService);
   }

   useEffect(() => {
      sessionStorage.setItem(SORT_DIR_KEY, JSON.stringify(isSortAsc));
      sessionStorage.setItem(SORT_COLUMN_KEY, JSON.stringify(sortColumnId));
   }, [isSortAsc, sortColumnId]);

   useEffect(() => {
      if (searchQueryState.length === 0 ) {
         if (page === parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!)) {
            fetchDashboard(showAssignedToMe)
         } else {
            setPage(parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!))
         }
      } else {
         setPage(1);
         if (parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!) === 1) {
            fetchDashboard(showAssignedToMe)
         }
      }
   }, [searchQueryState]);


   useEffect(() => {
      fetchDashboard(showAssignedToMe);
   }, [page, sortColumnId, isSortAsc]);




   useEffect(() => {

      const countriesSubscription = ReferenceService.GetListCountries().subscribe(
         (countriesRef) => setCountries(countriesRef)
         , () => { })

      const projectStatusSubscription = FlowRefService.GetAllStatus().subscribe(
         (projectStatusListRef) => setProjectStatusList(projectStatusListRef)
         , () => { })

      const thirdPartiesSubscription = FlowRefService.GetAllThirdParties().subscribe(
         (thirdPartiesList: Reference[]) => setThirdPartyList(thirdPartiesList)
         , () => { })

      const usersInvolverdSubscription = DashboardService.getAllUsersInvolvedInQuestionnaires().subscribe(
         (usersInvolvedList) => setUsersInvolvedList(usersInvolvedList)
         , () => { })

      const usersListSubscription = ReferenceService.GetSecurityTeamUsers().subscribe(users => {
         setUsersList(users);
         const newUsersList = users.map((user: User, index: number): Reference => {
            return {
               id: index,
               itemtKey: user.email,
               itemContent: user.fullName,
               displayOrder: index,
               isVisible: true,
               itemContent2: ''
            }
         })

         setUsersListv2(newUsersList);
      })

      const kpisSubscription = DashboardService.getKPIs().subscribe((kpi: KPIs) => {
         setKPIs(kpi);
      }, (error: { status: string | number; statusText: string; }) => {
         ErrorVerification(error)
      });

      return () => {
         countriesSubscription.unsubscribe();
         projectStatusSubscription.unsubscribe();
         usersListSubscription.unsubscribe();
         thirdPartiesSubscription.unsubscribe();
         usersInvolverdSubscription.unsubscribe();
         if (dashboardSubscription) dashboardSubscription.unsubscribe();
         kpisSubscription.unsubscribe();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   // needed for call featchDashboard when status change in subcomponent
   itemsList.map(i => i.fetch = fetchDashboard)

   saveAndLoadFilterStatus();
   saveAndLoadSortStatus();

   return (
      <>
         <React.Fragment>
            <Container maxWidth="xl">
               <div className="dashboard-head">
                  <h1>Dashboard</h1>
                  <div className="dashboard-head__number">
                     <div>
                        <p>Draft</p>
                        <span>{kpis?.draft}</span>
                     </div>
                     {
                        LocalStorageTokenService.getUserRoleToken() === UserGroup.SecurityTeam &&
                        <>
                           <div>
                              <p>Assigned</p>
                              <span>{kpis?.assigned}</span>
                           </div>
                           <div>
                              <p>In Waiting</p>
                              <span>{kpis?.inWaiting}</span>
                           </div>
                           <div>
                              <p>Done</p>
                              <span>{kpis?.done}</span>
                           </div>
                        </>
                     }
                  </div>
               </div>
               <UiBox
                  title="" /* Upcoming projects */
                  name="dashboard"
                  actions={
                     <DashboardActions
                        setSearchQuery={setSearchQuery}
                        setModalFilter={setModalFilter}
                        searchQuery={searchQueryState}
                        setAssignedToMe={handleAssignedToMeChange}
                        assignedToMe={showAssignedToMe}
                        countriesRef={countries}
                        thirdPartyList={thirdPartyList}
                        usersInvolvedList={usersInvolvedList}
                        projectStatusListRef={projectStatusList}
                        onApplyfilter={handleApplyFilters}
                        modalFilter={modalFilter}
                        onExport={handleExportData}
                        onImport={handleImportData}
                        isFileUploading={isFileUploading}
                     />
                  }
               >
                  <UiTable
                     className="dashboard-table"
                     items={itemsList}
                     onChange={handleChange}
                     usersList={usersList}
                     loading={loading}
                     rowPerPages={dashboardRowPerPage}
                     tableHeads={
                        LocalStorageTokenService.getUserRoleToken() === UserGroup.SecurityTeam ? tableHeads
                        : stakeHoldersTableHeads
                     }
                     onRowSelect={handleCellClick}
                     fetch={fetchDashboard}
                     total={totalCases}
                     onSetPage={setPage}
                     orderBy={sortColumnId}
                     order={isSortAsc}
                  />
               </UiBox>
            </Container>
            <input
               ref={inputFile}
               type='file'
               id='file'
               style={{display: 'none'}}
               onChange={(event) => onChangeFile(event) }
            />
         </React.Fragment>
      </>
   );
}

export default Dashboard